import client from "./client";

/**
 * @description Get advance payments
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUserTemporaryWorker = (userId: string) => {
	return client.get(`/api-client-v2/users/${userId}/profile`);
};

const endpoints = { getUserTemporaryWorker };
export default endpoints;
