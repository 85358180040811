// @ts-nocheck
import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, SelectAsync, Tag, Title } from "@zolteam/react-ras-library";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormikContext } from "formik";
import { useQuery } from "react-query";
// Style
import style from "./ModalContentCommandsFilter.module.css";
import cn from "../../../../../utils/cn";
// Utils
import validationSchema from "../../../commandsFormValidation";
import commandsFormDefaultValues from "../../../commandsFormDefaultValues";
// Constants
import { ORDER_STATUS } from "../../../../../constants";
// Services
import { getAgencies } from "../../../../../services/commandService";
import OrderFilterPeriod from "../../../../../components/molecules/OrderFilterPeriod";
const ModalContentCommandsForm = ({ onConfirmClick }) => {
	const { t } = useTranslation();
	const { values, setFieldValue, isValid, setValues } = useFormikContext();
	const { agenciesIds } = values;
	const [agencyInputValue, setAgencyInputValue] = useState("");
	const onStatusChange = (status) => {
		const newStatusValue = [...values.statuses];
		if (newStatusValue.includes(status)) {
			newStatusValue.splice(newStatusValue.indexOf(status), 1);
		} else {
			newStatusValue.push(status);
		}
		setFieldValue("statuses", newStatusValue);
	};
	const renderOptionLabel = (option, selectedList) => {
		const isSelected = selectedList?.find((id) => id === option.id) != null;
		return (
			<div className={style.optionsLabel}>
				<div className={style.optionCheckbox}>
					<Checkbox
						className={style.checkbox}
						onChange={(e) => {
							e.stopPropagation();
							// e.preventDefault();
						}}
						theme="primary"
						id={option.unifiedTempWorkerUniqueId}
						value={isSelected}
					/>
				</div>
				<p className={cn([style.optionLabel, isSelected ? style.active : ""])}>{`${option.name}`}</p>
			</div>
		);
	};
	const handleEquipmentSelect = useCallback(
		(item) => {
			const selectedIndex = values?.agenciesIds.findIndex((id) => id === item.id);
			if (selectedIndex !== -1) {
				const updatedEquipments = values?.agenciesIds.filter((id) => id !== item.id);
				setFieldValue("agenciesIds", updatedEquipments);
			} else {
				setFieldValue("agenciesIds", values?.agenciesIds.concat([item.id]));
			}
		},
		[values.agenciesIds]
	);
	const { data: agenciesData } = useQuery("getAgencies", () => getAgencies(" "), {
		staleTime: Infinity,
		placeholderData: { data: [] },
	});
	const onAgencyDelete = (agencyId) => {
		const filteredAgencies = values?.agenciesIds?.filter((id) => id !== agencyId);
		setFieldValue("agenciesIds", filteredAgencies);
	};
	const agencyOptions = useMemo(() => {
		if (!agenciesData) return [];
		const parsedResult = agenciesData?.data?.map(({ id, name }) => ({
			id,
			name: name.replace("RAS ", ""),
		}));
		return parsedResult.sort((a, b) => a.name.localeCompare(b.name));
	}, [agenciesData]);
	const renderSelectedAgencies = (id) => {
		const qualificationName = agencyOptions.find((option) => option.id === id)?.name;
		return (
			<div className={style.addedItem} key={id}>
				<Tag key={id} color="primaryLight" size="s" showCloseButton onClose={() => onAgencyDelete(id)}>
					<p className={style.itemSelected}>{`${qualificationName}`}</p>
				</Tag>
			</div>
		);
	};
	return (
		<div className={style.content}>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("commands.filtersModal.title")}
			</Title>
			<div>
				<div className={style.container}>
					{/* AGENCIES */}
					<div className={style.section}>
						<p className={style.subtitle}>{t("commands.filtersModal.agenciesSection")}</p>
						<div className={style.addedItemBox}>
							{agenciesIds.map((id) => renderSelectedAgencies(id)) || []}
						</div>
						<div className={style.searchInput}>
							<Formik>
								{() => (
									<Form className={style.addProfileForm}>
										<SelectAsync
											className={cn([style.inputs])}
											defaultOptions={agencyOptions}
											inputPersistOnOptionSelect
											promiseOptions={async (input) => {
												if (input.length < 1) return agencyOptions;
												return agencyOptions.filter((option) =>
													option.name.toLowerCase().includes(input.toLowerCase())
												);
											}}
											onChange={(item) => {
												handleEquipmentSelect(item);
											}}
											name="profile"
											label={t("commands.filtersModal.selectLabel")}
											getOptionLabel={(option) => renderOptionLabel(option, agenciesIds)}
											getOptionValue={(option) => option.id}
											value=""
											handleInputChange={(value, { action, prevInputValue }) => {
												if (action === "set-value") {
													setAgencyInputValue(prevInputValue);
													return prevInputValue;
												}
												if (action !== "menu-close") setAgencyInputValue(value);
												return value;
											}}
											inputValue={agencyInputValue}
											noOptionsMessage={() => null}
											loadingMessage={() => null}
										/>
									</Form>
								)}
							</Formik>
						</div>
					</div>
					<div>
						{/* STATUS */}
						<div className={style.section}>
							<p className={style.subtitle}>{t("commands.filtersModal.statusSection")}</p>
							<div className={cn([style.checkbox])}>
								<div>
									<Checkbox
										id="isInProgressFilterEnabled"
										value={values.statuses.includes(ORDER_STATUS.IN_PROGRESS)}
										onChange={() => onStatusChange(ORDER_STATUS.IN_PROGRESS)}
										theme="primary"
										label={t("commands.filtersModal.inProgressLabel")}
										name="statuses"
									/>
								</div>
								<div>
									<Checkbox
										id="isIsFilledFilterEnabled"
										value={values.statuses.includes(ORDER_STATUS.IS_FILLED)}
										onChange={() => onStatusChange(ORDER_STATUS.IS_FILLED)}
										theme="primary"
										label={t("commands.filtersModal.isFilledLabel")}
										name="statuses"
									/>
								</div>
								<div>
									<Checkbox
										id="isNotProvidedFilterEnabled"
										value={values.statuses.includes(ORDER_STATUS.NOT_PROVIDED)}
										onChange={() => onStatusChange(ORDER_STATUS.NOT_PROVIDED)}
										theme="primary"
										label={t("commands.filtersModal.notProvidedLabel")}
										name="statuses"
									/>
								</div>
							</div>
						</div>
						{/* DATE CREATION */}
						<div className={style.section}>
							<p className={style.subtitle}>{t("commands.filtersModal.creationPeriodStatus")}</p>
							<div className={style.dateRangeWrapper}>
								<OrderFilterPeriod
									startDateInputName="startDate"
									endDateInputName="endDate"
									startDateLabel={t("commands.filtersModal.startDate")}
									endDateLabel={t("commands.filtersModal.endDate")}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr className={cn([style.separator])} />
			<div className={cn([style.buttonsBlock])}>
				<button
					className={style.resetFilters}
					type="button"
					onClick={() => setValues({ ...commandsFormDefaultValues })}
				>
					{t("commands.filtersModal.cancelAction")}
				</button>
				<Button
					className={style.validationButtonRight}
					disabled={!isValid}
					color={!isValid ? "grey" : "primary"}
					type="button"
					onClick={() => onConfirmClick(values)}
				>
					{t("commands.filtersModal.confirmAction")}
				</Button>
			</div>
		</div>
	);
};
ModalContentCommandsForm.propTypes = {
	onConfirmClick: PropTypes.func.isRequired,
};
const ModalContentCommandsFormWrapper = ({ onConfirmClick, initialValues }) => (
	<div className={style.container}>
		<Formik initialValues={initialValues} validationSchema={validationSchema}>
			{() => (
				<Form>
					<ModalContentCommandsForm onConfirmClick={onConfirmClick} />
				</Form>
			)}
		</Formik>
	</div>
);
ModalContentCommandsFormWrapper.propTypes = {
	onConfirmClick: PropTypes.func.isRequired,
	initialValues: PropTypes.shape().isRequired,
};
export default ModalContentCommandsFormWrapper;
