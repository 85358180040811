// @ts-nocheck
import React, { cloneElement, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

// Atoms
// TODO: Rename this component for a generic name
import CommandSideMenuItem from "../../atoms/CommandSideMenuItem";

import useEnabledFeatures from "../../../hooks/useEnabledFeatures";

// Utils
import formatPhoneNumber from "../../../utils/formatPhoneNumber";

// Service
import { getUnifiedTempWorker } from "../../../services/missionFollowUpService";

// Style
import style from "./ProfileSideMenu.module.css";
import cn from "../../../utils/cn";

// Store

const ProfileSideMenu = ({
	routes = {
		path: "",
		label: "",
		component: null,
	},
	children: views = null,
}) => {
	const { profileId } = useParams();
	const { t } = useTranslation();

	const {
		isEnableFeaturesFetched,
		enabledFeatures: { hasProfileAccess, hasMissionFollowUpAccess },
	} = useEnabledFeatures();

	const links = useMemo(() => {
		if (!profileId) return [];
		return routes
			.filter((route) => {
				switch (route.routeName) {
					case "profile-missions": {
						return hasMissionFollowUpAccess;
					}
					default: {
						return true;
					}
				}
			})
			.map((route) => ({
				...route,
				path: route.path.replace(":profileId", profileId),
			}));
	}, [routes, profileId, hasMissionFollowUpAccess]);

	const { data: unifiedTempWorker } = useQuery(["unified-temp-worker"], () => getUnifiedTempWorker(profileId));

	if (!isEnableFeaturesFetched) {
		return <div>{t("global.loading")}</div>;
	}
	if (!hasProfileAccess) {
		return <div>{t("global.unauthorized")}</div>;
	}

	return (
		<>
			<div className={cn([style.sidemenu, style.sideMenuDesktop])} data-testid="formSideMenu">
				{unifiedTempWorker && (
					<div className={style.info}>
						<div className={style.fullName}>
							{unifiedTempWorker?.data?.firstName && (
								<p className={style.profileName}>{unifiedTempWorker?.data?.firstName}</p>
							)}
							{unifiedTempWorker?.data?.lastName && (
								<p className={style.profileName}>{unifiedTempWorker?.data?.lastName}</p>
							)}
						</div>
						{unifiedTempWorker?.data?.primaryQualificationName && (
							<div className={style.profileQualification}>
								{unifiedTempWorker?.data?.primaryQualificationName}
							</div>
						)}
						{unifiedTempWorker?.data?.phone && (
							<div className={style.profilePhone}>
								{formatPhoneNumber(`+33${unifiedTempWorker?.data?.phone?.slice(1)}` || "")}
							</div>
						)}
					</div>
				)}
				<div className={style.menu}>
					{links.map((route) => (
						<CommandSideMenuItem key={route.label} path={route.path}>
							{route.label}
						</CommandSideMenuItem>
					))}
				</div>
			</div>
			<div className={cn([style.container, style.containerMobile])}>{cloneElement(views)}</div>
		</>
	);
};

ProfileSideMenu.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string,
			label: PropTypes.string,
			component: PropTypes.node,
		})
	),
	children: PropTypes.node,
};

export default ProfileSideMenu;
