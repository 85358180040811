import { FormikErrors, FormikTouched } from "formik";
import { OrderInterface } from "src/types/OrderInterface";
import { get, isNil } from "lodash";
import { t } from "i18next";

export const getEndDateRangeInputErrorMessage = (
	touched: FormikTouched<OrderInterface>,
	errors: FormikErrors<OrderInterface>
): string | null => {
	return get(touched?.period, "endDate", false) && !isNil(errors?.period?.endDate)
		? t("commands.error.mandatoryEndDateField")
		: null;
};
