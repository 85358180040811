import * as Yup from "yup";
import { isNil } from "lodash";
import { OrderInterface } from "src/types/OrderInterface";

const isValidStartHourRequired = (startHour: string | null, context: Yup.TestContext<OrderInterface>): boolean => {
	const { endHour } = context.parent;
	return !(!isNil(endHour) && isNil(startHour));
};

export default isValidStartHourRequired;
