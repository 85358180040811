// @ts-nocheck
/* eslint-disable no-console */
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useQueries } from "react-query";

// Constants
import {
	DEFAULT_ADVANCE_PAYMENTS_FILTER,
	DEFAULT_EXPIRED_DOCUMENTS_FILTERS,
	DEFAULT_NOTIFICATIONS_LIST_FILTERS,
	INITIAL_SUSPICIOUS_FILTER,
} from "../constants";

// Reducers
import myTempMessaging from "../services/myTempMessaging";
import myTempNotifications from "../services/myTempNotifications";
import user from "../services/user";
import {
	prepareAgencyTempoFolderNameList,
	prepareCommercialCodeList,
	prepareFiltersForUnreadCount,
} from "../utils/notificationsListUtils";
import AppReducer from "./AppReducer";

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

const initialOrderMessage = {
	displayed: false,
	content: null,
	color: "primary",
	withIcon: false,
};

const initStore = {
	user: {},
	suspiciousSheetForm: {
		temporaryWorker: null,
		users: [],
		dissociatedUsers: [],
	},
	suspiciousFormTotalFields: [],
	suspiciousFormUnfilledFields: 0,
	suspiciousFilter: INITIAL_SUSPICIOUS_FILTER,
	notificationsFilters: DEFAULT_NOTIFICATIONS_LIST_FILTERS,
	expiredDocumentsFilters: DEFAULT_EXPIRED_DOCUMENTS_FILTERS,
	countUnreadNotifications: 0,
	countUnreadMessages: null,
	orderMessage: initialOrderMessage,
	advancePaymentsFilter: DEFAULT_ADVANCE_PAYMENTS_FILTER,
	userAgencies: [],
};

let initContext = {};

if (typeof window !== "undefined") {
	if (!isEmpty(window.localStorage.getItem("context"))) {
		initContext = {
			...initStore,
			...JSON.parse(window.localStorage.getItem("context")),
		};
		window.localStorage.removeItem("context");
	} else {
		Object.keys(initStore).forEach((key) => {
			initContext[key] = JSON.parse(window.localStorage.getItem(key)) || initStore[key];
			return null;
		});
	}
}

const AppProvider = ({ initialContext, children }) => {
	const [state, dispatch] = useReducer(AppReducer, {
		...initContext,
		...initialContext,
		orderMessage: initialOrderMessage,
	});
	const [userAgencies, setUserAgencies] = useState(null);

	useQueries([
		{
			queryKey: ["getUserAgencies"],
			queryFn: () => user.getUserAgencies(),
			onSuccess: (res) => {
				setUserAgencies(res?.data);
			},
		},
		{
			queryKey: ["postUnreadNotificationsCount", userAgencies],
			queryFn: () => {
				const { defaultAgencies, ...filteredFilters } = DEFAULT_NOTIFICATIONS_LIST_FILTERS;
				return myTempNotifications.postUnreadNotificationsCount({
					query: prepareFiltersForUnreadCount({
						...filteredFilters,
						type: {},
						agencies: userAgencies,
					}),
				});
			},
			enabled: userAgencies?.length > 0,
			onSuccess: (res) => {
				dispatch({
					type: "SET_TOTAL_UNREAD_NOTIFICATIONS_COUNT",
					payload: {
						count: res?.data?.count,
					},
				});
			},
		},
		{
			queryKey: ["getUnreadMessagesCount", userAgencies],
			queryFn: () =>
				myTempMessaging.postUnreadMessagesCount({
					agencies: prepareAgencyTempoFolderNameList(userAgencies),
					commercialCodes: prepareCommercialCodeList(userAgencies),
				}),
			onSuccess: (res) => {
				dispatch({
					type: "SET_TOTAL_UNREAD_MESSAGES_COUNT",
					payload: {
						count: res?.data?.count,
					},
				});
			},
			enabled: userAgencies !== null,
		},
	]);

	// Here we will have to check if there is a cookie set to get the auth infos and
	// dispatch the user info to the store
	// If not we will have to re-route the user to the login view on the Angular app
	useEffect(() => {
		// const { cookie } = document;
		// if (cookie) {
		// Get name followed by anything except a semicolon
		// Return everything after the equal sign, or an empty string if the cookie name not found
		// const cookiestring = decodeURIComponent(
		//   cookie ? cookie.toString().replace(/^[^=]+./, '') : '',
		// );
		// const token = RegExp('token_myDaily_v1=([^;]+)').exec(
		//   cookiestring,
		// );
		// if (token?.length && token[1]) dispatch({ type: 'SET_USER', payload: { token: JSON.parse(token[1]) } });
		// if (!token?.length) console.log('redirect login'); // else redirect to login
		// }
	}, []);

	useEffect(() => {
		if (!isEmpty(state) && typeof window !== "undefined") {
			Object.keys(state).forEach((key) => {
				window.localStorage.setItem(key, JSON.stringify(state[key]));
				return null;
			});
		}
	}, [state]);

	return (
		<AppStateContext.Provider value={state}>
			<AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
		</AppStateContext.Provider>
	);
};

AppProvider.propTypes = {
	initialContext: PropTypes.shape({}),
	children: PropTypes.node.isRequired,
};

AppProvider.defaultProps = {
	initialContext: {},
};

const useAppContext = () => [useContext(AppStateContext), useContext(AppDispatchContext)];

export { AppDispatchContext, AppProvider, AppStateContext, useAppContext };
