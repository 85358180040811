//@ts-nocheck
import { Badge, PaginationV2, Picto, SelectPopover, Text, Title, ToggleSwitch } from "@zolteam/react-ras-library";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Hook
import { useQueries } from "react-query";
import { useTitle } from "react-use";
// Utils
import cn from "../../utils/cn";
import { prepareAgencyTempoFolderNameList } from "../../utils/notificationsListUtils";
import { definePageOffset } from "../../utils/paginationUtils";
// Templates
import TableFilters from "../../components/templates/TableFilters";
import WithSideMenu from "../../components/templates/WithSideMenu/WithSideMenu";
import WithTopBarre from "../../components/templates/WithTopBarre";
// Organisms
import TableListing from "../../components/organisms/TableListing";
// Molecules
import { InputSearch } from "src/components/molecules/InputSearch/InputSearch";
import PaginationSearch from "../../components/molecules/PaginationSearch/PaginationSearch";
import TitleCount from "../../components/molecules/TitleCount";
// Atoms
import Tag from "../../components/atoms/Tag";
// Services
import myTempNotifications from "../../services/myTempNotifications";
// Constants
import {
	DEFAULT_NOTIFICATIONS_SEARCH_PARAMS,
	NOTIFICATION_STATUS_ALL,
	NOTIFICATION_STATUS_UNREAD,
	PAGINATION_OFFSET_OPTIONS,
} from "../../constants";
// Style
import { format } from "date-fns";
import { cloneDeep, every } from "lodash";
import { useFiltersContext } from "src/store/FiltersContext/useFiltersContext";
import NotificationFilterModal from "./NotificationFilterModal";
import style from "./Notifications.module.css";
import NotificationsTableColumns from "./NotificationsTableColumns";

function removeFalseValues(obj: any): any {
	// Parcourt les clés de l'objet
	// Crée un nouvel objet pour stocker les valeurs filtrées
	const newObj: any = {};

	Object.keys(obj).forEach((key) => {
		const value = obj[key];
		if (typeof value === "object" && value !== null) {
			// Si la valeur est un objet, on appelle récursivement la fonction
			const cleanedValue = removeFalseValues(value);
			// Si l'objet nettoyé n'est pas vide, on l'ajoute au nouvel objet
			if (Object.keys(cleanedValue).length > 0) {
				newObj[key] = cleanedValue;
			}
		} else if (value !== false) {
			// Si la valeur n'est pas false, on l'ajoute au nouvel objet
			newObj[key] = value;
		}
	});

	return newObj;
}

const countActiveFilters = (filters) => {
	let counter = 0;

	counter += filters.agencies?.length > 0 && filters.agencies?.length !== filters.defaultAgencies?.length ? 1 : 0;
	counter += filters.createdAt !== null ? 1 : 0;
	counter += every(filters.type.timesheets, (value) => value === false) ? 0 : 1;
	counter += every(filters.type.ratings, (value) => value === false) ? 0 : 1;

	return counter;
};

const Notifications = () => {
	// Hooks
	const { t } = useTranslation();
	const { filters, setFilter, setFilters, count, setCount } = useFiltersContext("notifications", countActiveFilters);

	useTitle(t("clientsMyTemp.notifications.title"));

	// States
	const [data, setData] = useState();
	const [options, setOptions] = useState(DEFAULT_NOTIFICATIONS_SEARCH_PARAMS);
	const [notificationsCount, setNotificationsCount] = useState(0);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

	// API Calls
	const [postNotificationsSearchQuery, postFilteredNotificationsCountQuery] = useQueries([
		{
			queryKey: ["postNotificationsSearch", filters, options],
			queryFn: ({ signal }) => {
				const { defaultAgencies, ...filteredFilters } = filters;
				return myTempNotifications
					.postNotificationsSearch(
						{
							query: {
								...filteredFilters,
								type: removeFalseValues(cloneDeep(filters.type)),
								createdAt: filters.createdAt ? format(filters.createdAt, "yyyy-MM-dd") : null,
								agencies: prepareAgencyTempoFolderNameList(filters.agencies),
							},
							...options,
						},
						signal
					)
					.then((resp) => {
						setData(resp.data);
						return resp.data;
					});
			},
		},
		{
			queryKey: ["postFilteredNotificationsCount", filters],
			queryFn: ({ signal }) => {
				const { defaultAgencies, ...filteredFilters } = filters;
				return myTempNotifications
					.postUnreadNotificationsCount(
						{
							query: {
								...filteredFilters,
								type: removeFalseValues(cloneDeep(filters.type)),
								agencies: prepareAgencyTempoFolderNameList(filters.agencies),
								status: NOTIFICATION_STATUS_UNREAD,
							},
						},
						signal
					)
					.then((resp) => {
						setNotificationsCount(resp.data?.count ?? 0);
						return resp.data;
					});
			},
			enabled: filters?.agencies?.length > 0,
		},
	]);

	// Destructuring useQuery and useMutation
	const {
		isError: isErrorNotificationsSearch,
		isLoading: isLoadingNotificationsSearch,
		isFetching: isFetchingNotificationsSearch,
	} = postNotificationsSearchQuery;

	const { isLoading: isLoadingNotificationsCount } = postFilteredNotificationsCountQuery;

	// Handlers
	const handlePaginationChange = (params) =>
		setOptions({
			...options,
			...params,
		});

	const handleRefresh = () => {
		postNotificationsSearchQuery.refetch();
		postFilteredNotificationsCountQuery.refetch();
		handlePaginationChange({ page: 1 });
	};

	const handleSearch = (searchFilters) => {
		if (searchFilters?.orderBy) {
			setOptions({
				...options,
				page: 1,
				...searchFilters,
			});
		} else {
			setFilters((prev) => ({
				...prev,
				...searchFilters,
			}));
			setOptions({
				...options,
				page: 1,
			});
		}
	};

	const handleOnlyUnread = () => {
		setFilter(
			"status",
			filters.status === NOTIFICATION_STATUS_UNREAD ? NOTIFICATION_STATUS_ALL : NOTIFICATION_STATUS_UNREAD
		);
		handlePaginationChange({ page: 1 });
	};

	const searchByFilters = (filters) => {
		setCount(countActiveFilters(filters));
		handleSearch(filters);
		setIsFilterModalOpen(false);
	};

	const onCloseModal = () => setIsFilterModalOpen(false);

	const getPageMaxNumber = () => Math.round((data?.total ?? 0) / options.limit);

	const getStartPagination = options.limit * options.page - options.limit + 1;

	const getEndPagination = options.limit * options.page <= data?.total ? options.limit * options.page : data?.total;

	return (
		<WithSideMenu>
			<WithTopBarre
				quickEntryContent={<div>test</div>}
				title={
					<TitleCount count={notificationsCount}>
						<Title tag="h1" size="heading01" lineHeight="s">
							{t("clientsMyTemp.notifications.title")}
						</Title>
						{notificationsCount !== null && !isLoadingNotificationsCount ? (
							<Tag size="m" radius="s" color="primary" className="ml-10">
								{`${notificationsCount} ${t("clientsMyTemp.notifications.unread")}`}
							</Tag>
						) : null}
					</TitleCount>
				}
				containerClassname="container"
			>
				<TableFilters
					rightContent={
						<div
							className={cn([style.container, "mt-m items-center gap-4"])}
							style={{ marginRight: "1rem" }}
						>
							<InputSearch
								label={t("clientsMyTemp.notifications.filters.searchText")}
								onChange={(value) => {
									handleSearch({ search: value });
								}}
								minLength={0}
								debounced
								name="search"
								value={filters?.query?.searchText || ""}
								className="min-w-[370px] w-full sm:w-fit h-12"
							/>

							<ToggleSwitch
								name="unreadToggle"
								id="unreadToggle"
								onChange={handleOnlyUnread}
								toggled={filters.status === NOTIFICATION_STATUS_UNREAD}
							>
								<span className={style.toggleLabel}>
									{t("clientsMyTemp.notifications.filters.onlyUnreadToggle")}
								</span>
							</ToggleSwitch>

							<button onClick={handleRefresh} className="flex items-center gap-2">
								<Picto
									className={style.iconPink}
									icon="refresh"
									style={{ width: "20px", height: "20px" }}
								/>
								<Text tag="span">{t("global.filters.refresh")}</Text>
							</button>
							<button className="flex items-center gap-2" onClick={() => setIsFilterModalOpen(true)}>
								<Picto
									className={style.iconPink}
									icon="sliders"
									style={{ width: "20px", height: "20px" }}
								/>
								<Text tag="span">{t("global.filtersPanel.openPanel")}</Text>
								<Badge color="primary" content={count} />
							</button>
						</div>
					}
				/>

				<TableListing
					loading={isLoadingNotificationsSearch || isFetchingNotificationsSearch}
					error={isErrorNotificationsSearch}
					data={data?.items}
					handleSearch={handleSearch}
					initialColumnSort={{
						field: "createdAt",
						orderBy: "DESC",
					}}
					canBeChecked={false}
					footer={
						data?.total ? (
							<>
								<PaginationV2
									previousLabel={<Picto icon="chevronLeft" />}
									nextLabel={<Picto icon="chevronRight" />}
									breakLine={
										<PaginationSearch
											onInputChange={handlePaginationChange}
											max={getPageMaxNumber()}
										/>
									}
									currentPage={options.page}
									totalCount={data.total}
									pageSize={options.limit}
									siblingCount={2}
									onPageChange={(activePage) =>
										handlePaginationChange({
											page: activePage,
										})
									}
								/>
								<Text tag="span" size="paragraph02" color="grey">
									{t("global.pagination.resultsPagination", {
										resultStart: getStartPagination,
										resultEnd: getEndPagination,
										resultTotal: data?.total,
									})}
								</Text>
								<SelectPopover
									name="limit"
									options={PAGINATION_OFFSET_OPTIONS}
									onChange={(value) =>
										handlePaginationChange({
											limit: value,
											page: definePageOffset(value, options.limit, options.page),
										})
									}
									value={
										options.limit
											? PAGINATION_OFFSET_OPTIONS.find((option) => option.value === options.limit)
											: null
									}
								/>
							</>
						) : null
					}
				>
					{NotificationsTableColumns({
						setCount: setNotificationsCount,
						count: notificationsCount,
						isUnreadToogleActive: filters.status === NOTIFICATION_STATUS_UNREAD,
						data,
						setData,
					})}
				</TableListing>
			</WithTopBarre>

			<NotificationFilterModal
				onCloseModal={onCloseModal}
				isDisplayed={isFilterModalOpen}
				onSubmit={searchByFilters}
			/>
		</WithSideMenu>
	);
};

export default Notifications;
