import client from "src/services/client";

export interface CommonQualificationsItem {
	id: number;
	name: string;
}

export interface CommonQualificationsApiResponse {
	total: number;
	items: CommonQualificationsItem[];
}

interface QueryData {
	search: string;
	limit: number;
}

export const getCommonQualifications = (params: QueryData): Promise<AxiosResponse<CommonQualificationsApiResponse>> =>
	client.post("/api-client-v2/common-qualification/search", params);
