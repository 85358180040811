// @ts-nocheck
/* eslint-disable no-unused-vars */
import { Title } from "@zolteam/react-ras-library";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

// Style
import cn from "../../../../../utils/cn";
import style from "./CommandRulesFormBlock.module.css";

// Constants
import { CREATE_MODE, EDIT_MODE } from "../../../../../constants";

const CommandRulesFormBlock = ({ isOrderFetched }) => {
	const { t } = useTranslation();
	const { orderId } = useParams();
	const { values } = useFormikContext();
	const { clientOption } = values;

	// States
	const isNewCommand = orderId === CREATE_MODE;
	const commandFormMode = isNewCommand ? CREATE_MODE : EDIT_MODE;

	const instructionsData = useMemo(() => {
		if (commandFormMode === EDIT_MODE) {
			// Displaying order instructions
			const {
				clientBillingDescription,
				clientBillingTempoId,
				clientCustomInstructionsDescription,
				clientCustomInstructionsTempoId,
				clientReferenceSalaryDescription,
				clientReferenceSalaryTempoId,
				clientSalaryDescription,
				clientSalaryTempoId,
			} = values || {};
			return {
				customInstructionsDescription: clientCustomInstructionsDescription,
				customInstructionsTempoId: clientCustomInstructionsTempoId,
				salaryDescription: clientSalaryDescription,
				salaryTempoId: clientSalaryTempoId,
				referenceSalaryDescription: clientReferenceSalaryDescription,
				referenceSalaryTempoId: clientReferenceSalaryTempoId,
				billingDescription: clientBillingDescription,
				billingTempoId: clientBillingTempoId,
			};
		}
		// Displaying client information
		return clientOption || {};
	}, [values, clientOption, commandFormMode]);

	const {
		customInstructionsDescription,
		customInstructionsTempoId,
		salaryDescription,
		salaryTempoId,
		referenceSalaryDescription,
		referenceSalaryTempoId,
		billingDescription,
		billingTempoId,
	} = instructionsData;

	if (!isOrderFetched && !isNewCommand) {
		return (
			<div className={style.skeleton}>
				<div className={style.skeletonFirstColumn}>
					<div className={style.skeletonElement}>
						<Skeleton containerClassName={style.boxHeader} height={20} borderRadius={25} />
						<Skeleton height={100} borderRadius={25} />
					</div>
					<div className={style.skeletonElement}>
						<Skeleton containerClassName={style.boxHeader} height={20} borderRadius={25} />
						<Skeleton height={100} borderRadius={25} />
					</div>
				</div>
				<div className={style.skeletonSecondColumn}>
					<div className={style.skeletonElement}>
						<Skeleton containerClassName={style.boxHeader} height={20} borderRadius={25} />
						<Skeleton height={100} borderRadius={25} />
					</div>
					<div className={style.skeletonElement}>
						<Skeleton containerClassName={style.boxHeader} height={20} borderRadius={25} />
						<Skeleton height={100} borderRadius={25} />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={style.wrapper}>
			<div className={style.firstColumn}>
				<div className={cn([style.box])}>
					<div className={style.header}>
						<Title
							tag="h3"
							fontWeight="normal"
							size="paragraph01"
							lineHeight="s"
							className={style.subTitle}
						>
							{t("commands.instructionForm.specialInstructions")}
						</Title>
						<span className={style.instructionId}>{customInstructionsTempoId}</span>
					</div>
					<div className={style.textarea} placeholder={t("commands.notes")}>
						{customInstructionsDescription}
					</div>
				</div>
				<div className={style.box}>
					<div className={style.header}>
						<Title
							tag="h3"
							fontWeight="normal"
							size="paragraph01"
							lineHeight="s"
							className={style.subTitle}
						>
							{t("commands.instructionForm.remuneration")}
						</Title>
						<span className={style.instructionId}>{salaryTempoId}</span>
					</div>
					<div
						className={style.textarea}
						name="qualificationNote"
						label={null}
						disabled
						placeholder={t("commands.notes")}
					>
						{salaryDescription}
					</div>
				</div>
			</div>
			<div className={style.secondColumn}>
				<div className={cn([style.box])}>
					<div className={style.header}>
						<Title
							tag="h3"
							fontWeight="normal"
							size="paragraph01"
							lineHeight="s"
							className={style.subTitle}
						>
							{t("commands.instructionForm.referenceSalary")}
						</Title>
						<span className={style.instructionId}>{referenceSalaryTempoId}</span>
					</div>
					<div
						className={style.textarea}
						name="qualificationNote"
						label={null}
						disabled
						placeholder={t("commands.notes")}
					>
						{referenceSalaryDescription}
					</div>
				</div>
				<div className={cn([style.box])}>
					<div className={style.header}>
						<Title
							tag="h3"
							fontWeight="normal"
							size="paragraph01"
							lineHeight="s"
							className={style.subTitle}
						>
							{t("commands.instructionForm.billing")}
						</Title>
						<span className={style.instructionId}>{billingTempoId}</span>
					</div>
					<div
						className={style.textarea}
						name="qualificationNote"
						label={null}
						disabled
						placeholder={t("commands.notes")}
					>
						{billingDescription}
					</div>
				</div>
			</div>
		</div>
	);
};

CommandRulesFormBlock.propTypes = {
	isOrderFetched: PropTypes.bool.isRequired,
};

export default CommandRulesFormBlock;
