import { Picto, SelectStatus, Text } from "@zolteam/react-ras-library";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import IdBox from "src/components/atoms/IdBox";
import MyScanPicto from "src/components/atoms/MyScanPicto/MyScanPicto";
import Tag from "src/components/atoms/Tag";
import ContactBlock from "src/components/organisms/ContactBlock/ContactBlock";
import SideMenuSecondary from "src/components/templates/SideMenuSecondary/SideMenuSecondary";
import { TTemporaryWorkerAgency } from "src/types/TTemporaryWorker";
import WithSideMenu from "../../components/templates/WithSideMenu";
import WithTopBarre from "../../components/templates/WithTopBarre";
import userTemporaryWorkerService from "../../services/userTemporaryWorkerService";

interface TemporaryWorkerSheetHeaderProps {
	user: any;
	isLoading?: boolean;
}

type TParams = {
	userId: string;
};

const TemporaryWorkerSheetHeader: FC<TemporaryWorkerSheetHeaderProps> = ({ user, isLoading }) => {
	const { t } = useTranslation();
	const agenciesList = Object.values(user?.agencies || {}).map((agency: TTemporaryWorkerAgency) => ({
		value: agency?.userId,
		label: agency.agencyName,
	}));

	const history = useHistory();
	const location = useLocation();
	const userId = useParams<TParams>()?.userId;
	const handleAgencyChange = (agency: any) => {
		// Replace userId in the current path
		const currentPath = location.pathname.replace(/\/users\/\d+/, `/users/${agency.value}`);
		history.push(currentPath);
	};
	const agency = {
		value: user?.agencies && user.agencies[userId]?.userId ? user.agencies[userId].userId : null,
		label: user?.agencies && user.agencies[userId]?.agencyName ? user.agencies[userId].agencyName : null,
	};

	return (
		<div className="flex flex-col gap-8">
			<div className="flex flex-col gap-3">
				<div className="flex flex-row items-center justify-between gap-3">
					<div className="flex-1">
						<IdBox isLoading={isLoading}>{user?.uniqueId || "-"}</IdBox>
					</div>
					<button className="flex justify-end flex-1 w-full">
						<MyScanPicto />
					</button>
				</div>

				{agenciesList.length > 1 ? (
					<SelectStatus
						options={(isLoading && []) || agenciesList || []}
						value={isLoading ? null : agency}
						onChange={(agency: any) => {
							handleAgencyChange(agency);
						}}
						noOptionsMessage={() => {
							return t("commands.selectNoAgenciesFound");
						}}
						placeholder={""}
						variant="default"
					/>
				) : (
					<Tag className="flex justify-start">{agenciesList[0]?.label}</Tag>
				)}

				<div className="flex flex-row items-center justify-between gap-2">
					<IdBox isLoading={isLoading}>{user?.twStatus ? t(`twStatus.${user?.twStatus}`) : "-"}</IdBox>
					<IdBox isLoading={isLoading}>{user?.tempoId || "-"}</IdBox>
				</div>
			</div>

			<div>
				{isLoading ? (
					<Skeleton
						className=""
						baseColor="var(--color-neutral-700)"
						highlightColor="var(--color-neutral-600)"
						height={50}
						width={"100%"}
					/>
				) : user ? (
					<div className="flex flex-col gap-2">
						<h4 className="text-xl font-medium">
							{user.firstName} {user.lastName}
						</h4>
						<h5 className="text-l font-extralight">{user.mainQualificationName}</h5>
						<ContactBlock phone={user?.phone} phone2={user?.phone2} email={user?.email} />
					</div>
				) : (
					<>-</>
				)}
			</div>
		</div>
	);
};

const TemporaryWorkerSheet: FC = () => {
	const { t } = useTranslation();
	const userId = useParams<TParams>()?.userId;

	const routes = [
		{
			path: `/users/${userId}/suivi`,
			routeName: "suivi",
			label: t("usersListing.menu.followup"),
			component: <div>Suivi</div>,
		},
		{
			path: `/users/${userId}/documents`,
			routeName: "documents",
			label: t("usersListing.menu.documents"),
			component: <div>Documents</div>,
		},
		{
			path: `/users/${userId}/administrative`,
			routeName: "administrative",
			label: t("usersListing.menu.administrative"),
			component: <div>Administratif</div>,
		},
		{
			path: `/users/${userId}/profil-professionnel`,
			routeName: "profil-professionnel",
			label: t("usersListing.menu.profilPro"),
			component: <div>Profil pro</div>,
		},
		{
			path: `/users/${userId}/missions`,
			routeName: "missions",
			label: t("usersListing.menu.missions"),
			component: <div>missions</div>,
		},
		{
			path: `/users/${userId}/paie`,
			routeName: "pay",
			label: t("usersListing.menu.pay"),
			component: <div>pay</div>,
		},
		{
			path: `/users/${userId}/demat`,
			routeName: "demat",
			label: t("usersListing.menu.demat"),
			component: <div>demat</div>,
		},
		{
			path: `/users/${userId}/communication`,
			routeName: "communication",
			label: t("usersListing.menu.communication"),
			component: <div>communication</div>,
		},
		{
			path: `/users/${userId}/formation`,
			routeName: "training",
			label: t("usersListing.menu.training"),
			component: <div>training</div>,
		},
		{
			path: `/users/${userId}/accidents`,
			routeName: "workAccident",
			label: t("usersListing.menu.workAccident"),
			component: <div>workAccident</div>,
		},
	];

	const getTemporaryWorker = useQuery({
		queryKey: ["getUser", userId],
		queryFn: () => userTemporaryWorkerService.getUserTemporaryWorker(userId),
	});

	const { isFetching: isFetchingGetTempWorker, data: dataTempWorker, error } = getTemporaryWorker;

	return (
		<WithSideMenu>
			<WithTopBarre
				leftContent={
					<div style={{ display: "flex" }}>
						<NavLink
							to={"/users"}
							className={"flex flex-row items-center gap-1"}
							onKeyDown={(e) => {
								if (e.key === " " || e.key === "Enter") {
									e.preventDefault();
									(e.target as HTMLElement).click();
								}
							}}
						>
							<Picto style={{ width: "24px" }} icon="chevronLeft" />
							<Text fontWeight="normal" tag="span">
								{t("usersListing.allUsersLink")}
							</Text>
						</NavLink>
					</div>
				}
				containerClassname="flex flex-row"
			>
				<SideMenuSecondary
					routes={routes}
					header={
						<TemporaryWorkerSheetHeader
							user={error ? null : dataTempWorker?.data || null}
							isLoading={isFetchingGetTempWorker}
						/>
					}
				/>
			</WithTopBarre>
		</WithSideMenu>
	);
};

export default TemporaryWorkerSheet;
