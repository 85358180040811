import { capitalizeWords } from "src/utils/string";
import { FormikTouched } from "formik";
import { OrderInterface } from "src/types/OrderInterface";
import { OrderWorkingTimeSlotInterface } from "src/types/OrderWorkingTimeSlotInterface";
import { isEmpty, omit } from "lodash";

/**
 * Prepare order values before use create/edit endpoint
 */
export const getPreparedOrderValues = (
	isNewCommand: boolean,
	touched: FormikTouched<OrderInterface>,
	values: OrderInterface,
	value: any
) => {
	const cleanedValues = omit(
		{
			...values,
			period: {
				// Don't have choice here to transform the date for the API, they don't accept standard format,
				// but they send ISO Format
				startDate: values.period.startDate.replace(/T|[.||+][A-Z|0-9:]*$/g, " ").trim(),
				endDate: values.period.endDate.replace(/T|[.||+][A-Z|0-9:]*$/g, " ").trim(),
			},
			contact: {
				...values.contact,
				name: capitalizeWords(values.contact.name),
			},
			notifyUsers: value?.notifyUsers || false,
		},
		[
			"agencyOption",
			"commercialCodeOption",
			"clientOption",
			"appealCaseOption",
			"qualificationOption",
			"workAddressCountryOption",
			"workAddressMeetingCountryOption",
		]
	);

	cleanedValues.workingTime.slots = cleanedValues.workingTime.slots.filter(
		(slot: OrderWorkingTimeSlotInterface) => !isEmpty(slot.startHour) && !isEmpty(slot.endHour)
	);

	// We need to reset coordinates if in edition mode, country is different than France and if the address has
	// changed
	const shouldResetCoordinates = !isNewCommand && values.workAddress.country !== "France" && touched.workAddress;
	if (shouldResetCoordinates) {
		return {
			...cleanedValues,
			workAddress: {
				...cleanedValues.workAddress,
				latitude: null,
				longitude: null,
			},
		};
	}
	return cleanedValues;
};

export default getPreparedOrderValues;
