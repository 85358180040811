import * as Yup from "yup";
import { WORK_PERIOD_TYPES } from "src/constants";
import { isNil } from "lodash";
import { OrderInterface } from "src/types/OrderInterface";
const isValidEndDayHour = (endHour: string | null, context: Yup.TestContext<OrderInterface>): boolean => {
	const { workPeriodType } = context.options.context;
	const { startHour } = context.parent;

	if (workPeriodType === WORK_PERIOD_TYPES.NIGHT) {
		return true;
	}

	const splitStartHour = startHour?.split(":");
	const splitEndHour = endHour?.split(":");

	if (isNil(splitStartHour)) {
		return true;
	}

	if (isNil(splitEndHour)) {
		return false;
	}

	const startDate = new Date().setHours(+splitStartHour[0], +splitStartHour[1], 0, 0);
	const endDate = new Date().setHours(+splitEndHour[0], +splitEndHour[1], 0, 0);

	return startDate <= endDate;
};

export default isValidEndDayHour;
