// @ts-nocheck
import React from "react";
import { Button, Picto, Text } from "@zolteam/react-ras-library";

import PropTypes from "prop-types";

import { t } from "i18next";
import { useMutation } from "react-query";
import { formatDate } from "../../utils/formatDate";

import ColumnListing from "../../components/molecules/ColumnListing/ColumnListing";
import TableInfo from "../../components/molecules/TableInfo";

import { NOTIFICATION_STATUS_UNREAD, NOTIFICATION_STATUS_READ } from "../../constants";

import myTempNotifications from "../../services/myTempNotifications";

import style from "./Notifications.module.css";
import Tag from "src/components/atoms/Tag";

const NotificationsTableColumns = ({ setCount, count, isUnreadToogleActive, data, setData }) => {
	const patchMyTempNotificationStatusMutation = useMutation(myTempNotifications.patchMyTempNotificationStatus);

	const handleUpdateNotificationStatus = async ({ id, status }) => {
		const newStatus = status === NOTIFICATION_STATUS_UNREAD ? NOTIFICATION_STATUS_READ : NOTIFICATION_STATUS_UNREAD;

		patchMyTempNotificationStatusMutation.mutate({
			notificationId: id,
			newStatus,
		});

		const index = data?.items.findIndex((notification) => notification.id === id);

		// Update status in data list
		const notificationToUpdate = data?.items.find((notification) => notification.id === id);
		data?.items.splice(index, 1, {
			...notificationToUpdate,
			status: newStatus,
		});

		//  Remove notification from list if unread notification toogle is active
		if (isUnreadToogleActive) {
			await setTimeout(() => {
				data?.items.splice(index, 1);
				setData({
					...data,
					total: (data?.total ?? 0) - 1,
				});
			}, 1500);
		}

		// Update unread notifications counter
		setCount(newStatus === NOTIFICATION_STATUS_READ ? count - 1 : count + 1);
	};

	return [
		<ColumnListing
			id="status"
			key="status"
			field={(elem) => ({ id: elem.id, status: elem.status })}
			component={({ data: { id, status } }) => (
				<span>
					<Button
						color="transparent"
						type="button"
						style={{ display: "flex" }}
						onClick={() => handleUpdateNotificationStatus({ id, status })}
					>
						<Picto
							icon={status === NOTIFICATION_STATUS_UNREAD ? "ringingBellActive" : "ringingBell"}
							className={style.pictoRingingBell}
							style={{
								color:
									status === NOTIFICATION_STATUS_UNREAD
										? "var(--color-primary-500)"
										: "var(--color-black)",
							}}
						/>
					</Button>
				</span>
			)}
			tdClassName="min-column-width"
		/>,
		<ColumnListing
			id="clientName"
			key="clientName"
			name={
				<Text tag="span" size="paragraph02" color="grey">
					{t("clientsMyTemp.notifications.client")}
				</Text>
			}
			field={(elem) => ({
				clientName: elem.client,
				isHighlighted: elem.isHighlighted,
			})}
			component={({ data: { clientName, isHighlighted } }) => (
				<Text
					tag="span"
					size="paragraph02"
					style={{
						color: isHighlighted ? "var(--color-warning-500)" : "var(--color-black)",
					}}
				>
					{clientName}
				</Text>
			)}
			tdClassName="min-column-width"
		/>,
		<ColumnListing
			id="clientCode"
			key="clientCode"
			name={
				<Text tag="span" size="paragraph02" color="grey" className={style.customHeaderWidth}>
					{t("clientsMyTemp.notifications.clientCode")}
				</Text>
			}
			field={(elem) => ({
				clientCode: elem.clientCode,
				isHighlighted: elem.isHighlighted,
			})}
			component={({ data: { clientCode, isHighlighted } }) => (
				<Text
					tag="span"
					size="paragraph02"
					className={style.customHeaderWidth}
					style={{
						color: isHighlighted ? "var(--color-warning-500)" : "var(--color-black)",
					}}
				>
					{clientCode}
				</Text>
			)}
			tdClassName="min-column-width"
		/>,
		<ColumnListing
			id="content"
			key="content"
			name={
				<Text tag="span" size="paragraph02" color="grey">
					{t("clientsMyTemp.notifications.content")}
				</Text>
			}
			field={(elem) => ({
				content: elem.content,
				isHighlighted: elem.isHighlighted,
			})}
			component={({ data: { content, isHighlighted } }) => (
				<Text
					tag="span"
					size="paragraph02"
					style={{
						color: isHighlighted ? "var(--color-warning-500)" : "var(--color-black)",
					}}
				>
					{content || ""}
				</Text>
			)}
		/>,
		<ColumnListing
			id="docPdf"
			key="docPdf"
			name={
				<Text tag="span" size="paragraph02" color="grey" className={style.customHeaderWidth}>
					{t("clientsMyTemp.notifications.document")}
				</Text>
			}
			field={(elem) => ({ details: elem.details })}
			component={({ data: { details } }) => (
				<div className="items-center gap-3 row">
					{!!details?.url && (
						<a
							href={details?.url}
							target="_blank"
							aria-label="pdf"
							rel="noreferrer"
							onClick={(e) => {
								e.stopPropagation(); // Prevents the event from being captured by the parent "a" element generated by the Table component
							}}
						>
							<Picto icon="pdf" className={style.iconPdf} />
						</a>
					)}
					{details?.hasComment && (
						<Tag color={"primary"} size="s">
							{t("clientsMyTemp.notifications.comment")}
						</Tag>
					)}
				</div>
			)}
			tdClassName="min-column-width"
		/>,
		<ColumnListing
			id="agencyName"
			key="agencyName"
			name={
				<Text tag="span" size="paragraph02" color="grey">
					{t("clientsMyTemp.notifications.agency")}
				</Text>
			}
			field={(elem) => ({ agencyName: elem.agency })}
			component={({ data: { agencyName } }) => (
				<Text tag="span" size="paragraph02">
					{agencyName || ""}
				</Text>
			)}
		/>,
		<ColumnListing
			id="createdAt"
			key="createdAt"
			name={
				<Text tag="span" size="paragraph02" color="grey">
					{t("clientsMyTemp.notifications.createdAt")}
				</Text>
			}
			field={(elem) => ({ createdAt: elem.createdAt })}
			component={({ data: { createdAt } }) => (
				<TableInfo info={formatDate(createdAt, "dd MMM yyyy")} subInfo={formatDate(createdAt, "HH:mm")} />
			)}
			isSortable
			tdClassName="min-column-width"
		/>,
	];
};

NotificationsTableColumns.propTypes = {
	setCount: PropTypes.func.isRequired,
	count: PropTypes.number.isRequired,
	isUnreadToogleActive: PropTypes.bool.isRequired,
	data: PropTypes.shape({
		items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		total: PropTypes.number.isRequired,
	}).isRequired,
	setData: PropTypes.func.isRequired,
};

export default NotificationsTableColumns;
