import React, { useCallback, useEffect, useState } from "react";

// Components
import { Picto } from "@zolteam/react-ras-library";

// Utils & misc

// Style
import { debounce } from "lodash";
import { DEBOUNCE_DELAY } from "src/constants";
import cn from "src/utils/cn";
import style from "./InputSearch.module.css";

interface IInputSearchProps {
	value?: string;
	type?: string;
	name: string;
	label: string;
	className?: string;
	onClick?: (
		value: string,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement>
	) => void;
	disabled?: boolean;
	onChange?: (value: string) => void;
	resetValueProps?: (name: string, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	minLength?: number;
	delay?: number;
	debounced?: boolean;
}

class IInputSearchProps {}

export const InputSearch: React.FC<IInputSearchProps> = (props: IInputSearchProps) => {
	const {
		type = "text",
		name,
		label,
		onClick = () => {},
		className,
		disabled = false,
		value = "",
		resetValueProps,
		minLength = 0,
		debounced = false,
		delay = DEBOUNCE_DELAY,
		onChange = () => {},
	} = props;

	const [inputValue, setInputValue] = useState(value);

	const debouncedSearch = useCallback(
		debounce((value) => {
			onChange && onChange(value);
		}, delay),
		[]
	);

	const handleSearch = (inputValue: string) => {
		if (minLength && inputValue.length < minLength) return;

		if (!debounced) return onChange?.(inputValue);
		return debouncedSearch(inputValue);
	};

	useEffect(() => {
		setInputValue(value || "");
	}, [value]);

	return (
		<div className={cn([className])}>
			<div
				className={cn([
					style.field,
					inputValue.length <= 0 ? style.empty : null,
					disabled && style.disabled,
					disabled && "dark:!opacity-30 [&_*]:cursor-not-allowed cursor-not-allowed",
					"dark:bg-neutral-900 dark:border-neutral-800 [&_*]:dark:text-neutral-300 border-2 border-neutral-300 border-solid dark:border-neutral-700 dark:focus-within:border",
				])}
			>
				<input
					id={name}
					data-testid={name}
					type={type}
					name={name}
					placeholder={label}
					value={inputValue}
					onChange={(e) => {
						setInputValue(e.target.value);
						handleSearch(e.target.value);
					}}
					onKeyPress={(event) => event.key === "Enter" && onClick(inputValue, event)}
					autoComplete={"off"}
				/>
				{!!inputValue?.length && (
					<button
						type="button"
						className={cn([
							style.buttonPicto,
							"duration-200",
							inputValue?.length > 0 ? "opacity-100" : "opacity-0 pointer-events-none",
						])}
						onClick={(e) => {
							setInputValue("");
							onChange && onChange("");
							resetValueProps && resetValueProps(name, e);
						}}
					>
						<Picto icon="close" className="text-neutral-500" />
					</button>
				)}

				<button type="button" className={style.buttonPicto} onClick={(e) => onClick(inputValue, e)}>
					<Picto icon="search" style={{ height: "18px" }} />
				</button>
			</div>
		</div>
	);
};
