import { OrderStatus } from "../types/commandServiceTypes";
import { AxiosResponse } from "axios";
import client from "../client";

export interface PatchOrderStatusInterface {
	status: OrderStatus;
	statusReasonId: number;
	statusReasonLabel: string;
}

export const patchOrderStatus = (
	orderId: string,
	newStatus: OrderStatus,
	statusReasonId: string,
	statusReasonDescription: string
): Promise<AxiosResponse<PatchOrderStatusInterface>> =>
	client.patch<PatchOrderStatusInterface>(`/api-client-v2/orders/${orderId}/status`, {
		newStatus,
		statusReasonId,
		statusReasonDescription,
	});
