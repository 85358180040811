// @ts-nocheck
/* eslint-disable import/prefer-default-export */
/**
 * Checks if the given time in milliseconds has already passed.
 *
 * @param {number} time - The time in milliseconds to check.
 * @returns {boolean} - Returns `true` if the given time is in the past, otherwise `false`.
 */
export const isTimePast = (time, delay) => {
	const now = new Date().getTime();
	return time < now - delay;
};

export const getDayDiff = (startDate: Date, endDate: Date): number => {
	if (startDate > endDate) {
		return 0;
	}

	const timeDifference = Number(endDate) - Number(startDate);
	const millisPerDay = 1000 * 60 * 60 * 24;
	return Math.ceil(timeDifference / millisPerDay);
};

export const getDayOfWeek = (date: Date): number => {
	const dayOfWeek = date.getDay();
	return dayOfWeek === 0 ? 7 : dayOfWeek;
};
