import { FC } from "react";

import { Checkbox, RadioButton } from "@zolteam/react-ras-library";
import FilterCardHeader from "../../molecules/FilterCardHeader/FilterCardHeader";

import cn from "src/utils/cn";

import style from "./CheckboxListFilter.module.css";

interface ICheckboxListFilterProps {
	items: Record<string, boolean>;
	onChange: (type: string) => void;
	indentedFirstLevel?: boolean;
	labelClassName?: string;
	handleReset?: () => void;
	title?: string;
	getId?: (index: number) => string;
	getLabel?: (type: string) => string;
	getValue?: (type: string) => string | boolean;
	listStyle?: React.CSSProperties;
	orientation?: "horizontal" | "vertical";
	type?: "checkbox" | "radio";
	name?: string;
}

const CheckboxListFilter: FC<ICheckboxListFilterProps> = ({
	items,
	onChange,
	indentedFirstLevel = false,
	labelClassName = "",
	handleReset = null,
	title = "",
	getId = (index) => `checkbox-${index}`,
	getLabel = (type) => type,
	getValue = (type) => type,
	listStyle = {},
	orientation = "vertical",
	type = "checkbox",
	name = "",
}) => (
	<div>
		{!!title && <FilterCardHeader title={title} handleReset={handleReset} />}
		<div
			style={{
				display: "flex",
				gap: "0.4rem",
				...(orientation === "horizontal"
					? {
							justifyContent: "space-between",
							flexWrap: "wrap",
						}
					: {
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
						}),
				...listStyle,
			}}
		>
			{Object.keys(items)?.map((item, index) => {
				const elemId = getId(index);
				return (
					<label
						key={item}
						style={{
							marginLeft: indentedFirstLevel && index !== 0 ? "8px" : "0px",
						}}
						className={cn(["row gap-1 items-center", labelClassName])}
						htmlFor={elemId}
					>
						{type === "radio" ? (
							<RadioButton
								type={type}
								className={style.checkbox}
								id={elemId}
								checked={getValue(item)}
								onChange={() => onChange(item)}
								color="primary"
								name={elemId}
							/>
						) : (
							<Checkbox
								type={type}
								className={style.checkbox}
								id={elemId}
								value={getValue(item)}
								onChange={() => onChange(item)}
								theme="primary"
							/>
						)}
						<span>{getLabel(item)}</span>
					</label>
				);
			})}
		</div>
	</div>
);

export default CheckboxListFilter;
