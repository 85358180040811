import React from "react";
import { Tooltip } from "@zolteam/react-ras-library";
import { Picto } from "@zolteam/react-ras-library";
import { Trans } from "react-i18next";

// Style
import styles from "./DropdownButton.module.css";
import cn from "../../../utils/cn";
import { ORDER_STATUS } from "src/constants";

// Types
import DropdownButtonType from "./DropdownButtonType";

const DropdownButton: React.FC<DropdownButtonType> = ({
	children: status,
	theme = "primary",
	type,
	statusReasonDescription,
	notProvidedReason,
}) => {
	return (
		<>
			<div className={styles.desktop}>
				{notProvidedReason ? (
					<Tooltip
						placement="top"
						animation={false}
						arrow={true}
						content={
							<p>
								<Trans
									i18nKey="commands.statusReasonDescription"
									values={{ statusReasonDescription, notProvidedReason }}
									components={{ br: <br /> }}
								/>
							</p>
						}
					>
						<div className={cn([styles.container, styles[theme]])}>{status}</div>
					</Tooltip>
				) : (
					<div className={cn([styles.container, styles[theme]])}>{status}</div>
				)}
			</div>
			{notProvidedReason ? (
				<Tooltip
					placement="top"
					animation={false}
					arrow={true}
					content={
						<p>
							<Trans
								i18nKey="commands.statusReasonDescription"
								values={{ statusReasonDescription, notProvidedReason }}
								components={{ br: <br /> }}
							/>
						</p>
					}
				>
					<div className={styles.mobile}>
						{type === ORDER_STATUS.NOT_PROVIDED && <Picto className={styles.closePicto} icon="close" />}
						<span className={cn([styles.round, styles[theme], styles[type]])} />
					</div>
				</Tooltip>
			) : (
				<div className={styles.mobile}>
					{type === ORDER_STATUS.NOT_PROVIDED && <Picto className={styles.closePicto} icon="close" />}
					<span className={cn([styles.round, styles[theme], styles[type]])} />
				</div>
			)}
		</>
	);
};

export default DropdownButton;
