import { FC } from "react";
import DatePickerField from "../../molecules/DatePickerField/DatePickerField";
import FilterCardHeader from "../../molecules/FilterCardHeader/FilterCardHeader";

interface IDatePickerFilterProps {
	placeholder?: string;
	onChange: (date: Date) => void;
	value: Date;
	handleReset?: () => void;
	title: string;
}

const DatePickerFilter: FC<IDatePickerFilterProps> = ({
	placeholder = null,
	onChange,
	value = null,
	handleReset = null,
	title,
}) => (
	<div className="[&_p]:mt-0">
		<FilterCardHeader title={title} handleReset={handleReset} />
		<DatePickerField label={placeholder} onChange={onChange} value={value} />
	</div>
);

export default DatePickerFilter;
