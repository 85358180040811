import { FC } from "react";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import { fr } from "date-fns/locale";
import style from "./DatePicker.module.css";
import cn from "src/utils/cn";

registerLocale("fr", fr);

interface IDatePickerProps extends ReactDatePicker {
	className?: string;
}

const DatePicker: FC<IDatePickerProps> = ({ className, ...props }) => (
	<ReactDatePicker
		/* eslint-disable-next-line react/jsx-props-no-spreading */
		{...props}
		locale="fr"
		dateFormat="dd/MM/yyyy"
		className={cn([style.DatePicker, className])}
	/>
);

DatePicker.propTypes = {
	className: PropTypes.string,
};

DatePicker.defaultProps = {
	className: "",
};

export default DatePicker;
