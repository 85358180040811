import { Badge, PaginationV2, Picto, SelectPopover, Text, Title } from "@zolteam/react-ras-library";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { InputSearch } from "src/components/molecules/InputSearch/InputSearch";
import useSelectable from "src/hooks/useSelectable";
import { useFiltersContext } from "src/store/FiltersContext/useFiltersContext";
import { TAction, TSearchFilters } from "src/types";
import { TUsersListingFilters } from "src/types/TUsersListing";
import { cntm } from "src/utils/cntm";
import PaginationSearch from "../../../components/molecules/PaginationSearch";
import TableListing from "../../../components/organisms/TableListing";
import TableFilters from "../../../components/templates/TableFilters";
import WithSideMenu from "../../../components/templates/WithSideMenu";
import WithTopBarre from "../../../components/templates/WithTopBarre";
import { DEFAULT_USERS_LISTING_OPTIONS, PAGINATION_OFFSET_OPTIONS } from "../../../constants";
import usersListingService from "../../../services/usersListingService";
import { definePageOffset } from "../../../utils/paginationUtils";
import style from "./UsersListing.module.css";
import UsersListColumns from "./UsersListingColumns";
import UsersListingFiltersModal from "./UsersListingFiltersModal";

const countActiveFilters = (filters: TUsersListingFilters) => {
	let counter = 0;

	counter += filters.agenciesIds?.length > 0 ? 1 : 0;
	counter += filters.createdSince !== null ? 1 : 0;
	counter += filters.hasMyRas !== null ? 1 : 0;

	return counter;
};

const UsersListing = () => {
	// Hooks
	const { t } = useTranslation();
	useTitle(t("usersListing.pageTitle"));
	const { selectedItems, toggleSelected, selectAll, selectedValuesStatus } = useSelectable({});
	const history = useHistory();
	const [Options, setOptions] = useState(DEFAULT_USERS_LISTING_OPTIONS);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	// states
	const { filters, setFilter, setFilters, count } = useFiltersContext("users", countActiveFilters);

	const handleRefresh = () => {
		postUsersListingSearchQuery.refetch();
	};

	const postUsersListingSearchQuery = useQuery({
		queryKey: ["postUsersListingSearch", filters, Options],
		queryFn: () => {
			const { count, ...restFilters } = filters;
			return usersListingService.postUsersListingSearch({
				...Options,
				query: {
					...restFilters,
					createdSince: filters.createdSince ? format(filters.createdSince, "yyyy-MM-dd") : null,
					agenciesIds: filters.agenciesIds?.map((agency) => agency.id),
					hasMyRas: filters.hasMyRas === null ? null : filters.hasMyRas === "true",
				},
			});
		},
	});

	const {
		isError: isErrorUsersListingSearch,
		isLoading: isLoadingUsersListingSearch,
		isFetching: isFetchingUsersListingSearch,
		data: users,
	} = postUsersListingSearchQuery;

	const getPageMaxNumber = () => Math.round((users?.data?.total ?? 0) / Options.limit);

	const getStartPagination = Options.limit * Options.page - Options.limit + 1;

	const getEndPagination =
		Options.limit * Options.page <= users?.data?.total ? Options.limit * Options.page : users?.data?.total;

	const handleUpdateOptions = (params: TSearchFilters) => {
		setOptions((prev) => ({
			...prev,
			...params,
		}));
	};

	const handleSearch = (searchText: string) => {
		setFilter("searchText", searchText);
		setOptions((prev) => ({
			...prev,
			page: 1,
		}));
	};

	const actions: TAction[] = [];

	const handleDoubleClick = ({ id }: { id: number }) => {
		history.push(`/users/${id}/administrative`);
	};

	return (
		<WithSideMenu>
			<WithTopBarre
				quickEntryContent={<div>test</div>}
				title={
					<Title tag="h1" size="heading01" lineHeight="s">
						{t("usersListing.pageTitle")}
					</Title>
				}
				containerClassname="container"
			>
				<TableFilters
					rightContent={
						<div className={cntm([style.filterField, "mt-m mr-m items-center gap-4"])}>
							<div className={style.searchTextFilterWidth}>
								<InputSearch
									label={t("global.filters.searchText")}
									onChange={(value) => {
										handleSearch(value);
									}}
									minLength={0}
									debounced
									name="search"
									value={filters?.query?.searchText || ""}
									className="min-w-[300px] w-full sm:w-fit "
								/>
							</div>
							<button onClick={handleRefresh} className="flex items-center gap-2">
								<Picto
									className={"text-primary-500"}
									icon="refresh"
									style={{ width: "20px", height: "20px" }}
								/>
								<Text tag="span">{t("global.filters.refresh")}</Text>
							</button>
							<button className="flex items-center gap-2" onClick={() => setIsFilterModalOpen(true)}>
								<Picto
									className={"text-primary-500"}
									icon="sliders"
									style={{ width: "20px", height: "20px" }}
								/>
								<Text tag="span">{t("global.filtersPanel.openPanel")}</Text>
								<Badge color="primary" content={count} />
							</button>
						</div>
					}
				/>
				<TableListing
					loading={isLoadingUsersListingSearch || isFetchingUsersListingSearch}
					error={isErrorUsersListingSearch}
					data={users?.data?.items}
					handleSearch={handleUpdateOptions}
					initialColumnSort={{
						field: Options?.sorting,
						orderBy: Options?.orderBy,
					}}
					onDoubleClick={handleDoubleClick}
					canBeChecked={true}
					selectedItems={selectedItems}
					toggleSelected={toggleSelected}
					selectAll={selectAll}
					actions={actions}
					selectedValuesStatus={selectedValuesStatus}
					showCustomMessage={false}
					genericEmptyCellsMessage={<div className="text-neutral-300">-</div>}
					footer={
						users?.data?.total ? (
							<>
								<PaginationV2
									previousLabel={<Picto icon="chevronLeft" />}
									nextLabel={<Picto icon="chevronRight" />}
									breakLine={
										<PaginationSearch
											onInputChange={handleUpdateOptions}
											max={getPageMaxNumber()}
										/>
									}
									currentPage={Options.page}
									totalCount={users?.data.total}
									pageSize={Options.limit}
									siblingCount={2}
									onPageChange={(activePage: number) => handleUpdateOptions({ page: activePage })}
								/>
								<Text tag="span" size="paragraph02" color="grey">
									{t("global.pagination.resultsPagination", {
										resultStart: getStartPagination,
										resultEnd: getEndPagination,
										resultTotal: users?.data?.total,
									})}
								</Text>
								<SelectPopover
									name="limit"
									options={PAGINATION_OFFSET_OPTIONS}
									onChange={(value: number) =>
										handleUpdateOptions({
											limit: value,
											page: definePageOffset(value, Options.limit, Options.page),
										})
									}
									value={
										Options.limit
											? PAGINATION_OFFSET_OPTIONS.find((option) => option.value === Options.limit)
											: null
									}
								/>
							</>
						) : null
					}
				>
					{UsersListColumns()}
				</TableListing>
			</WithTopBarre>
			<UsersListingFiltersModal
				onSubmit={(newFilters) => {
					setFilters(newFilters);
					setIsFilterModalOpen(false);
					setOptions((prev) => ({
						...prev,
						page: 1,
					}));
				}}
				isDisplayed={isFilterModalOpen}
				onCloseModal={() => setIsFilterModalOpen(false)}
			/>
		</WithSideMenu>
	);
};

export default UsersListing;
