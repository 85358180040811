import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import SideMenuSecondaryItem from "src/components/atoms/SideMenuSecondaryItem/SideMenuSecondaryItem";
import { TRoute } from "src/types/TRoute";
import cn from "src/utils/cn";
import style from "./SideMenuSecondary.module.css";

interface SideMenuSecondaryProps {
	routes: TRoute[];
	header?: JSX.Element;
}

const SideMenuSecondary: FC<SideMenuSecondaryProps> = ({ routes, header }) => {
	return (
		<div className={`${style.container} flex flex-row gap-3`}>
			<div
				className={cn([style.sidemenu, style.sidemenuDesktop, "overflow-y-hidden flex flex-col "])}
				data-testid="formSideMenu"
			>
				{header && <div className="p-5">{header}</div>}
				<div className="flex  flex-col p-5 overflow-y-auto">
					{routes.map((route) => (
						<SideMenuSecondaryItem key={route.label} path={route.path}>
							{route.label}
						</SideMenuSecondaryItem>
					))}
				</div>
			</div>
			<div className="flex-1">
				<Switch>
					{routes.map((route) => (
						<Route key={route.path} exact path={route.path}>
							{route.component}
						</Route>
					))}
				</Switch>
			</div>
		</div>
	);
};

export default SideMenuSecondary;
