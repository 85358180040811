// @ts-nocheck
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from "prop-types";

import { InfoMessage, Tooltip } from "@zolteam/react-ras-library";

// Utils
import cn from "../../../utils/cn";

// Styles
import style from "./TooltipMessage.module.css";

const TooltipMessage = ({ className = "", color = "primary", children = "", arrow = true, placement = "top" }) => (
	<Tooltip placement={placement} animation={false} arrow={arrow} content={<span>{children}</span>}>
		<div className={cn([style.infoMessage, className])}>
			<InfoMessage color={color} withIcon className={style.infoMessage} />
		</div>
	</Tooltip>
);

TooltipMessage.propTypes = {
	color: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	className: PropTypes.string,
	arrow: PropTypes.bool,
};

export default TooltipMessage;
