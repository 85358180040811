import { AxiosResponse } from "axios";
import client from "../client";
import CONFIG_EXPORT_STREAM from "src/services/constants/configExportStream";
import { FiltersQuery, Format } from "src/types/CommandsType";

export interface ExtractQuery {
	format: Format;
	orderIds?: string[];
	query?: FiltersQuery;
}

export const postOrdersExtract = (data: ExtractQuery): Promise<AxiosResponse<ReadableStream>> => {
	return client.post<ReadableStream, AxiosResponse<ReadableStream>, ExtractQuery>(
		"/api-client-v2/orders/extract",
		data,
		CONFIG_EXPORT_STREAM
	);
};
