import { FormikProps } from "formik";
import { t } from "i18next";
import { FC } from "react";
import { useQuery } from "react-query";
import CheckboxListFilter from "src/components/organisms/CheckboxListFilter/CheckboxListFilter";
import { useFiltersContext } from "src/store/FiltersContext/useFiltersContext";
import { TAgency } from "src/types";
import { TNotificationsFilters, TNotificationsType, TNotificationsTypes } from "src/types/TNotifications";
import DatePickerFilter from "../../components/organisms/DatePickerFilter/DatePickerFilter";
import FilterModal from "../../components/organisms/FilterModal/FilterModal";
import AgenciesFilter from "../../components/templates/AgenciesFilter";
import { DEFAULT_NOTIFICATIONS_LIST_FILTERS } from "../../constants";
import user from "../../services/user";
import style from "./Notifications.module.css";

type TNotificationsFiltersDispatch = FormikProps<TNotificationsFilters>;
interface INotificationFilterModalProps {
	onSubmit: (filters: Record<string, any>) => void;
	isDisplayed: boolean;
	onCloseModal: () => void;
}

const NotificationFilterModal: FC<INotificationFilterModalProps> = ({ onSubmit, isDisplayed, onCloseModal }) => {
	// Destructuring useQuery and useMutation
	const { setFilter, filters } = useFiltersContext<TNotificationsFilters>("notifications");

	const { data: userAgenciesData, isLoading } = useQuery(["getUserAgencies", "filtermodal"], () =>
		user.getUserAgencies().then((res) => {
			if (!filters?.agencies?.length) {
				setFilter("agencies", res.data);
				onSubmit({ ...filters, agencies: res.data });
			}
			setFilter("defaultAgencies", res.data);
			return res.data;
		})
	);

	const onClose = () => {
		onCloseModal();
	};

	const handleResetType = (key: keyof TNotificationsTypes, { setFieldValue }: TNotificationsFiltersDispatch) => {
		return setFieldValue(`type.${key}`, DEFAULT_NOTIFICATIONS_LIST_FILTERS.type[key]);
	};

	const handleChangeTimesheet = (
		type: TNotificationsType,
		{ values, setFieldValue }: TNotificationsFiltersDispatch
	) => {
		if (type === "all") {
			const updatedTimesheets = Object.keys(values.type.timesheets).reduce<{ [key: string]: boolean }>(
				(acc, key) => {
					acc[key] = !values.type.timesheets["all"];
					return acc;
				},
				{}
			);
			return setFieldValue("type.timesheets", updatedTimesheets);
		} else {
			const otherTimesheetsAreTrue = Object.keys(values.type.timesheets)
				.filter((key) => key !== "all" && key !== type)
				.every((key) => values.type.timesheets[key] === true);
			const allTimesheetsAreTrue = otherTimesheetsAreTrue && !values.type.timesheets[type];
			return setFieldValue("type.timesheets", {
				...values.type.timesheets,
				[type]: !values.type.timesheets[type],
				all: allTimesheetsAreTrue,
			});
		}
	};

	const handleChangeRatings = (
		type: TNotificationsType,
		{ values, setFieldValue }: TNotificationsFiltersDispatch
	) => {
		const updatedRatings = Object.keys(values.type.ratings).reduce<{ [key: string]: boolean }>((acc, key) => {
			acc[key] = false;
			return acc;
		}, {});

		return setFieldValue("type.ratings", {
			...updatedRatings,
			[type]: true,
		});
	};

	return (
		<FilterModal
			onSubmit={onSubmit}
			isDisplayed={isDisplayed}
			onCloseModal={onClose}
			defaultFilters={{
				...DEFAULT_NOTIFICATIONS_LIST_FILTERS,
				agencies: userAgenciesData,
				defaultAgencies: userAgenciesData,
			}}
			filterKey="notifications"
			isLoading={isLoading}
			size="m"
		>
			{(formikCtx) => {
				const { setFieldValue, values, resetField } = formikCtx;
				return (
					<>
						<div>
							{userAgenciesData !== undefined && userAgenciesData?.length > 0 && (
								<div>
									<AgenciesFilter
										titleOnTop
										activeSelectAllOption
										options={userAgenciesData || []}
										selectedOptions={values?.agencies?.map((agency: TAgency) => agency.id)}
										handleFilter={(selectedAgencies) => {
											setFieldValue("agencies", selectedAgencies);
										}}
										onReset={() => resetField("agencies")}
										canExpendOrFold={false}
										agenciesInputWidth="100%"
										inputSize="m"
										maxWidth="320"
										canReset={values?.agencies?.length !== userAgenciesData?.length}
									/>
								</div>
							)}
							<DatePickerFilter
								handleReset={values.createdAt ? () => resetField("createdAt") : null}
								placeholder={values?.createdAt ? null : "DD/MM/YYYY"}
								onChange={(date) => setFieldValue("createdAt", date)}
								title={t("clientsMyTemp.notifications.filterTitles.startDate")}
								value={values?.createdAt ? new Date(values.createdAt) : null}
							/>
						</div>
						{
							<div>
								<CheckboxListFilter
									title={t("clientsMyTemp.notifications.filterTitles.time_sheets")}
									handleReset={() => handleResetType("timesheets", formikCtx)}
									items={values.type.timesheets}
									onChange={(type) => handleChangeTimesheet(type as TNotificationsType, formikCtx)}
									getId={(index) => `timesheet-checkbox-${index}`}
									getValue={(type) => {
										return values?.type?.timesheets[type];
									}}
									getLabel={(type) => t(`clientsMyTemp.notifications.filters.timesheets.${type}`)}
									indentedFirstLevel
								/>
								<CheckboxListFilter
									title={t("clientsMyTemp.notifications.filterTitles.ratings")}
									handleReset={() => handleResetType("ratings", formikCtx)}
									items={values.type.ratings}
									onChange={(type) => handleChangeRatings(type as TNotificationsType, formikCtx)}
									getId={(index) => `rating-radio-${index}`}
									getValue={(type) => values?.type?.ratings[type]}
									getLabel={(type) => t(`clientsMyTemp.notifications.filters.ratings.${type}`)}
									labelClassName={style.ratingsLabel}
									listStyle={{
										display: "flex",
										justifyContent: "space-between",
									}}
									type="radio"
									orientation="horizontal"
									name="rating"
								/>
							</div>
						}
					</>
				);
			}}
		</FilterModal>
	);
};

export default NotificationFilterModal;
