import * as Yup from "yup";
import i18n from "src/i18n";
import isValidRangeNightPeriod from "src/views/Command/Configuration/forms/Rules/Validators/isValidRangeNightPeriod";

const PeriodRule = () => {
	return Yup.object().shape({
		startDate: Yup.date().nullable().required(i18n.t("commands.error.mandatoryStartDateField")),
		endDate: Yup.date()
			.nullable()
			.required(i18n.t("commands.error.mandatoryEndDateField"))
			.test("isValidRangeNightPeriod", i18n.t("commands.error.invalidRangeNightDay"), isValidRangeNightPeriod),
	});
};

export default PeriodRule;
