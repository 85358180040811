import { WORK_PERIOD_TYPES } from "src/constants";
import * as Yup from "yup";
import { OrderInterface } from "src/types/OrderInterface";

const resetTime = (date: Date) => {
	return date.setHours(0, 0, 0, 0);
};

const isValidRangeNightPeriod = (endDate: Date, context: Yup.TestContext<OrderInterface>): boolean => {
	const { startDate } = context.parent;
	const { workPeriodType } = context.options.context;

	return !(workPeriodType === WORK_PERIOD_TYPES.NIGHT && resetTime(new Date(startDate)) === resetTime(endDate));
};

export default isValidRangeNightPeriod;
