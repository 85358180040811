import { ChangeEvent } from "react";
import { Button, Title, Text, SelectAsync, Textarea } from "@zolteam/react-ras-library";
import { useTranslation, Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import { Form, Formik, useFormikContext } from "formik";
import orderNotProvidedFormValidation from "./orderNotProvidedFormValidation";

// Style
import style from "src/views/Command/Profiles/Modals/Modals.module.css";
import cn from "src/utils/cn";

// Services
import { getOrderNotProvidedReasons } from "src/services/commandService";
// Typs
import {
	FormValues,
	ModalContentOrderNotProvidedProps,
	ModalContentOrderNotProvidedWrapperProps,
	Option,
} from "./ModalContentOrderNotProvidedTypes";
import ORDER_NOT_PROVIDED_REASONS from "src/constants/orderNotProvidedReasons";

const ModalContentOrderNotProvided: React.FC<ModalContentOrderNotProvidedProps> = ({ onAddClick, onCancelClick }) => {
	// Hooks
	const { t } = useTranslation();
	const { orderId } = useParams<{ orderId: string }>();
	const { values, setFieldValue, isValid, isSubmitting } = useFormikContext<FormValues>();
	const { statusReasonDescription, orderNotProvidedReason } = values;
	const isFormValid = isValid && orderNotProvidedReason !== null && !isSubmitting;

	// API Call
	const orderNotProvidedReasonsAsync = () => getOrderNotProvidedReasons(orderId).then((res) => res.data);

	return (
		<div className={cn([style.content, style.limitWidth])}>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("commands.profiles.modalsContent.orderNotProvided.title")}
			</Title>

			<div>
				<div className={style.infoMessage}>
					<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l">
						{t("commands.profiles.modalsContent.orderNotProvided.infoMessage")}
					</Text>
				</div>
				<div className={style.selectInput}>
					<>
						<SelectAsync
							isSearchable={false}
							className={style.inputs}
							cacheOptions
							defaultOptions
							promiseOptions={orderNotProvidedReasonsAsync}
							name="orderNotProvidedReason"
							label={t("commands.orderNotProvidedReason")}
							getOptionLabel={(option: Option) => option.label}
							getOptionValue={(option: Option) => option.id}
							value={orderNotProvidedReason}
							onChange={(value: Option) => setFieldValue("orderNotProvidedReason", value)}
							noOptionsMessage={() => t("commands.selectNoAppealCaseFound")}
							loadingMessage={() => t("commands.selectLoadingorderNotProvidedReason")}
						/>
						{orderNotProvidedReason?.label === ORDER_NOT_PROVIDED_REASONS.OTHER ? (
							<Textarea
								className={style.textarea}
								maxLength={100}
								value={statusReasonDescription}
								name="statusReasonDescription"
								placeholder={t(
									"commands.profiles.modalsContent.orderNotProvided.statusReasonDescriptionPlaceholder"
								)}
								shouldDisplayErrorMessage={false}
								onChange={(value: ChangeEvent<HTMLTextAreaElement>) =>
									setFieldValue("statusReasonDescription", value.target.value)
								}
							/>
						) : null}
					</>
				</div>

				<div className={style.warningMessage}>
					<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l">
						<Trans i18nKey="commands.profiles.modalsContent.orderNotProvided.warningMessage" />
					</Text>
				</div>
				<div className={style.confirmationMessage}>
					<Text tag="p" size="paragraph01" fontWeight="light" lineHeight="l">
						{t("commands.profiles.modalsContent.orderNotProvided.confirmationMessage")}
					</Text>
				</div>
			</div>

			<hr className={style.separator} />
			<div className={style.buttonsBlock}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("commands.profiles.modalsContent.orderNotProvided.cancelAction")}
				</Button>
				<Button
					disabled={!isFormValid}
					className={style.validationButtonRight}
					type="button"
					color={!isFormValid ? "grey" : "primary"}
					onClick={() => {
						return onAddClick(orderNotProvidedReason, statusReasonDescription);
					}}
				>
					{t("commands.profiles.modalsContent.orderNotProvided.confirmAction")}
				</Button>
			</div>
		</div>
	);
};

const ModalContentOrderNotProvidedWrapper: React.FC<ModalContentOrderNotProvidedWrapperProps> = ({
	onAddClick,
	onCancelClick,
}) => (
	<div>
		<Formik
			initialValues={{
				orderNotProvidedReason: null,
				statusReasonDescription: "",
			}}
			validationSchema={orderNotProvidedFormValidation}
			validateOnMount
			onSubmit={() => {}}
		>
			{() => (
				<Form>
					<ModalContentOrderNotProvided onAddClick={onAddClick} onCancelClick={onCancelClick} />
				</Form>
			)}
		</Formik>
	</div>
);

export default ModalContentOrderNotProvidedWrapper;
