import { FormikErrors, FormikTouched } from "formik";
import { OrderInterface } from "src/types/OrderInterface";
import { get, isEmpty, isNil } from "lodash";

export const isWorkingTimeOnError = (
	touched: FormikTouched<OrderInterface>,
	errors: FormikErrors<OrderInterface>
): boolean[][] => {
	if (isNil(errors.workingTime) || get(touched?.workingTime, "slots", false) === false) {
		return [[false, false]];
	}

	const finalErrors = [];
	const { workingTime } = errors;

	if (isEmpty(workingTime.slots)) {
		return [[false, false]];
	}

	for (let i: number = 0; i < workingTime.slots.length; i++) {
		const startHourError = get(workingTime.slots[i], "startHour", null);
		const endHourError = get(workingTime.slots[i], "endHour", null);

		if (!isNil(startHourError) || !isNil(endHourError)) {
			finalErrors.push([startHourError !== null, endHourError !== null]);
		} else {
			finalErrors.push([false, false]);
		}
	}

	return finalErrors;
};
