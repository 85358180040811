import * as Yup from "yup";
import SlotsRule from "src/views/Command/Configuration/forms/Rules/SlotsRule";
import WorkingDaysRule from "src/views/Command/Configuration/forms/Rules/WorkingDaysRule";
import WorkingNightsRule from "src/views/Command/Configuration/forms/Rules/WorkingNightsRule";
import ParticularWorkTimeRule from "src/views/Command/Configuration/forms/Rules/ParticularWorkTimeRule";
import FirstDayStartHourRule from "src/views/Command/Configuration/forms/Rules/FirstDayStartHourRule";
import PeriodNotWorkingRule from "src/views/Command/Configuration/forms/Rules/PeriodNotWorkingRule";

const WorkingTimeRule = () => {
	return Yup.object().shape({
		slots: SlotsRule(),
		workingDays: WorkingDaysRule(),
		workingNights: WorkingNightsRule(),
		particularWorkTime: ParticularWorkTimeRule(),
		periodNotWorking: PeriodNotWorkingRule(),
		firstDayStartHour: FirstDayStartHourRule(),
	});
};

export default WorkingTimeRule;
