import { TUsersListingFilters } from "src/types/TUsersListing";

export const DEFAULT_USERS_LISTING_SEARCH_PARAMS = {
	limit: 40,
	page: 1,
	sorting: "createdAt",
	orderBy: "DESC",
};

export const DEFAULT_USERS_LISTING_FILTERS: TUsersListingFilters = {
	count: 0,
	searchText: "",
	agenciesIds: [],
	createdSince: null,
	hasMyRas: null,
};
