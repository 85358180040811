import { FC } from "react";
import logo from "../../../assets/picto/myscan.png";
import { cntm } from "../../../utils/cntm";

interface MyScanPictoProps {
	className?: string;
}

const MyScanPicto: FC<MyScanPictoProps> = ({ className }) => {
	return (
		<img src={logo} alt="myscan" width={"190px"} height={"66px"} className={cntm(["w-20 h-auto ", className])} />
	);
};

export default MyScanPicto;
