// @ts-nocheck
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getUnixTime } from "date-fns";
import {
	Button,
	PaginationV2,
	Picto,
	Popover,
	SelectPopover,
	Text,
	Title,
	Tooltip,
	ModalV2,
	ToggleSwitch,
	InputControlled,
} from "@zolteam/react-ras-library";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import useAppContext from "src/store/useAppContext";
// Hooks
import { useTitle } from "react-use";
import useInterval from "../../hooks/useInterval";
import useSelectable from "../../hooks/useSelectable";
import { useCommands } from "src/views/Commands/useCommands/useCommands";
// TODO: Remove when feature is ready
import useEnabledFeatures from "../../hooks/useEnabledFeatures";

// Utils
import { dateDifference, formatDate } from "../../utils/formatDate";
import { definePageOffset } from "../../utils/paginationUtils";
import commandsFormDefaultValues from "./commandsFormDefaultValues";
import cn from "../../utils/cn";

// Components
import ModalContentCommandsFilter from "./Modals/Modal/ModalContentCommandsFilter/ModalContentCommandsFilter";

// Templates
import WithSideMenu from "../../components/templates/WithSideMenu/WithSideMenu";
import WithTopBarre from "../../components/templates/WithTopBarre";
import TableFilters from "../../components/templates/TableFilters";

// Organisms
import TableListing from "../../components/organisms/TableListing";

// Molecules
import ColumnListing from "../../components/molecules/ColumnListing/ColumnListing";
import TableNumberRow from "../../components/molecules/TableNumberRow";
import DateTimeRangeDisplay from "../../components/molecules/DateTimeRangeDisplay";
import TableInfo from "../../components/molecules/TableInfo";
import PaginationSearch from "../../components/molecules/PaginationSearch/PaginationSearch";

// Atoms
import DropdownButton from "../../components/atoms/DropdownButton/DropdownButton";
import CountBox from "../../components/atoms/CountBox/CountBox";
import PopoverMenuItem from "../../components/atoms/PopoverMenuItem";

// Services
import { postOrdersSearch, getOrderUserFilters } from "../../services/commandService";

// Style
import style from "./Commands.module.css";

// Constants
import { PAGINATION_OFFSET_OPTIONS, ORDER_STATUS_IN_PROGRESS, ORDER_STATUS } from "../../constants";
import ModalContentCommandsExtract from "./Modals/Modal/ModalContentCommandsExtract";
import ROLES from "src/constants/roles";
const DEFAULT_SEARCH_PARAMS = {
	limit: 40,
	page: 1,
	sorting: "status",
	orderBy: "ASC",
};

const Commands = () => {
	// States
	const [data, setData] = useState();
	const [search, setSearch] = useState(DEFAULT_SEARCH_PARAMS);
	const [isFirstLoading, setIsFirstLoading] = useState(true);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [filters, setFilters] = useState(commandsFormDefaultValues);
	const [isExtractModalOpen, setIsExtractModalOpen] = useState(false);
	const [
		{
			user: { roles },
		},
	] = useAppContext();
	// Hooks
	const { t } = useTranslation();
	const history = useHistory();
	const {
		selectedItems,
		toggleSelected,
		selectAll,
		selectedValuesStatus,
		totalItemsSelected,
		updateSelectionAfterFilter,
	} = useSelectable([], "id");

	const {
		enabledFeatures: { hasMissionListAccess },
	} = useEnabledFeatures();
	const { handleExtract, isExtractProcessing, handleSearchChange, handleSearchClear, searchOrder } =
		useCommands(setFilters);

	const hasAtLeastOneSelected: boolean = useMemo(() => totalItemsSelected > 0, [totalItemsSelected]);

	const setUpdatedData = (res) => {
		const updatedData = res?.items?.reduce((acc, item) => {
			let updateDiff = null;
			if (item.updatedAt === item.createdAt) {
				updateDiff = null;
			} else {
				updateDiff = dateDifference(
					getUnixTime(new Date(item.updatedAt) * 1000),
					getUnixTime(Date.now()) * 1000
				);
			}
			acc.push({ ...item, updateDiff });
			return acc;
		}, []);
		setData({ ...res, items: updatedData });
	};

	useTitle(t("commands.pageTitle"));

	// API Calls
	const { data: userFiltersData } = useQuery("getOrderUserFilters", getOrderUserFilters);

	useEffect(() => {
		if (userFiltersData) {
			setFilters((f) => ({
				...f,
				showGenericAgency: userFiltersData?.data?.showGenericAgency,
			}));
		}
	}, [userFiltersData]);

	const postOrdersSearchMutation = useMutation(postOrdersSearch, {
		onSuccess: (result) => {
			setUpdatedData(result?.data);
			setIsFirstLoading(false);
		},
	});

	const getTableRowHref = ({ id }) => `/commandes/${id}/configuration`;

	// Destructuring useMutation
	const { isError: isErrorPostOrdersSearchMutation, isLoading: isLoadingPostOrdersSearchMutation } =
		postOrdersSearchMutation;

	useInterval(() => {
		setUpdatedData(data);
	}, 60000);

	const handleSearch = (sort) => {
		setSearch((prev) => {
			const searchRequest = { ...prev, ...sort };
			return searchRequest;
		});
		postOrdersSearchMutation.mutate({ ...search, query: filters, ...sort });
	};

	const handleEditCommand = (e, commandId) => {
		e.preventDefault();
		history.push(`/commandes/${commandId}/configuration`);
	};

	const handleDoubleClick = ({ id: commandId }) => {
		history.push(`/commandes/${commandId}/configuration`);
	};

	const isListLoading = () =>
		(isFirstLoading && !isErrorPostOrdersSearchMutation) || isLoadingPostOrdersSearchMutation;

	useEffect(() => {
		if (filters.showGenericAgency !== null) {
			postOrdersSearchMutation.mutate(
				{ ...search, query: filters },
				{
					onSuccess: (result) => {
						updateSelectionAfterFilter(result?.data?.items);
					},
				}
			);
		}
	}, [filters]);

	const activeFiltersCount = useMemo(() => {
		const conditions = [
			filters?.statuses?.length !== 0 || 0,
			filters?.agenciesIds?.length !== 0 || 0,
			filters?.maxCreationDate || filters?.minCreationDate,
		];
		const total = conditions.reduce((acc, condition) => acc + (condition ? 1 : 0), 0);
		return total;
	}, [filters]);

	const handleUpdateFilters = (values) => {
		setIsFilterModalOpen(false);
		setSearch(DEFAULT_SEARCH_PARAMS);
		setFilters((prev) => ({ ...prev, ...values }));
	};

	const handleExtractClick = ({ extractPerimeter, format }) => {
		setIsExtractModalOpen(false);
		handleExtract({ extractPerimeter, format, selectedItems, filters });
	};

	return (
		<WithSideMenu>
			{!hasMissionListAccess ? (
				<div style={{ padding: "2rem" }}>Accès non autorisé</div>
			) : (
				<WithTopBarre
					quickEntryContent={<div>test</div>}
					title={
						<Title tag="h1" size="heading01" lineHeight="s">
							{t("commands.title")}
						</Title>
					}
					containerClassname="container"
				>
					<TableFilters
						rightContent={
							<div className={style.filters}>
								<div className={style.searchInput}>
									<InputControlled
										picto={{ name: "search" }}
										label={t("commands.orderSearchLabel")}
										onChange={handleSearchChange}
										value={searchOrder}
										pictoColor="var(--color-neutral-500)"
										isClearable={true}
										handleClear={handleSearchClear}
									/>
								</div>
								{filters?.showGenericAgency !== null ? (
									<div className={style.genericToggle}>
										<ToggleSwitch
											name="showGenericAgency"
											id="showGenericAgency"
											onChange={() => {
												setFilters((prev) => ({
													...prev,
													showGenericAgency: !prev.showGenericAgency,
												}));
											}}
											toggled={filters.showGenericAgency ?? false}
										/>
										<label htmlFor="showGenericAgency" className={style.toggleLabel}>
											{t("commands.filtersModal.showGenericAgency")}
										</label>
									</div>
								) : null}
								<span
									onClick={() => setIsFilterModalOpen(true)}
									onKeyDown={() => setIsFilterModalOpen(true)}
									tabIndex="0"
									role="button"
									className={style.filterAction}
									aria-label="filter"
								>
									<Picto icon="sliders" className={style.picto} />
									<div className={style.wrapper} type="button">
										<span className={style.text}>Filtrer</span>
										<div className={style.badge}>{activeFiltersCount}</div>
									</div>
								</span>
							</div>
						}
						leftContent={
							<div className="flex items-center">
								<div className={style.leftContent}>
									<Button
										type="button"
										color="primary"
										onClick={() => history.push("/commandes/create/configuration")}
										data-testid="createCommand"
									>
										{t("commands.add")}
									</Button>
									{(!!roles[ROLES.DIGITAL_DIVISION] || !!roles[ROLES.ADMIN]) && (
										<div
											className={style.exportButton}
											role="button"
											tabIndex="1"
											aria-label="extract button"
											onClick={() => setIsExtractModalOpen(true)}
										>
											<Picto className={style.extract} icon="upload" />
											{t("commands.export")}
										</div>
									)}
								</div>
								{isExtractProcessing ? (
									<img
										className="h-4 w-4 text-primary-400 animate-spin"
										src="/circle-loader.png"
										alt="circle-loader"
									/>
								) : null}
							</div>
						}
					/>

					<TableListing
						loading={isListLoading()}
						error={isErrorPostOrdersSearchMutation}
						data={data?.items}
						handleSearch={handleSearch}
						onDoubleClick={handleDoubleClick}
						getTableRowHref={getTableRowHref}
						initialColumnSort={{
							field: DEFAULT_SEARCH_PARAMS.sorting,
							orderBy: DEFAULT_SEARCH_PARAMS.orderBy,
						}}
						selectedItems={selectedItems}
						toggleSelected={toggleSelected}
						selectAll={selectAll}
						selectedValuesStatus={selectedValuesStatus}
						loadingMessage={t("commands.loadingMessage")}
						hasCustomFooter={hasAtLeastOneSelected}
						footer={
							<div className={style.footer}>
								<div className={style.lightGray}>
									{hasAtLeastOneSelected && (
										<span>
											{t("commands.totalItemsSelected", {
												count: totalItemsSelected,
												totalItemsSelected: totalItemsSelected,
											})}
										</span>
									)}
								</div>
								{data?.total ? (
									<div className={style.pagination}>
										<PaginationV2
											previousLabel={<Picto icon="chevronLeft" />}
											nextLabel={<Picto icon="chevronRight" />}
											breakLine={
												<PaginationSearch
													onInputChange={handleSearch}
													max={Math.round(data.total / search.limit)}
												/>
											}
											currentPage={search.page}
											totalCount={data.total}
											pageSize={search.limit}
											siblingCount={2}
											theme={hasAtLeastOneSelected ? "dark" : "primary"}
											onPageChange={(activePage) => handleSearch({ page: activePage })}
										/>
										<span
											className={
												hasAtLeastOneSelected
													? cn([style.resultsPagination, style.lightGray])
													: cn([style.resultsPagination])
											}
										>
											{t("global.pagination.resultsPagination", {
												resultStart: search.limit * search.page - search.limit + 1,
												resultEnd:
													search.limit * search.page <= data?.total
														? search.limit * search.page
														: data?.total,
												resultTotal: data?.total,
											})}
										</span>
										<SelectPopover
											name="limit"
											options={PAGINATION_OFFSET_OPTIONS}
											theme={hasAtLeastOneSelected ? "white" : "primary"}
											onChange={(value) =>
												handleSearch({
													limit: value,
													page: definePageOffset(value, search.limit, search.page),
												})
											}
											value={
												search.limit
													? PAGINATION_OFFSET_OPTIONS.find(
															(option) => option.value === search.limit
														)
													: null
											}
										/>
									</div>
								) : null}
							</div>
						}
					>
						<ColumnListing
							id="status"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("commands.status")}
								</Text>
							}
							field={(elem) => ({
								status: elem.status,
								statusReasonDescription: elem?.statusReasonDescription,
								notProvidedReason: elem?.notProvidedReason,
							})}
							component={({ data: { status, statusReasonDescription, notProvidedReason } }) => (
								<DropdownButton
									theme={status === ORDER_STATUS_IN_PROGRESS ? "primary" : "grey"}
									type={status}
									statusReasonDescription={statusReasonDescription}
									notProvidedReason={notProvidedReason}
								>
									{t(`commands.statusType.${status}`)}
								</DropdownButton>
							)}
							isSortable
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="tempoId"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("commands.number")}
								</Text>
							}
							field={(elem) => ({ id: elem?.tempoId || "", type: elem.type })}
							component={({ data: { id, type } }) => (
								<TableNumberRow number={`${id}`} type={t(`commands.type.${type}`)} />
							)}
							isSortable
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="clientName"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("commands.client")}
								</Text>
							}
							field={(elem) => ({ clientName: elem.clientName })}
							component={({ data: { clientName } }) => (
								<Text tag="span" size="paragraph02">
									{clientName}
								</Text>
							)}
							isSortable
						/>
						<ColumnListing
							id="qualification"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("commands.qualification")}
								</Text>
							}
							field={(elem) => ({
								deletedAt: elem?.qualification?.deletedAt,
								qualificationName: elem.qualificationName,
							})}
							component={({ data: { qualificationName, deletedAt } }) => (
								<>
									<Text tag="span" size="paragraph02">
										{qualificationName || ""}
									</Text>
									{deletedAt && (
										<Tooltip
											animation={false}
											arrow={false}
											content={t("commands.deletedQualification")}
										>
											<div style={{ marginLeft: "10px" }}>
												<Picto
													icon="warning"
													style={{
														height: "13.72px",
														flex: "1",
														color: "var(--color-warning-500)",
													}}
												/>
											</div>
										</Tooltip>
									)}
								</>
							)}
							isSortable
						/>
						<ColumnListing
							id="startDate"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("commands.start")}
								</Text>
							}
							field={(elem) => ({
								start: elem.start,
								firstDayWorkHours: elem.firstDayWorkHours,
								status: elem.status,
							})}
							component={({ data: { start, firstDayWorkHours, status } }) => (
								<DateTimeRangeDisplay
									hours={firstDayWorkHours}
									date={formatDate(start, "dd MMM yyyy")}
									onClick={() => {}}
									shouldDisplayWarning={
										(moment(start).isBefore(moment()) || moment(start).isSame(moment())) &&
										status === ORDER_STATUS.IN_PROGRESS
									}
								/>
							)}
							isSortable
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="endDate"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("commands.end")}
								</Text>
							}
							field={(elem) => ({ end: elem.end })}
							component={({ data: { end } }) => (
								<DateTimeRangeDisplay date={formatDate(end, "dd MMM yyyy")} onClick={() => {}} />
							)}
							tdClassName="min-column-width"
						/>
						{/* TODO NICO rename 3 next column and delete mock */}
						<ColumnListing
							id="thumbUp"
							name={<Picto icon="thumb" style={{ height: "13.72px", flex: "1" }} />}
							field={(elem) => ({
								thumbUp: elem.thumbUp,
								status: elem.status,
								profileStatusSummary: elem.profileStatusSummary,
							})}
							component={({ data: { thumbUp, status, profileStatusSummary } }) => (
								<CountBox
									alert={profileStatusSummary[0]?.hasNewProfilesSinceLastOrderListView}
									number={thumbUp || profileStatusSummary[0]?.total}
									theme={status === ORDER_STATUS_IN_PROGRESS ? "primary" : "grey"}
								/>
							)}
							hideOnDesktop
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="thumbDown"
							name={
								<Picto
									icon="thumb"
									style={{
										height: "13.72px",
										flex: "1",
										transform: "rotate(180deg)",
									}}
								/>
							}
							field={(elem) => ({
								thumbDown: elem.thumbDown,
								status: elem.status,
								profileStatusSummary: elem.profileStatusSummary,
							})}
							component={({ data: { thumbDown, status, profileStatusSummary } }) => (
								<CountBox
									alert={profileStatusSummary[1]?.hasNewProfilesSinceLastOrderListView}
									number={thumbDown || profileStatusSummary[1]?.total}
									theme={status === ORDER_STATUS_IN_PROGRESS ? "darkGrey" : "grey"}
								/>
							)}
							hideOnDesktop
							tdClassName="min-column-width"
							thClassName="pl-0"
							cellClassName="pl-0"
						/>
						<ColumnListing
							id="validate"
							name={<Picto icon="checkCircle" style={{ height: "14.67px", flex: "1" }} />}
							field={(elem) => ({
								validate: elem?.validate,
								profileStatusSummary: elem?.profileStatusSummary,
								workerRequestedCount: elem?.workerRequestedCount,
							})}
							component={({ data: { validate, profileStatusSummary, workerRequestedCount } }) => (
								<CountBox
									alert={profileStatusSummary[2]?.hasNewProfilesSinceLastOrderListView}
									number={validate || `${profileStatusSummary[2]?.total}/${workerRequestedCount}`}
									theme="dark"
								/>
							)}
							tdClassName="min-column-width cell-separator"
						/>
						<ColumnListing
							id="agencyName"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("commands.agency")}
								</Text>
							}
							field={(elem) => ({ agencyName: elem.agencyName })}
							component={({ data: { agencyName } }) => (
								<Text tag="span" size="paragraph02">
									{agencyName || ""}
								</Text>
							)}
							isSortable
						/>
						<ColumnListing
							id="createdAt"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("commands.createdAt")}
								</Text>
							}
							field={(elem) => ({
								createdAt: elem.createdAt,
								updateDiff: elem.updateDiff,
							})}
							component={({ data: { createdAt, updateDiff } }) => (
								<TableInfo
									info={formatDate(createdAt, "dd MMM yyyy")}
									subInfo={updateDiff ? t("global.updatedAt", { updated: updateDiff }) : null}
								/>
							)}
							isSortable
							tdClassName="min-column-width"
						/>
						<ColumnListing
							id="author"
							name={
								<Text tag="span" size="paragraph02" color="grey">
									{t("commands.author")}
								</Text>
							}
							field={(elem) => ({ author: elem.author })}
							component={({ data: { author } }) => {
								const fullName = `${author?.lastName || ""} ${author?.firstName || ""}`;
								return (
									<>
										{fullName?.length > 12 ? (
											<Tooltip animation={false} arrow={false} content={fullName}>
												<div
													className={style.authorColumn}
												>{`${fullName?.substring(0, 12)}...`}</div>
											</Tooltip>
										) : (
											<Text tag="span" size="paragraph02">
												{fullName}
											</Text>
										)}
									</>
								);
							}}
						/>
						<ColumnListing
							id="isCreatedBy247"
							name=""
							field={(elem) => ({ author: elem.author })}
							component={({ data: { author } }) =>
								author?.isCreatedBy247 ? (
									<DropdownButton theme="primary" type="">
										{t("commands.createdBy247")}
									</DropdownButton>
								) : null
							}
						/>
						<ColumnListing
							id="action"
							field={(elem) => ({ id: elem.id })}
							component={({ data: { id } }) => (
								<Popover
									minWidth
									clickInside
									placement="left-start"
									animation="perspective"
									offset={[-20, 10]}
									content={
										<PopoverMenuItem click={(e) => handleEditCommand(e, id)}>
											<Text tag="div" size="paragraph01" data-testid={`edit-command-${id}`}>
												{t("commands.edit")}
											</Text>
										</PopoverMenuItem>
									}
								>
									<button type="button" onClick={(e) => e.preventDefault()} aria-label="more">
										<Picto
											icon="more"
											style={{
												width: "18px",
												transform: "rotate(90deg)",
												color: "var(--color-neutral-500)",
											}}
										/>
									</button>
								</Popover>
							)}
						/>
					</TableListing>
					<ModalV2 size="s" isDisplayed={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
						<ModalContentCommandsFilter initialValues={filters} onConfirmClick={handleUpdateFilters} />
					</ModalV2>
					<ModalV2 size="s" isDisplayed={isExtractModalOpen} onClose={() => setIsExtractModalOpen(false)}>
						<ModalContentCommandsExtract
							onConfirmClick={handleExtractClick}
							onCancelClick={() => setIsExtractModalOpen(false)}
							hasAtLeastOneSelected={hasAtLeastOneSelected}
						/>
					</ModalV2>
				</WithTopBarre>
			)}
		</WithSideMenu>
	);
};

export default Commands;
