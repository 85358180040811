import { AxiosResponse } from "axios";
import client from "../client";

export type AgencyInterviewData = {
	id: number;
	updatedAt: string;
	agencyId: string;
	agencyName: string;
};

const getUnifiedTempWorkerInterviewAgencies = (
	unifiedTempWorkerId: string
): Promise<AxiosResponse<AgencyInterviewData[]>> => {
	return client.get<AgencyInterviewData[]>(`/api-client-v2/unified-temp-workers/${unifiedTempWorkerId}/users`);
};

export default getUnifiedTempWorkerInterviewAgencies;
