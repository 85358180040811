// @ts-nocheck
import * as Yup from "yup";
import {
	COMMAND_BILLING_PACKAGES,
	COMMAND_TYPE_FORMATION,
	COMMAND_TYPE_INTERIM,
	COMMAND_TYPE_INVESTMENT,
	COMMAND_TYPE_NEW_ORDER,
	COMMAND_TYPE_REPLACEMENT,
	WORK_PERIOD_TYPES,
} from "src/constants";
import i18n from "../../../../i18n";
import WorkingTimeRule from "src/views/Command/Configuration/forms/Rules/WorkingTimeRule";
import PeriodRule from "src/views/Command/Configuration/forms/Rules/PeriodRule";

export default Yup.object().shape({
	// COMMAND TYPE FORM
	type: Yup.string().oneOf([COMMAND_TYPE_INTERIM, COMMAND_TYPE_INVESTMENT]),
	nature: Yup.string().oneOf([COMMAND_TYPE_NEW_ORDER, COMMAND_TYPE_FORMATION, COMMAND_TYPE_REPLACEMENT]),
	// COMMAND AGENCY FORM
	agencyId: Yup.string().required(i18n.t("commands.error.agencyRequired")),
	commercialCode: Yup.string().when("agencyOption", {
		is: (agencyOption) => agencyOption?.isGeneric,
		then: (schema) => schema.required("commands.error.commercialCodeRequired"),
		otherwise: (schema) => schema.nullable(),
	}),
	// COMMAND CLIENT FORM
	clientId: Yup.string().required(i18n.t("commands.error.clientRequired")),
	// COMMAND INTERLOCUTOR FORM
	contact: Yup.object().shape({
		title: Yup.string().nullable(),
		name: Yup.string()
			.nullable()
			.when("isCustom", {
				is: true,
				then: (schema) =>
					schema
						.required(i18n.t("commands.error.nameRequired"))
						.matches(/[a-zA-Z0-9].*/, i18n.t("commands.error.nameRequired")),
				otherwise: (schema) => schema.optional(),
			}),
		function: Yup.string().nullable(),
		phone: Yup.string()
			.nullable()
			.transform((compactVal, val) => {
				if (!val) {
					return null;
				}
				return val?.replaceAll(" ", "");
			})
			.min(10, i18n.t("commands.error.phoneLength"))
			.max(10, i18n.t("commands.error.phoneLength"))
			.matches(/^[0-9]+$/, i18n.t("commands.error.phoneOnlyDigits"))
			.matches(/^0[1-9][0-9]{8}$/, i18n.t("commands.error.phoneMustStartBy")),
		email: Yup.string().nullable().email(i18n.t("commands.error.emailValid")),
		isCustom: Yup.bool(),
	}),
	// COMMAND CONTRACT TYPE FORM
	contractTypeId: Yup.number().when("type", {
		is: (typeValue) => typeValue === COMMAND_TYPE_INVESTMENT,
		then: (schema) => schema.required(i18n.t("commands.error.contractTypeRequired")),
		otherwise: (schema) => schema.nullable(),
	}),
	// COMMAND APPEAL CASE FORM
	appealCase: Yup.object().shape({
		id: Yup.number().nullable(),
		tempoId: Yup.number().nullable(),
		model: Yup.object().shape({
			id: Yup.string().nullable(),
			template: Yup.string().nullable(),
		}),
	}),
	// COMMAND QUALIFICATIONS FORM
	qualification: Yup.object()
		.shape({
			id: Yup.number().required(i18n.t("commands.error.qualificationRequired")),
			source: Yup.string().nullable(),
			workerRequestedCount: Yup.number().min(1, i18n.t("commands.error.minimumWorkerNumber")),
			deletedAt: Yup.string().nullable(),
			name: Yup.string().required(i18n.t("commands.error.qualificationRequired")),
		})
		.test("is-qualification-deleted", i18n.t("commands.error.qualificationRequired"), (value) => {
			return value?.deletedAt === null;
		}),
	qualificationNote: Yup.string()
		.nullable()
		.max(800, i18n.t("commands.error.maxCaractersLimit", { count: 800 })),
	// COMMAND WORK ADDRESS FORM
	workAddress: Yup.object().shape({
		id: Yup.string().nullable(),
		libelle: Yup.string().nullable(),
		address: Yup.string().nullable(),
		meetingAddress: Yup.string().nullable(),
		addressComplement: Yup.string().nullable(),
		meetingAddressComplement: Yup.string().nullable(),
		postalCode: Yup.string()
			.nullable()
			.matches(/^[0-9]+$/, i18n.t("commands.locationForm.zipCodeErrorOnlyNumbers")),
		meetingPostalCode: Yup.string()
			.nullable()
			.matches(/^[0-9]+$/, i18n.t("commands.locationForm.zipCodeErrorOnlyNumbers")),
		city: Yup.string().nullable(),
		meetingCity: Yup.string().nullable(),
		country: Yup.string().nullable(),
		meetingCountry: Yup.string().nullable(),
		isClientMainAddress: Yup.boolean(),
		geographicSector: Yup.string().nullable(),
		comment: Yup.string()
			.nullable()
			.max(800, i18n.t("commands.error.maxCaractersLimit", { count: 800 })),
		latitude: Yup.string().nullable(),
		longitude: Yup.string().nullable(),
	}),
	// COMMAND PERIOD FORM
	period: PeriodRule(),
	workPeriodType: Yup.string().oneOf(Object.values(WORK_PERIOD_TYPES)).required(),
	workingTime: WorkingTimeRule(),
	// COMMAND MISSION FORM
	missionDescription: Yup.string()
		.nullable()
		.max(800, i18n.t("commands.error.maxCaractersLimit", { count: 800 })),
	// COMMAND BILLING FORM
	managementType: Yup.string().required(),
	package: Yup.string()
		.oneOf([...Object.values(COMMAND_BILLING_PACKAGES), null])
		.nullable(),
	packageAmount: Yup.number()
		.test("is-float", i18n.t("commands.error.packageAmountFloat"), (value) => {
			if (!value) {
				return true;
			}
			return /^\d+(\.\d{1,2})?$/.test(value.toString());
		})
		.nullable(),
});
