import { TNotificationsFilters, TNotificationsTypes } from "src/types/TNotifications";

// @ts-nocheck
export const NOTIFICATION_STATUS_ALL = "all";
export const NOTIFICATION_STATUS_READ = "read";
export const NOTIFICATION_STATUS_UNREAD = "unread";

export const DEFAULT_NOTIFICATIONS_SEARCH_PARAMS = {
	limit: 40,
	page: 1,
	sorting: "createdAt",
	orderBy: "DESC",
};

export const DEFAULT_NOTIFICATIONS_LIST_FILTERS: TNotificationsFilters = {
	count: 0,
	status: NOTIFICATION_STATUS_UNREAD,
	agencies: [],
	commercialCodes: [],
	search: "",
	createdAt: null,
	type: {
		timesheets: {
			all: false,
			validatedTimesheetWithSts: false,
			timesheetWithComment: false,
		},
		ratings: {
			all: false,
			negativeRating: false,
			positiveRating: false,
		},
	} as TNotificationsTypes,
	defaultAgencies: [],
};

export const DEFAULT_NOTIFICATIONS_SEARCH_FILTERS = {
	status: NOTIFICATION_STATUS_ALL,
	agencies: [] as number[],
	commercialCodes: [] as number[],
	search: "",
	createdAt: null as Date | null,
	theme: {
		all: true,
		timesheet: false,
		rating: false,
	},
	type: {
		validated_timesheet_with_sts: false,
		timesheet_with_comment: false,
		negative_rating: false,
		positive_rating: false,
	},
};

export const DEFAULT_NOTIFICATIONS_COUNT_FILTERS = {
	status: NOTIFICATION_STATUS_UNREAD,
	agencies: [] as number[],
	commercialCodes: [] as number[],
	search: "",
	createdAt: null as Date | null,
	theme: {
		all: true,
		timesheet: false,
		rating: false,
	},
	type: {
		validated_timesheet_with_sts: false,
		timesheet_with_comment: false,
		negative_rating: false,
		positive_rating: false,
	},
};
