import { isEmpty } from "lodash";
import * as Yup from "yup";
import { OrderInterface } from "src/types/OrderInterface";

/**
 * This function validates the slots of the working time between two slots selected by the user.
 * @param _
 * @param context
 */
const isValidSlots = (_: string | null, context: Yup.TestContext<OrderInterface>): boolean => {
	const { workingTime } = context.options.context;

	const slotsLength = workingTime.slots.length;
	if (slotsLength === 1) {
		return true;
	} else if (slotsLength > 2) {
		return false;
	}

	const { slots } = workingTime;
	const firstSlot = slots[0];
	const secondSlot = slots[1];

	const firstTimeSlotEnd = firstSlot.endHour;
	const secondTimeSlotStart = secondSlot.startHour;

	const splitFirstEndDateSlot = firstTimeSlotEnd?.split(":");
	const splitSecondStartSlot = secondTimeSlotStart?.split(":");

	if (
		isEmpty(splitFirstEndDateSlot) ||
		isEmpty(splitSecondStartSlot) ||
		splitFirstEndDateSlot.length !== 2 ||
		splitSecondStartSlot.length !== 2
	) {
		return true;
	}

	const firstEndDateSlot = new Date().setHours(+splitFirstEndDateSlot[0], +splitFirstEndDateSlot[1], 0, 0);
	const secondStartDateSlot = new Date().setHours(+splitSecondStartSlot[0], +splitSecondStartSlot[1], 0, 0);

	return secondStartDateSlot >= firstEndDateSlot;
};

export default isValidSlots;
