// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/index.scss";

// You shouldn't modify this JS File. If you want to add wrapper, search for App.js instead

// new mount in React 18
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

/*
	the next lines are used to prevent the types errors comming from @zolteam/react-ras-library
	exemple: "Warning: Tooltip: Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead."
*/
const error = console.error;
console.error = (...args: any) => {
	if (/defaultProps/.test(args[0])) return;
	error(...args);
};
