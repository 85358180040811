// Constants
import { WORK_PERIOD_TYPES } from "src/constants";

// Types
import { OrderInterface } from "src/types/OrderInterface";
import { DAYS_INDEXES_TABLE } from "src/constants/daysIndexesTable";
import { get, isEmpty } from "lodash";
import { NIGHT_DAYS_INDEXES_TABLE } from "src/constants/nightDaysIndexesTable";
import { getDayOfWeek } from "src/utils/dates";

const assertValidValueForNightMode = (startDate: Date, endDate: Date, nightWeekString: string): void => {
	while (startDate < endDate) {
		const dayOfWeek: string = NIGHT_DAYS_INDEXES_TABLE[getDayOfWeek(startDate)];

		if (dayOfWeek === nightWeekString) {
			return;
		}

		startDate.setDate(startDate.getDate() + 1);
	}

	throw new Error(`Night ${nightWeekString} is invalid`);
};

const assertValidValueForDayMode = (startDate: Date, endDate: Date, dayWeekString: string): void => {
	while (startDate <= endDate) {
		const dayOfWeek: string = DAYS_INDEXES_TABLE[getDayOfWeek(startDate)];

		if (dayOfWeek === dayWeekString) {
			return;
		}

		startDate.setDate(startDate.getDate() + 1);
	}

	throw new Error(`Day ${dayWeekString} is invalid`);
};

export const checkWorkDaysSelectedAreOutOfPeriod = (formValues: OrderInterface): boolean => {
	const { workingTime, workPeriodType, period } = formValues;
	const { workingDays, workingNights } = workingTime;

	if (isEmpty(period?.startDate) || isEmpty(period?.endDate)) {
		return false;
	}

	const workDays = workPeriodType === WORK_PERIOD_TYPES.DAY ? workingDays : workingNights;

	for (let workDay in workDays) {
		const startDate = new Date(period.startDate);
		const endDate = new Date(period.endDate);

		// Get current value for selected day
		const selectedDayValue: boolean = get(workDays, workDay, false);

		if (selectedDayValue === false) {
			// Skip this day because no need to check it
			continue;
		}

		try {
			if (workPeriodType === WORK_PERIOD_TYPES.NIGHT) {
				assertValidValueForNightMode(startDate, endDate, workDay);
			} else if (workPeriodType === WORK_PERIOD_TYPES.DAY) {
				assertValidValueForDayMode(startDate, endDate, workDay);
			}
		} catch (error) {
			return true;
		}
	}

	return false;
};
