// @ts-nocheck
/* eslint-disable no-nested-ternary */
import { Button, ModalV2, Picto, SelectStatus, Text, Tooltip } from "@zolteam/react-ras-library";
import { isEmpty } from "lodash";
import { cloneElement, forwardRef, useMemo, useState, useCallback } from "react";
import { useTranslation, Trans } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import ModalContentManualCommandFilled from "../../../views/Command/Profiles/Modals/ModalContentManualCommandFilled";
import ModalContentOrderNotProvided from "../../../views/Command/Profiles/Modals/ModalContentOrderNotProvided/ModalContentOrderNotProvided";

// Atoms
import CommandSideMenuItem from "../../atoms/CommandSideMenuItem";
import IdBox from "../../atoms/IdBox";
import ProfileNav from "../../atoms/ProfileNav";

// Hooks
import useEnabledFeatures from "../../../hooks/useEnabledFeatures";
import useOrderData from "../../../hooks/useOrderData";
import useOrderSideBarInformations from "src/hooks/useOrderSideBarInformations";

// Utils
import formatPhoneNumber from "../../../utils/formatPhoneNumber";

import { getOrderProfiles } from "../../../services/commandService";
import { patchOrderStatus } from "src/services/commands/patchOrderStatusService";

// Constants
import { CREATE_MODE, ORDER_STATUS, PROFILES_STATUS } from "../../../constants";

// Style
import cn from "../../../utils/cn";
import style from "./CommandSideMenu.module.css";

// Store
import { useToastContext } from "../../../store/ToastContext";
import OrderProfileTypes from "src/services/types/OrderProfileTypes";

interface RouteParams {
	orderId: string;
}

const CommandSideMenu = forwardRef(({ contact, routes, backgroundType, children: views }, scrollRef) => {
	const { t } = useTranslation();
	const { orderId } = useParams<RouteParams>();
	const { refetchOrderData, orderData, agencyFeaturesData } = useOrderData(orderId);
	const queryClient = useQueryClient();
	const [, toastDispatch] = useToastContext();
	const { pathname } = useLocation();
	const [modalContentManualCommandFilled, setModalContentManualCommandFilled] = useState(false);
	const [orderNotProvidedModalStatus, setOrderNotProvidedModalStatus] = useState(false);
	const { data: order } = orderData || {};
	const isNewCommand = orderId === CREATE_MODE;
	const {
		formatedMissionTime,
		clientLocation,
		periods,
		primaryPhone,
		contactPhone,
		contactPhone2,
		orderStatus,
		statusReasonDescription,
		notProvidedReason,
		orderTempoId,
		agencyName,
		clientName,
		qualificationName,
		contactName,
		contactEmail,
		startDate,
		endDate,
		refetchSideBarInformations,
	} = useOrderSideBarInformations(orderId);

	const {
		enabledFeatures: { hasOrderProfilesAccess, hasMatchingAccess },
	} = useEnabledFeatures();

	// Api calls

	const { data: orderProfilesData } = useQuery(["getOrderProfiles", orderId], () => getOrderProfiles(orderId), {
		enabled: !!order,
	});

	const commandStatusOption = [
		{
			label: t("commands.statusType.IN_PROGRESS"),
			value: ORDER_STATUS.IN_PROGRESS,
		},
		{
			label: t("commands.statusType.IS_FILLED"),
			value: ORDER_STATUS.IS_FILLED,
		},
		{
			label: t("commands.statusType.NOT_PROVIDED"),
			value: ORDER_STATUS.NOT_PROVIDED,
		},
	];

	const filteredCommandStatusOption = useMemo(() => {
		if (!orderProfilesData?.data) return [];

		const hasValidatedStatus = orderProfilesData.data.some(
			(profile: OrderProfileTypes) => profile.status === PROFILES_STATUS.VALIDATED
		);
		const inProgressStatus = orderStatus === ORDER_STATUS.IN_PROGRESS;
		const notProvidedStatus = orderStatus === ORDER_STATUS.NOT_PROVIDED;

		if (hasValidatedStatus && inProgressStatus) {
			return [
				{
					label: t("commands.statusType.IS_FILLED"),
					value: ORDER_STATUS.IS_FILLED,
				},
				{
					label: t("commands.statusType.NOT_PROVIDED"),
					value: ORDER_STATUS.NOT_PROVIDED,
				},
			];
		}

		if (!hasValidatedStatus && inProgressStatus) {
			return [
				{
					label: t("commands.statusType.NOT_PROVIDED"),
					value: ORDER_STATUS.NOT_PROVIDED,
				},
			];
		}

		if (notProvidedStatus) {
			return [];
		}

		return commandStatusOption.filter(({ value }) => value !== orderStatus);
	}, [orderStatus, orderProfilesData, orderProfilesData?.data]);

	const orderStatusValue = useMemo(() => {
		if (orderStatus) {
			return commandStatusOption.find(({ value }) => value === orderStatus);
		}
		return { label: "", value: "" };
	}, [orderStatus]);

	const handleSelectStatusVariant = (newStatus: (typeof ORDER_STATUS)[keyof typeof ORDER_STATUS]) => {
		switch (newStatus) {
			case ORDER_STATUS.IN_PROGRESS:
				return "default";
			case ORDER_STATUS.IS_FILLED:
				return "success";
			case ORDER_STATUS.NOT_PROVIDED:
				return "neutral";
			default:
				return "default";
		}
	};

	const getSuccessMessage = (orderStatus: (typeof ORDER_STATUS)[keyof typeof ORDER_STATUS]) => {
		if (orderStatus === ORDER_STATUS.IS_FILLED) {
			return t("commands.profiles.modalsContent.commandFilled.succesFilledOrderMessage");
		}
		if (orderStatus === ORDER_STATUS.IN_PROGRESS) {
			return t("commands.profiles.modalsContent.commandFilled.succesInProgressdOrderMessage");
		}
		if (orderStatus === ORDER_STATUS.NOT_PROVIDED) {
			return t("commands.profiles.modalsContent.commandFilled.succesNotFilledOrderMessage");
		}
		return null;
	};

	const patchOrderStatusMutation = useMutation(
		async (newStatus) =>
			patchOrderStatus(
				orderId,
				newStatus.value,
				newStatus.statusReasonId || null,
				newStatus.statusReasonDescription || null
			),
		{
			onMutate: async () => {
				setModalContentManualCommandFilled(false);
				await queryClient.cancelQueries({ queryKey: ["getOrder", orderId] });
				const previousState = queryClient.getQueryData(["getOrder", orderId]);

				return { previousState };
			},
			onSuccess: (response) => {
				toastDispatch({
					type: "SUCCESS",
					payload: {
						icon: "checkCircle",
						msg: getSuccessMessage(response?.data?.status),
					},
				});
			},
			onError: (error, _, context) => {
				const { message } = error.response.data;
				queryClient.setQueryData(["getOrder", orderId], context.previousState);
				toastDispatch({
					type: "ERROR",
					payload: {
						icon: "alertCircle",
						msg: t(`commands.apiCommandErrors.${message}`)
							? t(`commands.apiCommandErrors.${message}`)
							: message,
						params: {
							autoClose: true,
							closeOnClick: true,
						},
					},
				});
			},
			onSettled: () => {
				refetchOrderData();
				refetchSideBarInformations();
				queryClient.invalidateQueries({ queryKey: ["getOrder", orderId] });
			},
		}
	);

	const handleOrderStatusChange = useCallback(
		(newOrderStatus) => {
			if (newOrderStatus.value === ORDER_STATUS.IS_FILLED) {
				setModalContentManualCommandFilled(true);
			} else if (newOrderStatus.value === ORDER_STATUS.NOT_PROVIDED) {
				setOrderNotProvidedModalStatus(true);
			} else {
				patchOrderStatusMutation.mutate(newOrderStatus);
			}
		},
		[setModalContentManualCommandFilled, setOrderNotProvidedModalStatus, patchOrderStatusMutation]
	);

	const handleConfirmModalClick = async () => {
		patchOrderStatusMutation.mutate({
			label: t("commands.statusType.IS_FILLED"),
			value: ORDER_STATUS.IS_FILLED,
		});
	};

	const handleConfirmOrderNotProvidedClick = (orderNotProvidedSelected, statusReasonDescription) => {
		setOrderNotProvidedModalStatus(false);
		patchOrderStatusMutation.mutate({
			label: t("commands.statusType.NOT_PROVIDED"),
			value: ORDER_STATUS.NOT_PROVIDED,
			statusReasonId: orderNotProvidedSelected.id,
			statusReasonDescription: statusReasonDescription,
		});
	};

	const links = useMemo(() => {
		if (!orderId) return [];
		return routes
			.filter((route) => {
				switch (route.routeName) {
					case "profils": {
						return hasOrderProfilesAccess && agencyFeaturesData?.hasAccessToProfileManagement;
					}
					case "matching": {
						return hasMatchingAccess;
					}
					default: {
						return true;
					}
				}
			})
			.map((route) => ({
				...route,
				path: route.path.replace(":orderId", orderId),
			}));
	}, [routes, orderId, hasOrderProfilesAccess, hasMatchingAccess, agencyFeaturesData?.hasAccessToProfileManagement]);

	const renderPeriod = () => {
		const { startHourAm, endHourAm, startHourPm, endHourPm } = formatedMissionTime ?? {};
		const hasFirstSlotValid = !!startHourAm && !!endHourAm;
		const hasLastSlotValid = !!startHourPm && !!endHourPm;
		const hasExactlyOneEmptyStringValue = (obj) => {
			const values = Object.values(obj);
			const emptyStringCount = values.filter((value) => value === "").length;
			return emptyStringCount === 1;
		};

		if (hasExactlyOneEmptyStringValue(formatedMissionTime)) {
			return "";
		}

		if (hasFirstSlotValid && hasLastSlotValid) {
			return (
				<>
					<Text tag="div" size="paragraph01" fontWeight="light" color="white" className={style.ptxs}>
						{t("commands.profiles.modalsContent.sharingOffers.timeSpan", {
							startHour: formatedMissionTime?.startHourAm,
							endHour: formatedMissionTime?.endHourAm,
						})}
					</Text>
					<Text tag="div" size="paragraph01" fontWeight="light" color="white" className={style.ptxs}>
						{t("commands.profiles.modalsContent.sharingOffers.timeSpan", {
							startHour: formatedMissionTime?.startHourPm,
							endHour: formatedMissionTime?.endHourPm,
						})}
					</Text>
				</>
			);
		}
		if (hasFirstSlotValid && !hasLastSlotValid) {
			return t("commands.profiles.modalsContent.sharingOffers.timeSpan", {
				startHour: startHourAm,
				endHour: endHourAm,
			});
		}
		if (!hasFirstSlotValid && hasLastSlotValid) {
			return t("commands.profiles.modalsContent.sharingOffers.timeSpan", {
				startHour: startHourPm,
				endHour: endHourPm,
			});
		}
		return "";
	};

	const renderSelectStatus = (size: string) => {
		return (
			<SelectStatus
				options={filteredCommandStatusOption || []}
				variant={handleSelectStatusVariant(orderStatusValue.value)}
				value={orderStatusValue}
				onChange={handleOrderStatusChange}
				size={size}
				noOptionsMessage={() => null}
				isDisabled={orderStatusValue.value === ORDER_STATUS.NOT_PROVIDED}
				className={style.selectStatus}
			/>
		);
	};

	return (
		<>
			<div className={cn([style.sidemenu, style.sidemenuDesktop])} data-testid="formSideMenu">
				{!isNewCommand && (
					<div className={style.info}>
						<div className={style.selectStatus}>
							{!notProvidedReason ? (
								renderSelectStatus("medium")
							) : (
								<Tooltip
									placement="top"
									animation={false}
									arrow={true}
									content={
										<p>
											<Trans
												i18nKey="commands.statusReasonDescription"
												values={{
													notProvidedReason: notProvidedReason,
													statusReasonDescription: statusReasonDescription,
												}}
												components={{ br: <br /> }}
											/>
										</p>
									}
								>
									<div>{renderSelectStatus("medium")}</div>
								</Tooltip>
							)}
						</div>
						{orderTempoId ? <IdBox>{orderTempoId}</IdBox> : null}
						<Text tag="div" size="heading02" color="white" fontWeight="bold" className={style.ptm}>
							{contact?.orderStatus || ""}
						</Text>
						{agencyName ? (
							<Text tag="div" size="paragraph01" color="white" fontWeight="light" className={style.pts}>
								{agencyName || ""}
							</Text>
						) : (
							<Skeleton
								className={style.skeletonMenuItem}
								baseColor="var(--color-neutral-700)"
								highlightColor="var(--color-neutral-600)"
								height={30}
							/>
						)}
						<hr className={style.menuSeparator} />
						{clientName ? (
							<Text tag="div" size="heading03" fontWeight="normal" color="white" className={style.ptxs}>
								{clientName || ""}
							</Text>
						) : (
							<Skeleton
								className={style.skeletonMenuItem}
								baseColor="var(--color-neutral-700)"
								highlightColor="var(--color-neutral-600)"
								height={50}
							/>
						)}
						<div className={style.contactBlock}>
							{contactName ? (
								<Text
									tag="div"
									size="paragraph01"
									color="white"
									fontWeight="light"
									className={style.pts}
								>
									{`${contactName}` || ""}
								</Text>
							) : contactName === null ? null : (
								<Skeleton
									className={style.skeletonMenuItem}
									baseColor="var(--color-neutral-700)"
									highlightColor="var(--color-neutral-600)"
									height={30}
									width={120}
								/>
							)}
						</div>
						{primaryPhone || contactEmail ? (
							<div className={style.contact}>
								<div className={style.contactPhone}>
									<div className={style.phone1}>
										{primaryPhone && (
											<a href={`tel:${primaryPhone}`} target="__blank">
												<Button
													tabIndex="-1"
													size="s"
													color="white"
													outline
													type="tel"
													className={style.contactButton}
												>
													{formatPhoneNumber(`+33${primaryPhone?.slice(1)}` || "")}
												</Button>
											</a>
										)}
										{contactEmail && (
											<button
												className={style.mailButton}
												onClick={(e) => {
													window.location = `mailto:${contactEmail}`;
													e.preventDefault();
												}}
												type="button"
												aria-label="mail"
											>
												<Picto icon="mail" className={style.mailIcon} />
											</button>
										)}
									</div>
									{contactPhone2 && contactPhone && (
										<a href={`tel:${contactPhone}`} target="__blank">
											<Button
												tabIndex="-1"
												size="s"
												color="white"
												outline
												type="tel"
												className={style.contactButton}
											>
												{formatPhoneNumber(`+33${contactPhone2?.slice(1)}` || "")}
											</Button>
										</a>
									)}
								</div>
							</div>
						) : contactPhone === null || contactEmail === null ? null : (
							<div className={style.skeletonContact}>
								<Skeleton
									className={style.skeletonMenuItem}
									baseColor="var(--color-neutral-700)"
									highlightColor="var(--color-neutral-600)"
									height={30}
								/>
							</div>
						)}
						<hr className={style.menuSeparator} />
						{qualificationName ? (
							<Text
								tag="div"
								size="heading03"
								fontWeight="bold"
								color="white"
								className={cn([style.ptxs, style.qualification])}
							>
								{qualificationName}
							</Text>
						) : (
							<Skeleton
								className={style.skeletonMenuItem}
								baseColor="var(--color-neutral-700)"
								highlightColor="var(--color-neutral-600)"
								height={30}
								width={100}
							/>
						)}
						{clientLocation ? (
							<Text tag="div" size="paragraph01" fontWeight="light" color="white" className={style.ptm}>
								{clientLocation}
							</Text>
						) : clientLocation === undefined ? (
							<Skeleton
								className={cn([style.skeletonMenuItem, style.skeletonSpacing])}
								baseColor="var(--color-neutral-700)"
								highlightColor="var(--color-neutral-600)"
								height={30}
							/>
						) : null}
						{startDate && endDate ? (
							<Text tag="div" size="paragraph01" fontWeight="light" color="white" className={style.pts}>
								{periods}
							</Text>
						) : (
							<Skeleton
								className={cn([style.skeletonMenuItem, style.skeletonSpacing])}
								baseColor="var(--color-neutral-700)"
								highlightColor="var(--color-neutral-600)"
								height={30}
								width={100}
								count={2}
							/>
						)}
						<div className={style.pts}>{renderPeriod()}</div>
					</div>
				)}

				<div className={style.menu}>
					{links.map((route) => (
						<CommandSideMenuItem key={route.label} path={route.path}>
							{route.label}
						</CommandSideMenuItem>
					))}
				</div>
			</div>
			<div className={style.sidemenuMobile}>
				<div className={style.info}>
					{!isEmpty(order) ? (
						<>
							{orderTempoId && (
								<div className={style.idBox}>
									<IdBox>{orderTempoId}</IdBox>
								</div>
							)}
							{notProvidedReason ? (
								<Tooltip
									placement="bottom"
									animation={false}
									arrow={true}
									content={
										<p>
											<Trans
												i18nKey="commands.statusReasonDescription"
												values={{
													notProvidedReason: notProvidedReason,
													statusReasonDescription: statusReasonDescription,
												}}
												components={{ br: <br /> }}
											/>
										</p>
									}
								>
									<div>{renderSelectStatus("small")}</div>
								</Tooltip>
							) : (
								renderSelectStatus("small")
							)}
							<Text
								tag="div"
								size="paragraph01"
								color="black"
								fontWeight="normal"
								className={style.infoText}
							>
								{`${clientName || t("global.loading")} - ${qualificationName}`}
							</Text>
						</>
					) : null}
				</div>
				<div className={style.menu}>
					{links.map((route) => (
						<ProfileNav key={route.label} path={route.path} isActive={pathname === route.path}>
							{route.label}
						</ProfileNav>
					))}
				</div>
			</div>
			<div
				className={cn([style.container, style.containerMobile, style[`containerTheme${backgroundType}`]])}
				ref={scrollRef}
			>
				{cloneElement(views, {
					scrollRef,
				})}
			</div>
			<ModalV2
				isDisplayed={modalContentManualCommandFilled}
				onClose={() => setModalContentManualCommandFilled(false)}
				size="s"
			>
				<ModalContentManualCommandFilled
					onConfirmClick={() => handleConfirmModalClick()}
					onCancelClick={() => {
						setModalContentManualCommandFilled(false);
					}}
				/>
			</ModalV2>
			<ModalV2
				isDisplayed={orderNotProvidedModalStatus}
				onClose={() => setOrderNotProvidedModalStatus(false)}
				size="s"
				className={style.modal}
			>
				<ModalContentOrderNotProvided
					onCancelClick={() => setOrderNotProvidedModalStatus(false)}
					onAddClick={(orderNotProvidedSelected, statusReasonDescription) => {
						handleConfirmOrderNotProvidedClick(orderNotProvidedSelected, statusReasonDescription);
					}}
					currentOrder={orderData?.data}
				/>
			</ModalV2>
		</>
	);
});

export default CommandSideMenu;
