import * as Yup from "yup";
import { Option } from "./ModalContentOrderNotProvidedTypes";
import ORDER_NOT_PROVIDED_REASONS from "src/constants/orderNotProvidedReasons";

export default Yup.object().shape({
	orderNotProvidedReason: Yup.object().shape({
		label: Yup.string().required(),
		id: Yup.string().required(),
	}),
	statusReasonDescription: Yup.string().when("orderNotProvidedReason", {
		is: (value: Option) => value && value.label === ORDER_NOT_PROVIDED_REASONS.OTHER,
		then: () => Yup.string().required().max(100),
		otherwise: () => Yup.string().nullable(),
	}),
});
