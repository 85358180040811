// @ts-nocheck

import React, { FC, useEffect, useState } from "react";
import { Button, Picto, PictoAction, SelectMulti, Title } from "@zolteam/react-ras-library";
import { useTranslation } from "react-i18next";

// Atoms
import Tag from "../../atoms/Tag";
// Molecules
import OptionMultiSelect from "../../molecules/OptionMultiSelect";
// Organisms
import ListMaxWidth from "../../organisms/ListMaxWidth";

// Style
import style from "./AgenciesFilter.module.css";

// Utils
import cn from "../../../utils/cn";

interface IAgenciesFilterProps {
	options: Record<string, any>[];
	selectedOptions: number[];
	handleFilter: (choosenOptions: Record<string, any>[]) => void;
	maxWidth?: string;
	inputSize?: string;
	widthSelect?: string;
	titleOnTop?: boolean;
	activeSelectAllOption?: boolean;
	disableOtherOptionsOnSelectAll?: boolean;
	maxSelectionCount?: number;
	agenciesInputWidth?: string;
	canExpendOrFold?: boolean;
	onReset?: () => void;
	canReset?: boolean;
}

const AgenciesFilter: FC<IAgenciesFilterProps> = ({
	options,
	selectedOptions,
	handleFilter,
	onReset,
	maxWidth = "300",
	inputSize = "m",
	widthSelect,
	titleOnTop = false,
	activeSelectAllOption = false,
	disableOtherOptionsOnSelectAll = false,
	maxSelectionCount = null,
	agenciesInputWidth,
	canExpendOrFold,
	canReset,
}) => {
	// Hook
	const { t } = useTranslation();
	const [isFilterMinimized, setIsFilterMinimized] = useState(false);
	const [selectedAgencies, setSelectedAgencies] = useState([]);
	const [initialValues, setInitialValues] = useState([]);
	const [isFirstTime, setIsFirstTime] = useState(true);

	const isLoaded = options?.length > 0 && selectedOptions?.length > 0 && isFirstTime;

	// Constant
	const PREFIX_AGENCY_NAME = "RAS ";

	// Handler
	const handleUpdate = (choosenOptions) => {
		handleFilter(choosenOptions);
	};

	const handleReset = () => {
		if (onReset) {
			return onReset();
		}
		handleFilter(initialValues);
	};

	const handleDelete = (id) => {
		const filteredOptions = selectedAgencies.filter((agency) => agency.id !== id);
		handleFilter(filteredOptions);
	};

	const handleExpendOrFold = () => setIsFilterMinimized(!isFilterMinimized);

	const getSelectedAgencies = () =>
		options.reduce((acc, item) => {
			if (selectedOptions?.includes(item.id)) {
				acc.push(item);
			}
			return acc;
		}, []);

	useEffect(() => {
		setInitialValues(getSelectedAgencies());
		setIsFirstTime(false);
	}, [isLoaded]);

	useEffect(() => {
		setSelectedAgencies(getSelectedAgencies());
	}, [selectedOptions]);

	return (
		<>
			<div className={style.containerTitleAndClearBtn} style={{ marginBottom: isFilterMinimized ? "" : "10px" }}>
				{titleOnTop ? (
					<Title tag="h3" fontWeight="normal" lineHeight="s">
						{t("clientsMyTemp.notifications.agency")}
					</Title>
				) : null}
				{titleOnTop ? (
					<div className={style.containerWithClearBtn}>
						{(canReset ?? initialValues.length !== selectedAgencies.length) ? (
							<Button
								size="s"
								type="button"
								color="transparentPrimary"
								className={style.clearBtn}
								onClick={() => handleReset()}
							>
								{t("global.filters.reinit")}
							</Button>
						) : null}
						{canExpendOrFold && (
							<Button color="transparent" type="button" onClick={() => handleExpendOrFold()}>
								<Picto
									icon={isFilterMinimized ? "chevronUp" : "chevronDown"}
									style={{ width: "20px", height: "20px", marginRight: 0 }}
								/>
							</Button>
						)}
					</div>
				) : null}
			</div>
			<div className={style.filterContainer} style={{ display: isFilterMinimized ? "none" : "block" }}>
				{selectedAgencies.length ? (
					<div
						className={cn([style.agenciesFilter])}
						style={{
							marginBottom: "var(--margin-s)",
						}}
					>
						<ListMaxWidth
							array={selectedAgencies}
							component={({ data: { name, id } }) => (
								<Tag color="primary" size="xs">
									<span>{name}</span>
									<button
										type="button"
										className="noButton"
										aria-label="Delete"
										onClick={() => handleDelete(id)}
									>
										<Picto icon="close" />
									</button>
								</Tag>
							)}
							keyListElem="id"
							margeError={36}
							maxWidth={maxWidth}
							maxWidthMarge={16}
							addMaxWidthMarge={!titleOnTop && options.length !== selectedAgencies.length}
						/>
						{!titleOnTop && initialValues.length !== selectedAgencies.length ? (
							<PictoAction
								className={style.iconPink}
								picto="trash"
								pictoSize={{ width: "16px", height: "16px" }}
								onClick={handleReset}
							/>
						) : null}
					</div>
				) : null}
				<div
					className={style.agenciesInput}
					style={{
						width: agenciesInputWidth,
					}}
				>
					<SelectMulti
						isMulti
						size={inputSize}
						value={selectedAgencies}
						name="agencies"
						label={t(titleOnTop ? "global.filters.selectOptionsText" : "global.filters.agenciesLabel")}
						options={options}
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						selectAllLabel={t("global.filters.allAgencies")}
						activeSelectAllOption={activeSelectAllOption}
						disableOtherOptionsOnSelectAll={disableOtherOptionsOnSelectAll}
						maxSelectionCount={maxSelectionCount}
						components={{
							Option: OptionMultiSelect,
						}}
						onChange={(choosenOptions) => handleUpdate(choosenOptions)}
						getOptionLabel={(option) => option.name.replace(PREFIX_AGENCY_NAME, "")}
						getOptionValue={(option) => option.id}
						isClearable={false}
						useFormikStyle={false}
					/>
				</div>
			</div>
		</>
	);
};

export default AgenciesFilter;
