import { t } from "i18next";
import { FC } from "react";
import { useQuery } from "react-query";
import CheckboxListFilter from "src/components/organisms/CheckboxListFilter/CheckboxListFilter";
import { DEFAULT_USERS_LISTING_FILTERS } from "src/constants/CUsersListing";
import { useFiltersContext } from "src/store/FiltersContext/useFiltersContext";
import { TAgency } from "src/types";
import { TUsersListingFilters } from "src/types/TUsersListing";
import DatePickerFilter from "../../../components/organisms/DatePickerFilter/DatePickerFilter";
import FilterModal from "../../../components/organisms/FilterModal/FilterModal";
import AgenciesFilter from "../../../components/templates/AgenciesFilter";
import user from "../../../services/user";

interface IUsersListingFiltersModalProps {
	onSubmit: (filters: TUsersListingFilters) => void;
	isDisplayed: boolean;
	onCloseModal: () => void;
}

const UsersListingFiltersModal: FC<IUsersListingFiltersModalProps> = ({ onSubmit, isDisplayed, onCloseModal }) => {
	const { filters } = useFiltersContext<TUsersListingFilters>("users");

	const { data: userAgenciesData, isLoading } = useQuery(["getUserAgencies", "filtermodal"], () =>
		user.getUserAgencies().then((res) => {
			if (!filters?.agenciesIds?.length) {
				onSubmit({ ...filters });
			}
			return res.data;
		})
	);

	const onClose = () => {
		onCloseModal();
	};

	const handleChangeMyRas = (type: string, formikCtx: any) => {
		formikCtx.setFieldValue("hasMyRas", type);
	};

	return (
		<FilterModal
			onSubmit={onSubmit}
			isDisplayed={isDisplayed}
			onCloseModal={onClose}
			defaultFilters={{
				...DEFAULT_USERS_LISTING_FILTERS,
			}}
			filterKey="users"
			size="s"
			isLoading={isLoading}
		>
			{(formikCtx) => {
				const { setFieldValue, values, resetField } = formikCtx;
				return (
					<>
						<div>
							{userAgenciesData?.length > 0 && (
								<div>
									<AgenciesFilter
										titleOnTop
										activeSelectAllOption
										options={userAgenciesData || []}
										selectedOptions={values?.agenciesIds?.map((agency: TAgency) => agency.id)}
										handleFilter={(selectedAgencies) => {
											setFieldValue("agenciesIds", selectedAgencies);
										}}
										canExpendOrFold={false}
										agenciesInputWidth="100%"
										inputSize="m"
										canReset={values?.agenciesIds?.length !== 0}
										onReset={() => resetField("agenciesIds")}
									/>
								</div>
							)}
							<DatePickerFilter
								handleReset={values.createdSince ? () => resetField("createdSince") : null}
								placeholder={values?.createdSince ? null : "DD/MM/YYYY"}
								onChange={(date) => setFieldValue("createdSince", date)}
								title={t("clientsMyTemp.notifications.filterTitles.startDate")}
								value={values?.createdSince ? new Date(values.createdSince) : null}
							/>
							<CheckboxListFilter
								title={t("usersListing.filters.myRas")}
								items={{
									true: true,
									false: false,
								}}
								onChange={(type) => handleChangeMyRas(type, formikCtx)}
								type="radio"
								orientation="horizontal"
								getLabel={(type) => t(`global.${type}`)}
								name="hasMyRas"
								getValue={(type) => {
									return type === values?.hasMyRas;
								}}
								listStyle={{
									display: "flex",
									justifyContent: "flex-start",
								}}
								labelClassName="flex-1"
								handleReset={() => setFieldValue("hasMyRas", DEFAULT_USERS_LISTING_FILTERS.hasMyRas)}
							/>
						</div>
					</>
				);
			}}
		</FilterModal>
	);
};

export default UsersListingFiltersModal;
