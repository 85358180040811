import { useState, useEffect, useCallback } from "react";
import { useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";

import { PROFILES_STATUS, ORDER_STATUS } from "src/constants";

// Services
import {
	postUnifiedTempWorkerOrdersSearch,
	UnifiedTempWorkerOrdersSearchApiResponse,
	UnifiedTempWorkerOrdersQueryParamsType,
} from "src/services/UnifiedTempWorker/unifiedTempWorkerOrdersSearchService";

// Constants
import { DEFAULT_SEARCH_PARAMS } from "./constants";

const useProfileMissionsOrders = () => {
	const { profileId } = useParams<ProfileMissionsOrdersParams>();
	const history = useHistory();
	const [searchParams, setSearchParams] = useState<UnifiedTempWorkerOrdersQueryParamsType>(DEFAULT_SEARCH_PARAMS);
	const [data, setData] = useState({ items: [], total: 0 });
	const [isFirstLoading, setIsFirstLoading] = useState(true);

	const postProfileOrdersSearchMutation = useMutation<
		AxiosResponse<UnifiedTempWorkerOrdersSearchApiResponse>,
		Error,
		UnifiedTempWorkerOrdersQueryParamsType
	>("searchProfileOrders", (params) => postUnifiedTempWorkerOrdersSearch(profileId, params), {
		onSuccess: (result) => {
			setData(result.data);
			setIsFirstLoading(false);
		},
	});

	const { isError: isErrorSearchMutation, isLoading: isLoadingPSearchMutation } = postProfileOrdersSearchMutation;

	useEffect(() => {
		postProfileOrdersSearchMutation.mutate(searchParams);
	}, [searchParams]);

	const handleSearch = (params: { page?: number; limit?: number }) => {
		setSearchParams((prev) => ({ ...prev, ...params }));
		postProfileOrdersSearchMutation.mutate({ ...searchParams, ...params });
	};

	const handleDoubleClick = useCallback(
		({ orderId }: { orderId: number }) => {
			history.push(`/commandes/${orderId}/configuration`);
		},
		[history]
	);

	const getOrderStatusColor = useCallback((status: string) => {
		switch (status) {
			case ORDER_STATUS.IN_PROGRESS:
				return "default";
			case ORDER_STATUS.IS_FILLED:
				return "ghost";
			case ORDER_STATUS.NOT_PROVIDED:
				return "ghost";
			default:
				return "primaryLight";
		}
	}, []);

	const getProfileStatusColor = useCallback((status: string) => {
		switch (status) {
			case PROFILES_STATUS.SELECTED:
				return "grey";
			case PROFILES_STATUS.CONTACTED:
				return "primary";
			case PROFILES_STATUS.OFFER_ACCEPTED:
				return "primary";
			case PROFILES_STATUS.OFFER_DECLINED:
				return "grey";
			case PROFILES_STATUS.PROPOSED:
				return "primary";
			case PROFILES_STATUS.VALIDATED:
				return "success";
			case PROFILES_STATUS.RECUSED:
				return "grey";
			default:
				return "primary";
		}
	}, []);

	const isListLoading = useCallback(
		() => (isFirstLoading && !isErrorSearchMutation) || isLoadingPSearchMutation,
		[isFirstLoading, isErrorSearchMutation, isLoadingPSearchMutation]
	);

	const getTableRowHref = useCallback(
		({ orderId }: { orderId: number }) => `/commandes/${orderId}/configuration`,
		[]
	);

	return {
		searchParams,
		setSearchParams,
		data,
		isErrorSearchMutation,
		isFirstLoading,
		handleDoubleClick,
		handleSearch,
		getOrderStatusColor,
		getProfileStatusColor,
		isListLoading,
		getTableRowHref,
	};
};

export default useProfileMissionsOrders;
