import { useEffect, useMemo, useState } from "react";
import { DaysSelector, TabSwitch, Textarea, WorkHoursTimeSlots } from "@zolteam/react-ras-library";
import { useFormikContext } from "formik";
import { omit, set } from "lodash";
import "moment/locale/fr";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useMedia } from "react-use";

// Components
import DateRangeInput from "../../../../../components/atoms/DateRangeInput/DateRangeInput";
import TooltipMessage from "../../../../../components/atoms/TooltipMessage";
import DateRangePicker from "../../../../../components/molecules/DateRangePicker";

// Style
import cn from "../../../../../utils/cn";
import style from "./CommandTimeFormBlock.module.css";

// Constants
import { COMMAND_TYPE_INTERIM, CREATE_MODE, INDEX_DAYS, WORK_PERIOD_TYPES } from "src/constants";

// Utils
import { checkWorkDaysSelectedAreOutOfPeriod } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/utils/checkWorkDaysSelectedAreOutOfPeriod";
import { selectDefaultWorkingDays } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/utils/selectDefaultWorkingDays";
import { getStartDateRangeInputErrorMessage } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/utils/getStartDateRangeInputErrorMessage";
import { getEndDateRangeInputErrorMessage } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/utils/getEndDateRangeInputErrorMessage";
import { isPeriodError } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/utils/isPeriodError";
import { isWorkingTimeOnError } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/utils/isWorkingTimeOnError";
import { getWorkingTimeErrorMessage } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/utils/getWorkingTimeErrorMessage";
import { showWorkingTimeTooltipError } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/utils/showWorkingTimeTooltipError";
import { areAllDaySelected } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/utils/areAllDaySelected";
import { getNewValuesOnModeChanged } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/utils/getNewValuesOnModeChanged";
import { getDayOfWeek } from "src/utils/dates";

// Misc
import { commandPeriodFormDefaultValues } from "../commandFormDefaultValues";
import { OrderInterface } from "src/types/OrderInterface";
import {
	DATE_FORMAT_STRING,
	DEFAULT_DAY_WORKING_TIME,
	DEFAULT_NIGHT_WORKING_TIME,
	END_DATE_INPUT_NAME,
	START_DATE_INPUT_NAME,
} from "./constants";
import { NIGHT_DAYS_INDEXES_TABLE } from "src/constants/nightDaysIndexesTable";
import { DAYS_INDEXES_TABLE } from "src/constants/daysIndexesTable";
import { WORKING_MODE } from "src/constants/workingMode";

interface CommandTimeFormBlockProps {
	isOrderFetched: boolean;
}

interface Params {
	orderId: string;
}

const CommandTimeFormBlock = ({ isOrderFetched }: CommandTimeFormBlockProps) => {
	const { values, setValues, setFieldValue, setFieldTouched, touched, errors } = useFormikContext<OrderInterface>();

	const { type, period, workingTime, workPeriodType } = values as OrderInterface;
	const { t } = useTranslation();
	const { orderId } = useParams<Params>();
	const isNewCommand = orderId === CREATE_MODE;

	const showOneColumn = useMedia("(max-width: 1439px)");
	const showFullSize = useMedia("(min-width: 1920px)");

	const [focusedInputName, setFocusedInputName] = useState("");

	const startDateDayIndex = useMemo(() => {
		if (!period?.startDate) {
			return null;
		}
		return getDayOfWeek(new Date(period?.startDate));
	}, [period?.startDate]);

	const TRAD_DAYS = useMemo(() => INDEX_DAYS.map((index) => t(`global.days.${index}`)), [INDEX_DAYS]);

	const tabs = [
		{
			id: WORK_PERIOD_TYPES.DAY,
			label: t("commands.periodForm.day"),
		},
		{
			id: WORK_PERIOD_TYPES.NIGHT,
			label: t("commands.periodForm.night"),
		},
	];

	const currentTab = useMemo(() => {
		if (!workPeriodType) {
			return null;
		}
		return tabs.find((tab) => tab.id === workPeriodType).label;
	}, [workPeriodType]);

	// For now, switching between day and night will reset workingTime values
	const handleTabChange = async (index: number) => {
		const periodType = tabs[index].id;
		if (periodType === workPeriodType) {
			return;
		}

		setValues(getNewValuesOnModeChanged(periodType, values, isNewCommand));
	};

	const IS_NIGHT = useMemo(() => workPeriodType === WORK_PERIOD_TYPES.NIGHT, [workPeriodType]);

	const workingDaysToIndexesArray = useMemo(() => {
		const days = IS_NIGHT
			? { ...DEFAULT_NIGHT_WORKING_TIME.workingNights, ...workingTime?.workingNights }
			: { ...DEFAULT_DAY_WORKING_TIME.workingDays, ...workingTime?.workingDays };

		return Object.values(days || {})
			.map((isSelected, index) => (isSelected ? index + 1 : null)) // +1 is using for lib DaySelectors
			.filter(Boolean);
	}, [workingTime.workingDays, workingTime.workingNights, IS_NIGHT]);

	const handleClearPeriodDate = (periodSlot: string, event: React.SyntheticEvent) => {
		event.stopPropagation();
		const newValues = Object.assign({}, values, {
			workingTime: omit(
				{
					...values.workingTime,
					...(IS_NIGHT ? DEFAULT_NIGHT_WORKING_TIME : DEFAULT_DAY_WORKING_TIME),
				},
				[IS_NIGHT ? WORKING_MODE.DAYS : WORKING_MODE.NIGHTS]
			),
		});

		set(newValues, periodSlot, null);
		setValues(newValues);
		setFieldTouched(periodSlot, true);
	};

	const areAllDaysSelected: boolean = useMemo(() => {
		return areAllDaySelected(IS_NIGHT, values.workingTime);
	}, [values.workingTime, IS_NIGHT]);

	const handleDateRangeUpdate = (startDate?: moment.Moment | null, endDate?: moment.Moment | null) => {
		const newPeriod = {
			// WARNING: those dates are transformed when sent to the API (because they don't accept ISO format on POST/PUT)
			// If you rewrite those lines, watch out for the POST/PUT API call in Configuration.jsx
			startDate: startDate?.toISOString() || null,
			endDate: endDate?.toISOString() || null,
		};

		const newWorkingDays = selectDefaultWorkingDays(startDate, endDate, workPeriodType);

		if (startDate) {
			setFieldTouched("period.startDate", true);
		}
		if (endDate) {
			setFieldTouched("period.endDate", true);
		}

		return setValues({
			...values,
			period: newPeriod,
			workingTime: {
				...values.workingTime,
				[IS_NIGHT ? "workingNights" : "workingDays"]: newWorkingDays,
			},
		});
	};

	const handleDayClick = (selectedDayIndex: number) => {
		if (selectedDayIndex === startDateDayIndex) {
			return;
		}
		// The API use an object of days set with bool, but the component uses indexes and array. So we need
		// some dumb logic to match both of their behaviour.
		let weekDaySelected: string;

		if (IS_NIGHT) {
			weekDaySelected = NIGHT_DAYS_INDEXES_TABLE[selectedDayIndex];
			setFieldValue("workingTime.workingNights", {
				...values.workingTime?.workingNights,
				// @ts-ignore
				[weekDaySelected]: !values.workingTime?.workingNights[weekDaySelected],
			});
			setFieldTouched("workingTime.workingNights", true);
		} else {
			weekDaySelected = DAYS_INDEXES_TABLE[selectedDayIndex];
			setFieldValue("workingTime.workingDays", {
				...values.workingTime?.workingDays,
				// @ts-ignore
				[weekDaySelected]: !values.workingTime?.workingDays[weekDaySelected],
			});
			setFieldTouched("workingTime.workingDays", true);
		}
	};

	const handleChangeWorkHours = (e: { name: string; value: object }) => {
		const [slotLabel, slotIndex] = e.name.split("-");
		const newWorkingTimeSlots = workingTime.slots.map((slotValue, index) => {
			if (index === Number(slotIndex)) {
				return { ...slotValue, [slotLabel]: e.value || null };
			}
			return slotValue;
		});

		setFieldValue("workingTime.slots", newWorkingTimeSlots);
		setTimeout(() => {
			setFieldTouched("workingTime.slots", true, true);
			setFieldTouched("period.startDate", true, true);
			setFieldTouched("period.endDate", true, true);
		}, 0);
	};

	useEffect(() => {
		if (!isNewCommand) {
			setTimeout(() => {
				setFieldTouched("workingTime.slots", true, true);
			}, 0);
		}
	}, [isNewCommand, setFieldTouched, values]);

	useEffect(() => {
		const weekDaySelected = IS_NIGHT
			? NIGHT_DAYS_INDEXES_TABLE[startDateDayIndex]
			: DAYS_INDEXES_TABLE[startDateDayIndex];

		requestAnimationFrame(async () => {
			if (IS_NIGHT) {
				setFieldValue("workingTime.workingNights", {
					...values.workingTime?.workingNights,
					[weekDaySelected]: true,
				});
			} else {
				setFieldValue("workingTime.workingDays", {
					...values.workingTime?.workingDays,
					[weekDaySelected]: true,
				});
			}
		});
	}, [startDateDayIndex, currentTab]);

	const isBlockLoading = (!isOrderFetched && !isNewCommand) || !currentTab;

	if (isBlockLoading) {
		return (
			<div className={style.skeletonColumn}>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonPeriod} height={50} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonPeriod} height={50} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonDaysSelected} height={50} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonFullWidth} height={50} borderRadius={25} />
				</div>
				<div className={style.skeletonRow}>
					<Skeleton containerClassName={style.skeletonFullWidth} height={100} borderRadius={25} />
					<Skeleton containerClassName={style.skeletonFullWidth} height={100} borderRadius={25} />
				</div>
			</div>
		);
	}

	const getOnDeleteSlotsLine = (indexToDelete: number, params: { softDelete?: boolean }) => {
		const { softDelete } = params || {};
		setFieldTouched("workingTime.slots", true);

		if (softDelete) {
			setFieldValue(
				"workingTime.slots",
				workingTime.slots.map((slotValue, index) =>
					index === indexToDelete ? { startHour: null, endHour: null } : slotValue
				)
			);
			return;
		}

		setFieldValue(
			"workingTime.slots",
			workingTime.slots.filter((_, index) => index !== indexToDelete)
		);
	};

	return (
		<div>
			<div className={style.workPeriodType}>
				<TabSwitch
					minTabWidth="7.5rem"
					tabs={tabs.map((tab) => tab.label)}
					activeTab={currentTab}
					onTabChange={handleTabChange}
				/>
			</div>
			<div
				className={cn([
					style.dateRangeWrapper,
					showFullSize || showOneColumn ? style.horizontalDateRange : style.verticalDateRange,
				])}
			>
				<div className={style.dateRangeContent}>
					<DateRangeInput
						label={t("commands.periodForm.start")}
						value={period.startDate ? moment(period.startDate).format(DATE_FORMAT_STRING) : ""}
						onClick={async () => {
							setFieldTouched("period.startDate", true);
							setFocusedInputName(START_DATE_INPUT_NAME);
						}}
						onClear={(e) => handleClearPeriodDate(START_DATE_INPUT_NAME, e)}
						className={cn([style.fieldStartDate, style.dateRangeField])}
						isActive={focusedInputName === START_DATE_INPUT_NAME}
						error={getStartDateRangeInputErrorMessage(touched, errors)}
					/>
					<DateRangeInput
						label={t("commands.periodForm.end")}
						value={period.endDate ? moment(period.endDate).format(DATE_FORMAT_STRING) : ""}
						onClick={async () => {
							setFieldTouched("period.endDate", true);
							setFocusedInputName(END_DATE_INPUT_NAME);
						}}
						onClear={(e) => handleClearPeriodDate(END_DATE_INPUT_NAME, e)}
						className={cn([style.dateRangeField, style.fieldEndDate])}
						isActive={focusedInputName === END_DATE_INPUT_NAME}
						error={getEndDateRangeInputErrorMessage(touched, errors)}
					/>
					{isPeriodError(touched, errors) && (
						<TooltipMessage className={cn([style.centerTooltip, style.dateTooltip])} color="error">
							{errors.period.startDate || errors.period.endDate}
						</TooltipMessage>
					)}
					<DateRangePicker
						className={style.datePickerWrapper}
						// @ts-ignore
						dateFrom={moment(period.startDate || new Date())
							.startOf("day")
							.add(12, "hours")}
						// @ts-ignore
						dateTo={period.endDate ? moment(period.endDate) : null}
						focusedInput={focusedInputName.replace("period.", "")}
						setFocusedInput={(reactDatesInputName) =>
							setFocusedInputName(reactDatesInputName ? `period.${reactDatesInputName}` : "")
						}
						onDatesChange={handleDateRangeUpdate}
						keepOpenOnDateSelect
						isVisible={focusedInputName !== ""}
						isDayBlocked={() => false}
						setIsVisible={() => setFocusedInputName("")}
					/>
				</div>
				{/* Jours travaillés */}
				<div
					className={cn([
						style.daysSelectorGroup,
						showFullSize || showOneColumn ? null : style.verticalDaysSelector,
					])}
				>
					<DaysSelector
						className={cn([style.daysSelector])}
						displayDays={TRAD_DAYS}
						lockedDays={[startDateDayIndex]}
						lockedDaysMessage={t("commands.lockedDayMessage")}
						selectedIndexDays={workingDaysToIndexesArray || []}
						selectAllLabel={
							areAllDaysSelected ? t("commands.unSelectAllDaysAction") : t("commands.selectAllDaysAction")
						}
						onDayClick={handleDayClick}
						onSelectAllClick={null}
						isNightPeriod={IS_NIGHT}
					/>
					{checkWorkDaysSelectedAreOutOfPeriod(values) === true && (
						<TooltipMessage className={cn([style.centerTooltip])} color="warning" arrow={false}>
							{t("commands.daysSelectedDontMatch")}
						</TooltipMessage>
					)}
				</div>
			</div>
			{/* Working hours */}
			<div
				className={cn([
					style.periodWrapper,
					showFullSize ? style.showFullSizePeriod : style.periodVerticalWrapper,
				])}
			>
				<WorkHoursTimeSlots
					singleRange={IS_NIGHT}
					errors={isWorkingTimeOnError(touched, errors)}
					slotsConfig={{
						popoverLabels: {
							delete: t("commands.periodForm.delete"),
							add: t("commands.periodForm.add"),
							clear: t("commands.periodForm.clear"),
						},
						names: {
							start: "startHour",
							end: "endHour",
						},
						labels: {
							start: null,
							end: null,
						},
						menuStartTime: {
							start: IS_NIGHT ? "16:00" : "05:00",
							end: IS_NIGHT ? "05:00" : "16:00",
						},
						placeholders: {
							start: IS_NIGHT ? "--:--*" : "--:--",
							end: IS_NIGHT ? "--:--*" : "--:--",
						},
					}}
					showSeparators={showOneColumn || !showFullSize}
					direction={showFullSize || showOneColumn ? "horizontal" : "vertical"}
					onChange={(e: { name: string; value: object }) => handleChangeWorkHours(e)}
					onNewSlotsLine={(defaultSlotValues: { startHour: null; endHour: null }) =>
						setFieldValue("workingTime.slots", workingTime.slots.concat(defaultSlotValues))
					}
					onDeleteSlotsLine={getOnDeleteSlotsLine}
					values={
						workingTime.slots.length
							? workingTime.slots
							: commandPeriodFormDefaultValues?.workingTime?.slots
					}
					className={style.workHoursTime}
				/>
				{showWorkingTimeTooltipError(touched, errors) && (
					<TooltipMessage
						className={cn([
							style.centerTooltip,
							style.dateTooltip,
							!showOneColumn && style.verticalPeriodTooltip,
						])}
						color="error"
					>
						{getWorkingTimeErrorMessage(errors)}
					</TooltipMessage>
				)}
			</div>

			{/* Horaires particuliers - Période non travaillée */}
			{type === COMMAND_TYPE_INTERIM ? (
				<div className={style.comments}>
					<Textarea
						className={style.textarea}
						name="workingTime.particularWorkTime"
						label={t("commands.periodForm.particularWorkTimeTextAreaLabel")}
						maxLength={120}
						shouldDisplayErrorMessage={false}
					/>
					<Textarea
						className={style.textarea}
						name="workingTime.periodNotWorking"
						label={t("commands.periodForm.periodNotWorkingTextAreaLabel")}
						maxLength={40}
						shouldDisplayErrorMessage={false}
					/>
				</div>
			) : null}
		</div>
	);
};

export default CommandTimeFormBlock;
