// @ts-nocheck
import { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { SelectAsyncV2, Tag, Picto, ModalV2 } from "@zolteam/react-ras-library";
import { useTranslation } from "react-i18next";
import ModalContentMatchingFilter from "../../../views/Command/Matching/modals/modalContentMatchingFilter/ModalContentMatchingFilter";

// Components
import ActiveFiltersCount from "../ActiveFiltersCount/ActiveFiltersCount";

// Hooks
import { useMatchingContext } from "../../../store/MatchingContext";
import useOrderData from "../../../hooks/useOrderData";
import useFilterState from "../../../hooks/useFilterState";

// Services
import { getAgencies } from "../../../services/commandService";
import { getAvailableFilters } from "src/services/availableFiltersService";

// Style
import style from "./MatchingHeader.module.css";

// Constants
import { MATCHING_ERRORS } from "../../../constants";

const SMALL_SCREEN_WIDTH = 1440;

const MatchingHeader = () => {
	const { t } = useTranslation();
	const [{ filters, searchResult, filterMatchingModalOpen, hasFilters, wideLoading }, dispatchMatching] =
		useMatchingContext();
	const { orderId } = useParams();
	const { agencyData, isOrderFetched, isUser24by7, hasCommonQualificationId, commonQualificationDetails, orderData } =
		useOrderData(orderId);
	const { updateAgencyFilter, checkForFilters } = useFilterState(orderId);

	// State is needed to check if the user a attached to an single agency
	// Only used if the user is not in the 24by7 group
	const [uniqueAgency, setUniqueAgency] = useState(true);
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < SMALL_SCREEN_WIDTH);

	const { startDate: orderStartDate, endDate: orderEndDate } = orderData?.data?.period ?? {};
	const { startDate: filtersStartDate, endDate: filtersEndDate } = filters?.period ?? {};

	const isGeolocation = useMemo(() => {
		const { latitude, longitude } = orderData?.data?.workAddress || {};

		return !!latitude && !!longitude;
	}, [orderData?.data]);

	const updateScreenWidth = () => {
		setIsSmallScreen(window.innerWidth < SMALL_SCREEN_WIDTH);
	};

	useEffect(() => {
		window.addEventListener("resize", updateScreenWidth);
		return () => {
			window.removeEventListener("resize", updateScreenWidth);
		};
	}, [window.innerWidth]);

	const getAgenciesOptions = (agencies) => {
		const optionsList = agencies
			?.map((a) => ({
				id: a.tempoFolderName,
				label: a.name?.replace("RAS ", ""),
				agencyId: a.id,
			}))
			.sort((a, b) => a.label.localeCompare(b.label));

		if (optionsList.length === 1) {
			setUniqueAgency(optionsList[0]);
		} else {
			setUniqueAgency(false);
		}

		const user24by7Options = isUser24by7
			? [
					{
						label: "Toutes",
						id: "all",
					},
				]
			: [];

		return [...user24by7Options, ...optionsList];
	};

	const { data: defaultAgenciesData } = useQuery(
		"defaultAgencies",
		async () => {
			const agencies = await getAgencies("");

			return getAgenciesOptions(agencies?.data);
		},
		{
			staleTime: Infinity,
			placeholderData: [],
		}
	);

	const isCustomPeriod = useMemo(() => {
		if (orderStartDate === null || filtersStartDate === null || orderEndDate === null || filtersEndDate === null) {
			return false;
		}
		return orderStartDate !== filtersStartDate || orderEndDate !== filtersEndDate;
	}, [orderStartDate, filtersStartDate, orderEndDate, filtersEndDate]);

	useQuery(["availableFilters"], () => getAvailableFilters());

	const activeFiltersCount = useMemo(() => {
		const conditions = [
			filters?.qualifications?.length !== 0 || 0,
			filters?.commonDocuments?.length !== 0 || 0,
			filters?.statuses?.length !== 0 || 0,
			filters?.equipments?.length !== 0 || 0,
			filters?.vehicles?.length !== 0 || 0,
			filters?.isOutOfBusiness === true,
			filters?.isMedicalCheckupValid === true,
			filters?.candidate || filters?.worker,
			isCustomPeriod,
			// a null value means that the user selected the max value, which disables the filter
			filters?.distance !== null && isGeolocation,
			filters.isTempWorkerOrderFilterEnabled === true ||
				filters.isTempWorkerAvailabilitiesFilterEnabled === true ||
				filters.isTempWorkerTimeOffFilterEnabled === true ||
				filters.isTempWorkerContractFilterEnabled === true,
			filters.isAdequateForClient || filters.hasAlreadyWorkedForClient,
		];
		const total = conditions.reduce((acc, condition) => acc + (condition ? 1 : 0));
		return total;
	}, [filters, filters?.qualifications]);

	const agenciesOptionsAsync = (value) => {
		if (value.length < 3) return Promise.resolve(defaultAgenciesData);
		return getAgencies(value.length >= 3 ? value : "").then((res) => getAgenciesOptions(res?.data));
	};

	const [agencyValue, setAgencyValue] = useState(null);

	// first source of logic comes from filters.agency, then from agencyData (default agency)
	useEffect(() => {
		if (defaultAgenciesData.length < 1) return () => {};
		if (filters.agency) {
			const agency = defaultAgenciesData.find((a) => a.id === filters.agency);

			if (agency) {
				setAgencyValue({
					label: agency?.label?.replace("RAS ", ""),
					value: agency?.label,
				});
			}
		} else if (agencyData) {
			setAgencyValue({
				label: agencyData?.name?.replace("RAS ", ""),
				value: agencyData?.name,
			});
		}
	}, [agencyData, filters.agency, defaultAgenciesData]);

	// commonQualification initial value
	useEffect(() => {
		if (isOrderFetched && hasCommonQualificationId) {
			dispatchMatching({
				type: "SET_QUALIFICATION_VALUE",
				payload: commonQualificationDetails?.id,
			});
		}
	}, [isOrderFetched, hasCommonQualificationId]);

	useEffect(() => {
		if (isOrderFetched) {
			if (hasCommonQualificationId === false && hasFilters === false) {
				if (filters?.qualifications?.length > 0) return;
				dispatchMatching({
					type: "SET_EMPTY_SECTION",
					payload: {
						isDisplayed: true,
						sectionType: MATCHING_ERRORS.NO_COMMON_QUALIFICATION,
					},
				});
				dispatchMatching({ type: "HIDE_WIDE_LOADING" });
			}
		}
	}, [isOrderFetched, hasCommonQualificationId, filters]);

	useEffect(() => {
		checkForFilters();
	}, [filters, orderData, filterMatchingModalOpen, wideLoading?.isDisplayed]);

	const toggleFilterMatchingModal = (isOpen) => {
		dispatchMatching({
			type: "SET_FILTER_MATCHING_MODAL_OPEN",
			payload: isOpen,
		});
	};

	return (
		<div className={style.wrapper}>
			<div className={style.leftPart}>
				<h1 className={style.title}>{t("matching.title")}</h1>
				<Tag className={style.totalResult} color="grey">
					{isSmallScreen ? (
						searchResult || 0
					) : (
						<span>{t("matching.totalResult", { count: searchResult || 0 })}</span>
					)}
				</Tag>
			</div>
			<div className={style.rightPart}>
				<span
					onClick={() => toggleFilterMatchingModal(true)}
					onKeyDown={() => toggleFilterMatchingModal(true)}
					tabIndex="0"
					role="button"
					className={style.filterAction}
					aria-label="filter matching results"
				>
					<Picto icon="sliders" className={style.picto} />
					<ActiveFiltersCount count={activeFiltersCount} />
				</span>
				<span className={isSmallScreen ? style.agencySelectSmall : style.agencySelect}>
					<SelectAsyncV2
						className={isSmallScreen ? style.bare : null}
						key="agencyFilter"
						name="agencyName"
						label={isSmallScreen ? null : t("commands.agency")}
						disabled={uniqueAgency && !isUser24by7}
						cacheOptions
						defaultOptions={defaultAgenciesData}
						promiseOptions={agenciesOptionsAsync}
						onChange={(agencySelected) => {
							if (agencySelected?.id === filters?.agency) return;
							updateAgencyFilter(agencySelected);
						}}
						value={agencyValue}
						getOptionLabel={(option) => option.label}
						getOptionValue={(option) => option.id}
						noOptionsMessage={() => null}
						loadingMessage={() => t("commands.selectLoadingAgencies")}
						components={
							uniqueAgency && !isUser24by7
								? {
										DropdownIndicator: () => null,
									}
								: null
						}
					/>
				</span>
			</div>

			<ModalV2 isDisplayed={filterMatchingModalOpen} onClose={() => toggleFilterMatchingModal(false)} size="m">
				<ModalContentMatchingFilter />
			</ModalV2>
		</div>
	);
};

export default MatchingHeader;
