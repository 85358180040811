// @ts-nocheck

import EXTRACT_FORMAT from "src/constants/extractFormat";
import EXTRACT_PERIMETER from "src/constants/extractPerimeter";

const commandsExtractFormDefaultValues = {
	extractPerimeter: EXTRACT_PERIMETER.DISPLAYED,
	format: EXTRACT_FORMAT.EXCEL,
};
export default commandsExtractFormDefaultValues;
