// @ts-nocheck
import { Picto, ModalV2 } from "@zolteam/react-ras-library";
import MatchingProfilItem from "../../molecules/MatchingProfilItem/MatchingProfilItem";
import { useTranslation } from "react-i18next";

// components
import ModalContentAddAllProfilesModal from "../../../views/Command/Matching/modals/modalContentAddAllProfiles/ModalContentAddAllProfilesModal";
import ModalContentcreateUserModal from "../../../views/Command/Matching/modals/modalContentCreateUser/ModalContentCreateUser";
import ModalContentCreateUserInterviewData from "src/views/Command/Matching/modals/modalContentCreateUserInterviewData/ModalContentCreateUserInterviewData";

// style
import style from "./MatchingResultList.module.css";

// Hooks
import useMatchingResultList from "./useMatchingResultList";

const MatchingResultList = () => {
	const {
		emptySection,
		handleScroll,
		handleProfileClick,
		handleTitleCorrespondence,
		handleAddAllProfilesModalClick,
		handleCreateUserModalClick,
		handleCreateUserInterviewModalClick,
		selectableProfilesNumber,
		concatenatedProfiles,
		isFetchingNextPage,
		agencyData,
		clickedProfile,
		toggleModal,
		modalStates,
		setModalStates,
	} = useMatchingResultList();
	const { t } = useTranslation();

	if (emptySection?.isDisplayed) return null;

	return (
		<div className={style.boxwrapper}>
			{selectableProfilesNumber >= 10 ? (
				<>
					<div
						className={style.addAllButton}
						onClick={() => toggleModal("addAllProfiles", true, setModalStates)}
						onKeyDown={() => toggleModal("addAllProfiles", true, setModalStates)}
						role="button"
						tabIndex={0}
					>
						<Picto className={style.addAllButtonPicto} icon="plus" />
						<span>{t("matching.addAll")}</span>
					</div>
					<hr className={style.separator} />
				</>
			) : null}
			<div
				className={selectableProfilesNumber >= 10 ? style.wrapperWithButton : style.wrapper}
				onScroll={handleScroll}
			>
				{concatenatedProfiles?.map(
					({
						id,
						title,
						firstName,
						lastName,
						vipAgencies,
						cdiInterimAgencies,
						orderProfileStatus,
						outOfBusinessReasons,
						hasNationality,
						isProfileLoading,
						hasUserOnReferenceAgencyOrder,
						hasResidentPermit,
						hasValidMedicalCheckup,
					}) => (
						<MatchingProfilItem
							key={id}
							title={handleTitleCorrespondence(title)}
							firstName={firstName}
							lastName={lastName}
							vipAgencies={vipAgencies}
							cdiInterimAgencies={cdiInterimAgencies}
							orderProfileStatus={orderProfileStatus}
							handleProfileClick={handleProfileClick}
							id={id}
							outOfBusinessReasons={outOfBusinessReasons}
							hasNationality={hasNationality}
							hasResidentPermit={hasResidentPermit}
							isLoading={isProfileLoading}
							hasUserOnReferenceAgencyOrder={hasUserOnReferenceAgencyOrder}
							hasValidMedicalCheckup={hasValidMedicalCheckup}
						/>
					)
				)}
				{isFetchingNextPage && (
					<div className={style.loadingBox}>
						<span className={style.loader} />
					</div>
				)}
			</div>
			<ModalV2
				size="s"
				isDisplayed={modalStates.addAllProfiles}
				onClose={() => toggleModal("addAllProfiles", false, setModalStates)}
			>
				<ModalContentAddAllProfilesModal
					selectableProfilesNumber={selectableProfilesNumber}
					onCancelClick={() => toggleModal("addAllProfiles", false, setModalStates)}
					onConfirmClick={(profilesCount) => handleAddAllProfilesModalClick(profilesCount)}
				/>
			</ModalV2>
			<ModalV2
				size="s"
				isDisplayed={modalStates.createUser}
				onClose={() => toggleModal("createUser", false, setModalStates)}
			>
				<ModalContentcreateUserModal
					agencyName={agencyData?.name ?? null}
					onCancelClick={() => toggleModal("createUser", false, setModalStates)}
					onConfirmClick={() => handleCreateUserModalClick()}
				/>
			</ModalV2>
			<ModalV2
				size="s"
				isDisplayed={modalStates.blockCreateUser}
				onClose={() => toggleModal("blockCreateUser", false, setModalStates)}
			>
				<div className={style.content}>
					<p className={style.title}>{t("matching.blockCreateUserModal.title")}</p>
					<div className={style.textContent}>
						<p className={style.modalMessage}>{t("matching.blockCreateUserModal.infoMessage")}</p>
					</div>
					<p className={style.modalMessage}>{t("matching.blockCreateUserModal.confirmMessage")}</p>
				</div>
			</ModalV2>
			<ModalV2
				size="s"
				isDisplayed={modalStates.createUserInterviewData}
				onClose={() => toggleModal("createUserInterviewData", false, setModalStates)}
			>
				<ModalContentCreateUserInterviewData
					onCancelClick={() => toggleModal("createUserInterviewData", false, setModalStates)}
					onConfirmClick={(agencyId) => handleCreateUserInterviewModalClick(agencyId)}
					unifiedTempWorkerId={clickedProfile}
				/>
			</ModalV2>
		</div>
	);
};

export default MatchingResultList;
