// @ts-nocheck
import * as Yup from "yup";
import i18n from "../../../i18n";
import isOverlapWithWorkingDays from "../../../utils/matching/isOverlapWithWorkingDays";

const matchingValidationForm = (orderData) =>
	Yup.object().shape({
		// BUSINESS INFORMATIONS FILTERS
		distance: Yup.number()
			.min(10, i18n.t("commands.error.radiusTooLow"))
			.max(100, i18n.t("commands.error.radiusTooHigh"))
			.nullable(true),
		statuses: Yup.array().of(Yup.string().oneOf(["worker", "candidate"], i18n.t("commands.error.invalidStatus"))),
		outOfBusinessReasonsIds: Yup.array().of(Yup.number().nullable(i18n.t("commands.error.invalidStatus"))),
		equipments: Yup.array().of(Yup.number()),
		qualifications: Yup.array()
			.of(
				Yup.object().shape({
					id: Yup.number().required(i18n.t("matching.error.invalid")),
					name: Yup.string().required(i18n.t("matching.error.invalid")),
				})
			)
			.test("isAnArrayOfNumber", i18n.t("matching.error.invalid"), (value) => value instanceof Array),
		isMedicalCheckupValid: Yup.boolean(),
		isAdequateForClient: Yup.boolean(),
		hasAlreadyWorkedForClient: Yup.boolean(),
		commonDocuments: Yup.array().of(
			Yup.object().shape({
				id: Yup.number().required(i18n.t("commands.error.qualificationIdRequired")),
				name: Yup.string().required(i18n.t("commands.error.qualificationNameRequired")),
			})
		),
		period: Yup.object()
			.shape({
				startDate: Yup.date().nullable().required(i18n.t("commands.error.mandatoryStartDateField")),
				endDate: Yup.date().nullable().required(i18n.t("commands.error.mandatoryEndDateField")),
			})
			.test("isOverlapWithWorkingDays", i18n.t("commands.error.noOverlapWorkingDays"), (values, context) => {
				const isPeriod = [
					context.parent.isTempWorkerAvailabilitiesFilterEnabled,
					context.parent.isTempWorkerOrderFilterEnabled,
					context.parent.isTempWorkerTimeOffFilterEnabled,
					context.parent.isTempWorkerContractFilterEnabled,
				].every((value) => value === false);
				if (isPeriod) return true;
				return isOverlapWithWorkingDays(
					orderData?.data?.workingTime?.workingDays,
					values.startDate,
					values.endDate
				);
			}),
	});

export default matchingValidationForm;
