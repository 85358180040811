import { Button } from "@zolteam/react-ras-library";
import { FC } from "react";
import { t } from "i18next";

import style from "./FilterCardHeader.module.css";

interface IFilterCardHeaderProps {
	title: string;
	handleReset?: () => void;
}

const FilterCardHeader: FC<IFilterCardHeaderProps> = ({ title, handleReset }) => (
	<div className={style.container}>
		<p className={style.filterTitle}>{title}</p>
		{handleReset && (
			<Button size="s" type="button" color="transparentPrimary" onClick={handleReset}>
				{t("global.filters.reinit")}
			</Button>
		)}
	</div>
);

export default FilterCardHeader;
