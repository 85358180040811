import React, { useCallback } from "react";
import { Button, Title, SelectV2, Tooltip } from "@zolteam/react-ras-library";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormikContext } from "formik";
import commandsExtractFormDefaultValues from "./commandsExtractFormDefaultValues";
import validationSchema from "./commandsExtractFormValidation";
// Style
import style from "./ModalContentCommandsExtract.module.css";
// Types
import {
	Option,
	ModalContentCommandsFormWrapperProps,
	ModalContentCommandsExtractProps,
	FormValues,
} from "./ModalContentCommandsExtract.types";
// Constants
import EXTRACT_FORMAT from "src/constants/extractFormat";
import EXTRACT_PERIMETER from "src/constants/extractPerimeter";

const ModalContentCommandsExtract: React.FC<ModalContentCommandsExtractProps> = ({
	onConfirmClick,
	onCancelClick,
	hasAtLeastOneSelected,
}) => {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<FormValues>();

	const extractPerimeterOptions: Option[] = [
		{
			label: t("commands.extractModal.displayedLabel"),
			value: EXTRACT_PERIMETER.DISPLAYED,
		},
		{
			label: t("commands.extractModal.selectedLabel"),
			value: EXTRACT_PERIMETER.SELECTED,
		},
		{
			label: t("commands.extractModal.allLabel"),
			value: EXTRACT_PERIMETER.ALL,
		},
	];

	const formatOptions: Option[] = [
		{
			label: "Excel",
			value: EXTRACT_FORMAT.EXCEL,
		},
		{
			label: "CSV",
			value: EXTRACT_FORMAT.CSV,
		},
	];

	const profileOptionLabel = useCallback(
		(option: Option) =>
			option.value === EXTRACT_PERIMETER.SELECTED && !hasAtLeastOneSelected ? (
				<Tooltip
					animation={false}
					arrow={false}
					placement="top"
					content={t(t("commands.extractModal.noOrderSelectedTooltipMessage"))}
				>
					<div key={option.value}>{option.label}</div>
				</Tooltip>
			) : (
				<div key={option.value}>{option.label}</div>
			),
		[hasAtLeastOneSelected]
	);

	return (
		<div className={style.wrapper}>
			<Title tag="h1" size="heading01" lineHeight="l" className={style.title}>
				{t("commands.extractModal.title")}
			</Title>
			<div className={style.body}>
				<SelectV2
					options={extractPerimeterOptions}
					onChange={(selected: Option) => {
						setFieldValue("extractPerimeter", selected.value);
					}}
					name="extractPerimeter"
					label={t("commands.extractModal.extractPerimeterLabel")}
					getOptionLabel={(option: Option) => profileOptionLabel(option)}
					placeholder={t("commands.extractModal.extractPerimeterLabel")}
					value={
						extractPerimeterOptions.find(
							(extractPerimeter) => extractPerimeter.value === values.extractPerimeter
						)?.value || null
					}
					isClearable={false}
					isSearchable={false}
					isOptionDisabled={(option: Option) =>
						option.value === EXTRACT_PERIMETER.SELECTED && !hasAtLeastOneSelected
					}
				/>
				<SelectV2
					options={formatOptions}
					onChange={(selected: Option) => {
						setFieldValue("format", selected.value);
					}}
					name="format"
					label={t("commands.extractModal.formatLabel")}
					placeholder={t("commands.extractModal.formatLabel")}
					value={formatOptions.find((format) => format.value === values.format)?.value || null}
					isClearable={false}
					isSearchable={false}
				/>
			</div>
			<hr className={style.separator} />
			<div className={style.buttonsBlock}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					outline
				>
					{t("commands.extractModal.cancelAction")}
				</Button>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={() => onConfirmClick(values)}
				>
					{t("commands.extractModal.confirmAction")}
				</Button>
			</div>
		</div>
	);
};

const ModalContentCommandsFormWrapper: React.FC<ModalContentCommandsFormWrapperProps> = ({
	onConfirmClick,
	onCancelClick,
	hasAtLeastOneSelected,
}) => (
	<div className={style.container}>
		<Formik
			initialValues={{
				...commandsExtractFormDefaultValues,
				extractPerimeter: hasAtLeastOneSelected ? EXTRACT_PERIMETER.SELECTED : EXTRACT_PERIMETER.DISPLAYED,
			}}
			validationSchema={validationSchema}
			onSubmit={() => {}}
		>
			{() => (
				<Form>
					<ModalContentCommandsExtract
						onConfirmClick={onConfirmClick}
						onCancelClick={onCancelClick}
						hasAtLeastOneSelected={hasAtLeastOneSelected}
					/>
				</Form>
			)}
		</Formik>
	</div>
);

export default ModalContentCommandsFormWrapper;
