// @ts-nocheck
import { t } from "i18next";
import { formatDate } from "./formatDate";
import { FAMILY_SITUATIONS } from "../constants";

export const isFrenchOrNullCountry = (country) =>
	country === null || country === undefined || country === "" || country === "FRANCE";

export const ADDRESS_PROPERTIES = ["addressCity", "addressCountry", "addressPostalCode", "addressInseeCode"];

export const getPropertyForAllAgencies = (property, users) =>
	users.map((user) => ({
		value: user[property],
		mainLocation: user?.mainLocation,
		currentUser: user,
	}));

export const isTheSameForAll = (property, users) => {
	const allUsers = getPropertyForAllAgencies(property, users);
	let isTheSame = true;
	for (let i = 0; i < allUsers?.length; i += 1) {
		const unifiedTemporaryWorker = allUsers[0];
		const userLowerCaseValue = typeof allUsers[i]?.value === "string" ? allUsers[i]?.value.toLowerCase() : null;
		const unifiedTempWorkerLowerCaseValue =
			typeof unifiedTemporaryWorker?.value === "string" ? unifiedTemporaryWorker?.value.toLowerCase() : null;

		switch (property) {
			case "firstName":
			case "lastName": {
				if (allUsers[i]?.value?.toLowerCase() !== unifiedTemporaryWorker?.value?.toLowerCase()) {
					isTheSame = false;
				}
				break;
			}
			case "dateOfBirth":
			case "childrenQty": {
				if (allUsers[i]?.value !== unifiedTemporaryWorker?.value) {
					isTheSame = false;
				}
				break;
			}
			case "isDfsAccepted": {
				if (`${allUsers[i]?.value}` !== `${unifiedTemporaryWorker?.value}`) {
					isTheSame = false;
				}
				break;
			}
			case "addressCity":
				if (
					isFrenchOrNullCountry(allUsers[i].currentUser.addressCountry) &&
					isFrenchOrNullCountry(unifiedTemporaryWorker.currentUser.addressCountry)
				) {
					const userLocation = allUsers[i].mainLocation.city || userLowerCaseValue || "";
					// eslint-disable-next-line max-len
					const unifiedTempWorkerLocation =
						unifiedTemporaryWorker.mainLocation.city || unifiedTempWorkerLowerCaseValue || "";
					isTheSame = userLocation === unifiedTempWorkerLocation;
					break;
				}
				if (userLowerCaseValue !== unifiedTempWorkerLowerCaseValue) {
					isTheSame = false;
				}
				break;
			case "addressPostalCode":
				if (
					isFrenchOrNullCountry(allUsers[i].currentUser.addressCountry) &&
					isFrenchOrNullCountry(unifiedTemporaryWorker.currentUser.addressCountry)
				) {
					const userLocation = allUsers[i].mainLocation.postalCode || userLowerCaseValue || "";
					// eslint-disable-next-line max-len
					const unifiedTempWorkerLocation =
						unifiedTemporaryWorker.mainLocation.postalCode || unifiedTempWorkerLowerCaseValue || "";
					isTheSame = userLocation === unifiedTempWorkerLocation;
					break;
				}
				isTheSame = userLowerCaseValue === unifiedTempWorkerLowerCaseValue;
				break;
			case "addressInseeCode": {
				if (
					isFrenchOrNullCountry(allUsers[i].currentUser.addressCountry) &&
					isFrenchOrNullCountry(unifiedTemporaryWorker.currentUser.addressCountry)
				) {
					const userLocation = allUsers[i].mainLocation.inseeCode || userLowerCaseValue || "";
					// eslint-disable-next-line max-len
					const unifiedTempWorkerLocation =
						unifiedTemporaryWorker.mainLocation.inseeCode || unifiedTempWorkerLowerCaseValue || "";
					isTheSame = userLocation === unifiedTempWorkerLocation;
					break;
				}
				isTheSame = userLowerCaseValue === unifiedTempWorkerLowerCaseValue;
				break;
			}
			default: {
				if (userLowerCaseValue !== unifiedTempWorkerLowerCaseValue) {
					isTheSame = false;
				}
				break;
			}
		}
	}
	return isTheSame;
};

// TODO maybe make condition on name here ?
export const getSuspiciousCheckboxValue = ({ property, userValue, suspiciousSheetForm }) =>
	userValue === suspiciousSheetForm?.temporaryWorker?.[property];

export const getValueToSet = (property, worker) => {
	if (ADDRESS_PROPERTIES.includes(property)) {
		return {
			mainLocation: worker?.mainLocation,
			addressCity: worker?.addressCity,
			addressInseeCode: worker?.addressInseeCode,
			addressPostalCode: worker?.addressPostalCode,
			addressCountry: worker?.addressCountry,
		};
	}
	return worker[property];
};

export const setSuspiciousCheckboxValue = ({ property, value, appDispatch, suspiciousSheetForm }) => {
	if (ADDRESS_PROPERTIES.includes(property)) {
		return appDispatch({
			type: "SET_SUSPICIOUS_FORM_WORKER_VALUES",
			payload: {
				...suspiciousSheetForm?.temporaryWorker,
				mainLocation: value?.mainLocation,
				addressPostalCode: value?.addressPostalCode,
				addressCity: value?.addressCity,
				addressInseeCode: value?.addressInseeCode,
				addressCountry: value?.addressCountry,
			},
		});
	}
	return appDispatch({
		type: "SET_SUSPICIOUS_FORM_WORKER_VALUES",
		payload: { ...suspiciousSheetForm?.temporaryWorker, [property]: value },
	});
};

export const formatSuspiciousFormFields = (property, value, workerData) => {
	switch (property) {
		case "sex":
		case "title": {
			return value ? t(`suspiciousSheet.workerInfo.${value}`) : "";
		}
		case "dateOfBirth": {
			return value ? formatDate(value, "dd/MM/yyyy") : "";
		}
		case "familySituation": {
			return value ? t(`global.familySituation.${FAMILY_SITUATIONS[value]}`) : "";
		}
		case "isDfsAccepted": {
			return `${value}` ? t(`global.isDfsAccepted.${value}`) : "";
		}
		case "addressPostalCode":
			if (isFrenchOrNullCountry(workerData?.addressCountry)) {
				return workerData?.mainLocation?.postalCode || value || "";
			}
			return value || "";
		case "addressInseeCode":
			if (isFrenchOrNullCountry(workerData?.addressCountry)) {
				return workerData?.mainLocation?.inseeCode || value || "";
			}
			return value || "";
		case "addressCity":
			if (isFrenchOrNullCountry(workerData?.addressCountry)) {
				return workerData?.mainLocation?.city || value || "";
			}
			return value || "";
		default: {
			return value;
		}
	}
};

export const getUnfilledFields = (temporaryWorker, suspiciousFormTotalFields) =>
	suspiciousFormTotalFields?.reduce((acc, property) => {
		if (temporaryWorker?.[property] !== undefined) {
			return acc + 1;
		}
		return acc;
	}, 0);

export const isDissociated = (userId, suspiciousSheetForm) => suspiciousSheetForm?.dissociatedUsers?.includes(userId);

export const removeDissociatedUserValuesFromForm = ({ dissociatedUserIds, worker, users }) => {
	if (!dissociatedUserIds?.length || !worker) {
		return worker;
	}
	const dissociatedUsers = users?.filter(({ id }) => dissociatedUserIds?.includes(id));
	const notDissociatedUsers = users?.filter(({ id }) => !dissociatedUserIds?.includes(id));
	return Object.entries(worker)?.reduce((acc, [property, value]) => {
		const isPartOfDissociatedUsers = dissociatedUsers?.some((user) => user?.[property] === value);
		if (isPartOfDissociatedUsers) {
			const isParOfNotDissociatedUsers = notDissociatedUsers?.some((user) => user?.[property] === value);
			if (isParOfNotDissociatedUsers) {
				return { ...acc, [property]: value };
			}
			return acc;
		}
		return { ...acc, [property]: value };
	}, {});
};

export const checkSuspiciousValue = (value) => {
	if (value || value === 0 || value === null || value === false) {
		return true;
	}

	return false;
};
