import client from "src/services/client";

// Types
import { SearchQueryBaseParamsType, OrderByType } from "src/types/SearchQueryTypes";

export interface UnifiedTempWorkerOrdersSearchItemInterface {
	orderId: number;
	orderStatus: string;
	orderTempoId: NullableString;
	orderType: string;
	clientSocialReason: string;
	clientQualificationName: string;
	orderStartDate: string;
	orderEndDate: string;
	agencyName: string;
	orderProfileStatus: string;
	canEditOrder: boolean;
}

export interface UnifiedTempWorkerOrdersSearchApiResponse {
	total: number;
	items: UnifiedTempWorkerOrdersSearchItemInterface[];
}

export type UnifiedTempWorkerOrdersQueryParamsType = SearchQueryBaseParamsType & {
	orderBy: OrderByType;
};

export const postUnifiedTempWorkerOrdersSearch = (
	unifiedTempWorkerId: string,
	params: UnifiedTempWorkerOrdersQueryParamsType
): Promise<AxiosResponse<UnifiedTempWorkerOrdersSearchApiResponse>> =>
	client.post(`/api-client-v2/unified-temp-workers/${unifiedTempWorkerId}/orders/search`, params);
