// Types
import ProfileStatusesType from "./orderProfiles/profileStatuses";
import OrderStatusesType from "./order/orderStatuses";
export * from "./CNotifications";

export const API_URL = process.env.REACT_APP_API_URL;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const POSTMAN_MOCK_URL = process.env.REACT_APP_POSTMAN_MOCK_URL;
export const POSTMAN_SERVER_MOCK_KEY = process.env.REACT_APP_POSTMAN_SERVER_MOCK_KEY;

export const V1_TOKEN_COOKIE = `token_myDaily_${process.env.REACT_APP_ENV}_v1`;
export const PAGE_TITLE = "myDaily";

export const ROLES = {
	ADMIN: "admin",
	MANAGER: "manager",
};

export const OPTION_DATE = { weekStartsOn: 1 };
export const PAGINATION_OFFSET_OPTIONS = [
	{ id: 1, name: "40", value: 40 },
	{ id: 2, name: "80", value: 80 },
];

export const TOOLTIP_THRESHOLD = 20;

export const PROFILES_STATUS: Record<ProfileStatusesType, ProfileStatusesType> = {
	SELECTED: "SELECTED",
	CONTACTED: "CONTACTED",
	OFFER_ACCEPTED: "OFFER_ACCEPTED",
	OFFER_DECLINED: "OFFER_DECLINED",
	PROPOSED: "PROPOSED",
	VALIDATED: "VALIDATED",
	RECUSED: "RECUSED",
};

export const ACTIVITY_PROFILES_EVENTS = {
	statusUpdate: "STATUS_UPDATED",
	communicationSent: "COMMUNICATION_SENT",
	receivedResponse: "RESPONSE_RECEIVED",
};

export const COMMUNICATION_SENT = {
	share: ["SHARE_ORDER", "SEND_ORDER_MISSION"],
	send: ["REFUSE_PROFIL", "VALIDATE_PROFIL", "SEND_ORDER_UPDATED"],
};

export const ORDER_STATUS: Record<OrderStatusesType, OrderStatusesType> = {
	IN_PROGRESS: "IN_PROGRESS",
	IS_FILLED: "IS_FILLED",
	NOT_PROVIDED: "NOT_PROVIDED",
};

export const ORDER_CONTENT_BACKGROUND = {
	WHITE: "White",
	TRANSPARENT: "Transparent",
};

// Determine why we add ton concat the default option before
export const STATUS_OPTIONS = (Object.keys(PROFILES_STATUS) as ProfileStatusesType[])
	.filter((status) => status !== PROFILES_STATUS.RECUSED)
	.map((status: keyof typeof PROFILES_STATUS) => ({
		label: `commands.profiles.modalsContent.addProfile.statusOptionLabels.${PROFILES_STATUS[status]}`,
		value: PROFILES_STATUS[status],
	}));

export const ORDER_STATUS_IN_PROGRESS = "IN_PROGRESS";
export const ORDER_STATUS_STAND_BY = "STAND_BY";
export const ORDER_STATUS_IS_FILLED = "IS_FILLED";
export const ORDER_STATUS_NOT_PROVIDED = "NOT_PROVIED";

export const TITLE_MS = "MS";
export const TITLE_MR = "MR";

export const COMMAND_SECTION_TYPE = "type";
export const COMMAND_SECTION_AGENCY = "agency";
export const COMMAND_SECTION_CLIENT = "client";
export const COMMAND_SECTION_APPEAL_CASE = "appealCase";
export const COMMAND_SECTION_QUALIFICATION = "qualification";
export const COMMAND_SECTION_CONTRACT_TYPE = "contractTypes";
export const COMMAND_SECTION_RECRUITMENT_REASON = "recruitmentReason";
export const COMMAND_SECTION_DURATION_AND_WORKING_TIME = "durationAndWorkingTime";
export const COMMAND_SECTION_WORK_ADDRESS = "workAddress";
export const COMMAND_SECTION_MISSION = "mission";

export const CREATE_MODE = "create";
export const EDIT_MODE = "edit";
export const READ_MODE = "read";

export const COMMAND_CONTRACT_TYPE_CDD = 1;
export const COMMAND_CONTRACT_TYPE_CDI = 2;
export const COMMAND_CONTRACT_TYPE_INTERIM = 3;
export const COMMAND_CONTRACT_TYPE_INTERNSHIP = 4;
// TODO : voir pourquoi la valeur 'Alternance' n'existe pas en back: erreur ou evol ultérieure ?
// export const COMMAND_CONTRACT_TYPE_WORK_STUDY = ???;

export const COMMAND_TYPE_INTERIM = "orderInterim";
export const COMMAND_TYPE_INVESTMENT = "orderInvestment";
export const COMMAND_TYPE_VACATION = "orderVacation";

export const COMMAND_TYPE_NEW_ORDER = "newOrder";
export const COMMAND_TYPE_FORMATION = "formation";
export const COMMAND_TYPE_REPLACEMENT = "replacement";

export const COMMAND_PLACEMENT_CONFIDENTIAL = "isConfidential";

export const COMMAND_CLIENT_GESTION = "isManageClient";

export const COMMAND_CLIENT_BLOCKED = "IS_BLOCKED";
export const COMMAND_CLIENT_PROSPECT = "IS_PROSPECT";

export const SOURCE_MY_DAILY_API_V2 = "myDailyApiV2";
export const SOURCE_MY_DAILY = "common";
export const SOURCE_TEMPO = "tempo";
export const SOURCE_WEB = "web";
export const SOURCE_MY_SCAN = "myScan";
export const SOURCE_MY_RAS = "mobile";
export const SOURCE_MY_RAS_360 = "mobile";
export const SOURCE_IGS = "igs";
export const SOURCE_AUTOMATIC_FUSION = "Fusion automatique";

export const COMMAND_RECRUITMENT_REASON_OTHER = "other";
export const COMMAND_RECRUITMENT_REASONS = [
	{ value: "jobCreation", label: "commands.recruitmentReasons.jobCreation" },
	{ value: "retirement", label: "commands.recruitmentReasons.retirement" },
	{ value: "parental", label: "commands.recruitmentReasons.parental" },
	{ value: "sick", label: "commands.recruitmentReasons.sick" },
	{
		value: "activityIncrease",
		label: "commands.recruitmentReasons.activityIncrease",
	},
	{ value: "other", label: "commands.recruitmentReasons.other" },
];

export const DEFAULT_ADVANCE_PAYMENTS_LIST_OPTIONS = {
	limit: 40,
	page: 1,
	sorting: "STATUS",
	orderBy: "DESC",
};

export const COMMAND_BILLING_PACKAGES = {
	WEEKEND: "weekend",
	NIGHT: "night",
	1214: "1214",
};

export const COMMAND_QUALIFICATION_LEVELS = [
	{
		value: "Agent de maîtrise",
		label: "commands.qualificationLevels.agentControl",
	},
	{ value: "Cadre", label: "commands.qualificationLevels.seniorExecutive" },
	{ value: "Employé.e", label: "commands.qualificationLevels.employee" },
];

export const COMMAND_PERIOD_START_DATE_INPUT = "startDate";
export const COMMAND_PERIOD_END_DATE_INPUT = "endDate";
export const INDEX_DAYS = [1, 2, 3, 4, 5, 6, 7];

export const COMMAND_WORK_ADDRESS_MAIN_ADDRESS_LIBELLE = "Adresse principale";
export const COMMAND_WORK_ADDRESS_CUSTOM_ADDRESS_LIBELLE = "Adresse ponctuelle";
export const COMMAND_WORK_ADDRESS_OTHER_ADDRESS = "other";

export const ORDER_CREATION_INVALID_PARAMETERS_ERROR_CODE = "ERR_BR_ORDER_0003_INVALID_PARAMETERS";

export const WORKER_INFO_SECTION = [
	{
		identity: {
			isCategory: true,
		},
	},
	{
		sex: {
			isCategory: false,
		},
	},
	{
		title: {
			isCategory: false,
		},
	},
	{
		firstName: {
			isCategory: false,
		},
	},
	{
		lastName: {
			isCategory: false,
		},
	},
	{
		birthName: {
			isCategory: false,
		},
	},
	{
		dateOfBirth: {
			isCategory: false,
		},
	},
	{
		bornDepartment: {
			isCategory: false,
		},
	},
	{
		bornDepartmentName: {
			isCategory: false,
		},
	},
	{
		commonNationality: {
			isCategory: false,
			isNotCheckableIfNull: true,
		},
	},
	{
		commonNativeCountry: {
			isCategory: false,
			isNotCheckableIfNull: true,
		},
	},
	{
		health: {
			isCategory: true,
		},
	},
	{
		ssn: {
			isCategory: false,
		},
	},
	{
		familySituationCat: {
			isCategory: true,
		},
	},
	{
		familySituation: {
			isCategory: false,
		},
	},
	{
		childrenQty: {
			isCategory: false,
		},
	},
	{
		contact: {
			isCategory: true,
		},
	},
	{
		email: {
			isCategory: false,
		},
	},
	{
		phone: {
			isCategory: false,
		},
	},
	{
		phone2: {
			isCategory: false,
		},
	},
	{
		phone3: {
			isCategory: false,
		},
	},
	{
		address: {
			isCategory: true,
		},
	},
	{
		addressStreet: {
			isCategory: false,
		},
	},
	{
		addressBuilding: {
			isCategory: false,
		},
	},
	{
		addressPostalCode: {
			isCategory: false,
		},
	},
	{
		addressInseeCode: {
			isCategory: false,
		},
	},
	{
		addressCity: {
			isCategory: false,
		},
	},
	{
		addressCountry: {
			isCategory: false,
			isNotCheckableIfNull: true,
		},
	},
	{
		isDfsAcceptedCat: {
			isCategory: true,
		},
	},
	{
		isDfsAccepted: {
			isCategory: false,
			isNotCheckableIfNull: true,
		},
	},
];

export const SUSPICION_TABLE_ROW_WIDTH = 364;
export const SUSPICION_TABLE_ROW_HEIGHT = 70;
export const SUSPICION_TABLE_ROW_CATEGORY_HEIGHT = 50;
export const SUSPICION_TABLE_FIRST_ROW_HEIGHT = 81.5;
export const INITIAL_SUSPICIOUS_FILTER = {
	limit: 40,
	page: 1,
	sorting: "unifiedTemporaryWorker.uniqueId",
	orderBy: "ASC",
	agencies: [] as number[],
	searchText: "",
	applyFilterToLastAgency: false,
};

export const FAMILY_SITUATIONS = {
	255: "EMPTY",
	1: "SINGLE",
	2: "MARRIED",
	3: "WIDOWER",
	4: "DIVORCED",
	5: "CONCUBINAGE",
	6: "CIVIL_PARTNER",
	7: "SEPARATE",
};

export const DEFAULT_ADVANCE_PAYMENTS_FILTER = {
	searchText: null as string | null,
};

export const COMMON_FIELDS_TEMPLATES = {
	STRING: "string",
	BOOLEAN: "bool",
	DATE: "date",
	NAME: "name",
	FAMILY_SITUATIONS: "familySituation",
	SEX: "sex",
	CHANNEL_PREF: "channelPreferences",
	AVAILABILITY: "availability",
	MAIN_LOCATION: "mainLocation",
	GEO_LOCATIONS: "geoLocations",
	DOCUMENTS: "documents",
	QUALIFICATIONS: "qualifications",
	COMPETENCIES: "competencies",
	JOB_TYPES: "jobTypes",
	ARRAY_NAME: "arrayName",
	ARRAY_NAME_TRANS: "arrayNameTrans",
	LOCATIONS: "locations",
	TIME_OFF_REQUEST: "timeOffRequest",
	META: "meta",
};

export const COMMON_FIELDS = {
	isComplete: {
		tab: "AMA",
		block: "",
		template: COMMON_FIELDS_TEMPLATES.BOOLEAN,
	},
	firstName: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	lastName: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	email: {
		tab: "INFORMATION",
		block: "CONTACT",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	phone: {
		tab: "INFORMATION",
		block: "CONTACT",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	phone2: {
		tab: "INFORMATION",
		block: "CONTACT",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	phone3: {
		tab: "INFORMATION",
		block: "CONTACT",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	title: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.SEX,
	},
	sex: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.SEX,
	},
	sexLabel: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	birthName: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	dateOfBirth: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.DATE,
	},
	ssn: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	ssnMain: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	ssnCheckSum: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	bornDepartment: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	bornDepartmentName: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	bornCity: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	familySituation: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.FAMILY_SITUATIONS,
	},
	childrenQty: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	commonNationality: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	commonNativeCountry: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	nationality: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	nativeCountry: {
		tab: "INFORMATION",
		block: "CIVIL_STATUS",
		template: COMMON_FIELDS_TEMPLATES.NAME,
	},
	addressCountryId: {
		tab: "INFORMATION",
		block: "ADRESS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	addressStreet: {
		tab: "INFORMATION",
		block: "ADRESS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	addressBuilding: {
		tab: "INFORMATION",
		block: "ADRESS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	addressCityConcat: {
		tab: "INFORMATION",
		block: "ADRESS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	addressPostalCode: {
		tab: "INFORMATION",
		block: "ADRESS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	addressInseeCode: {
		tab: "INFORMATION",
		block: "ADRESS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	addressCity: {
		tab: "INFORMATION",
		block: "ADRESS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	addressCountry: {
		tab: "INFORMATION",
		block: "ADRESS",
		template: COMMON_FIELDS_TEMPLATES.NAME,
	},
	mainLocation: {
		tab: "INFORMATION",
		block: "ADRESS",
		template: COMMON_FIELDS_TEMPLATES.MAIN_LOCATION,
	},
	beneficiary: {
		tab: "INFORMATION",
		block: "BANK",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	bpByEmail: {
		tab: "INFORMATION",
		block: "BANK",
		template: COMMON_FIELDS_TEMPLATES.BOOLEAN,
	},
	regularIban: {
		tab: "INFORMATION",
		block: "IBAN",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	regularBic: {
		tab: "INFORMATION",
		block: "IBAN",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	isDepositActive: {
		tab: "INFORMATION",
		block: "IBAN",
		template: COMMON_FIELDS_TEMPLATES.BOOLEAN,
	},
	depositIban: {
		tab: "INFORMATION",
		block: "DEPOSIT",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	depositBic: {
		tab: "INFORMATION",
		block: "DEPOSIT",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	isDematAccepted: {
		tab: "INFORMATION",
		block: "ZOE",
		template: COMMON_FIELDS_TEMPLATES.BOOLEAN,
	},
	channelPreferences: {
		tab: "OTHER",
		block: "MISK",
		template: COMMON_FIELDS_TEMPLATES.CHANNEL_PREF,
	},
	isAvailable: {
		tab: "SCHEDULE",
		block: "",
		template: COMMON_FIELDS_TEMPLATES.BOOLEAN,
	},
	availability: {
		tab: "SCHEDULE",
		block: "",
		template: COMMON_FIELDS_TEMPLATES.AVAILABILITY,
	},
	locations: {
		tab: "LOCATION",
		block: "",
		template: COMMON_FIELDS_TEMPLATES.LOCATIONS,
	},
	geoLocations: {
		tab: "LOCATION",
		block: "",
		template: COMMON_FIELDS_TEMPLATES.GEO_LOCATIONS,
	},
	documents: {
		tab: "DOCUMENTS",
		block: "",
		template: COMMON_FIELDS_TEMPLATES.DOCUMENTS,
	},
	mainQualification: {
		tab: "QUALIFICATIONS",
		block: "MAIN_QUALIFICATIONS",
		template: COMMON_FIELDS_TEMPLATES.STRING,
	},
	qualifications: {
		tab: "QUALIFICATIONS",
		block: "QUALIFICATIONS",
		template: COMMON_FIELDS_TEMPLATES.QUALIFICATIONS,
	},
	competencies: {
		tab: "COMPETENCIES",
		block: "",
		template: COMMON_FIELDS_TEMPLATES.COMPETENCIES,
	},
	jobTypes: {
		tab: "JOB_TYPES",
		block: "",
		template: COMMON_FIELDS_TEMPLATES.JOB_TYPES,
	},
	conditionsOfWork: {
		tab: "CONDITIONS",
		block: "",
		template: COMMON_FIELDS_TEMPLATES.ARRAY_NAME_TRANS,
	},
	equipments: {
		tab: "EQUIPMENT",
		block: "",
		template: COMMON_FIELDS_TEMPLATES.ARRAY_NAME_TRANS,
	},
	timeOffRequests: {
		tab: "ABSENCES",
		block: "",
		template: COMMON_FIELDS_TEMPLATES.TIME_OFF_REQUEST,
	},
	meta: { tab: "META", block: "", template: COMMON_FIELDS_TEMPLATES.META },
};

export const FEATURE_SCOPES = {
	bdcPiloteV1: "bdcPiloteV1",
	bdcPiloteV2: "bdcPiloteV2",
	bdcMVP: "bdcMVP",
	unifiedTempWorkerMission: "unifiedTempWorkerMission",
};

// Profiles status for Kanban view
export const MATCHING_ERRORS = {
	NO_COMMON_QUALIFICATION: "NO_COMMON_QUALIFICATION",
	DEFAULT: "DEFAULT",
	ERR_BR_MAT_0015_INVALID_WORKING_DAYS_ORDER_PERIOD: "ERR_BR_MAT_0015_INVALID_WORKING_DAYS_ORDER_PERIOD",
	ERR_BR_MAT_0016_INVALID_WORKING_HOURS_ON_ORDER: "ERR_BR_MAT_0016_INVALID_WORKING_HOURS_ON_ORDER",
};

export const ORDER_PROFILES_ERRORS = {
	ERR_CO_ORDER_PROFILE_NOTES_CONFLICT: "ERR_CO_ORDER_PROFILE_NOTES_CONFLICT",
};

export const ORDER_EQUIPMENTS = {
	EQUIPMENT_OTHER_REMARKS: "Autres",
	EQUIPMENT_SAFETY_SHOES: "Chaussures de sécurité",
	EQUIPMENT_FLUO_VEST: "Gilet Fluo",
	EQUIPMENT_HELMET: "Casque",
	EQUIPMENT_GLOVES: "Gants",
	EQUIPMENT_GLASSES: "Lunettes",
	EQUIPMENT_HEARING_PROTECTION: "Protections auditives",
	EQUIPMENT_WHISTLE_AND_CHOKER: "Sifflet et Tour de Cou",
	EQUIPMENT_MEDICAL_OUTFIT: "Tenue médicale",
	EQUIPMENT_PRUNING_OUTFIT: "Tenue Elagage (chaussures, pantalon, manchon anti-coupe)",
	EQUIPMENT_WHITE_SHIRT: "Chemise blanche",
	EQUIPMENT_BLACK_SHIRT: "Chemise noire",
	EQUIPMENT_BLACK_PANTS: "Pantalon noir",
	EQUIPMENT_TIE: "Cravate",
	EQUIPMENT_DRESS_SHOES: "Chaussures de ville",
	EQUIPMENT_KITCHEN_UNIFORM: "Tenue de cuisine (veste et pantalon)",
	EQUIPMENT_KITCHEN_SAFETY_SHOES: "Chaussures de sécurité cuisine",
	EQUIPMENT_SLING: "Baudrier",
	EQUIPMENT_DUST_MASK: "Masque à poussière",
	EQUIPMENT_WELDERS_APRON: "Tablier de soudeur",
	EQUIPMENT_PERSONAL_BUSINESS_TOOLS: "Outils Métier personnels",
};

export const DEFAULT_EXPIRED_DOCUMENTS_FILTERS = {
	agencies: [] as number[],
};

export const DEFAULT_EXPIRED_DOCUMENTS_OPTIONS = {
	limit: 40,
	page: 1,
	sorting: "expirationDate",
	orderBy: "ASC",
};

export const CONFIG_EXPORT_XLSX = {
	headers: {
		"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	},
	responseType: "arraybuffer",
};

export const MAX_PROFILES_COUNT = 300;

export const SOCKET_EVENTS = {
	ORDER_PROFILES_USER_JOINS_ROOM: "order:orderProfilesUserJoinsRoom",
	ORDER_PROFILES_USER_LIST_UPDATED: "order:orderProfilesUserListUpdated",
};

export const MANAGEMENT_TYPE = {
	DELEGATION: "delegation",
	GESTION: "gestion",
};

export const ORDER_NATURE = {
	REPLACEMENT: "replacement",
	NEW_ORDER: "newOrder",
	FORMATION: "formation",
};

export const DATE_REGEX = /^((0|)[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

export const WORK_PERIOD_TYPES = {
	DAY: "day",
	NIGHT: "night",
};

export const DEFAULT_USERS_LISTING_OPTIONS = {
	limit: 40,
	page: 1,
	sorting: "createdAt",
	orderBy: "DESC",
};

export const DEBOUNCE_DELAY = 800;

export const MAX_AGENCY_FOR_EXPORT = 5;
