import { useMemo, useCallback } from "react";
import { isNil } from "lodash";

// Constants
import { CURRENT_AVAILABLE_FILTERS } from "src/constants/order/currentAvailableFilters";

// Types
import { OrderInterface } from "src/types/OrderInterface";
import LocationCalculationType from "src/types/LocationCalculationType";
import { AvailableFiltersApiResponse } from "src/services/availableFiltersService";

const isLocationBeingCalculated = (
	latitude: string | null,
	longitude: string | null,
	gpsCalculationRequestedAt: string | null
): boolean => {
	return gpsCalculationRequestedAt !== null && latitude === null && longitude === null;
};

const locationCouldNotBeCalculated = (
	latitude: string | null,
	longitude: string | null,
	gpsCalculationRequestedAt: string | null
): boolean => {
	return gpsCalculationRequestedAt === null && latitude === null && longitude === null;
};

export const isAvailabilityPeriodActive = (
	availableFilters: AxiosResponse<AvailableFiltersApiResponse> | undefined | { data: AvailableFiltersApiResponse }
): boolean => {
	if (isNil(availableFilters?.data)) {
		return false;
	}
	return (
		availableFilters?.data?.includes(CURRENT_AVAILABLE_FILTERS.TIME_OFF_V1) ||
		availableFilters?.data?.includes(CURRENT_AVAILABLE_FILTERS.TIME_OFF_V2)
	);
};

export const isClientHistoryActive = (
	availableFilters: AxiosResponse<AvailableFiltersApiResponse> | undefined | { data: AvailableFiltersApiResponse }
): boolean => {
	if (isNil(availableFilters?.data)) {
		return false;
	}
	return (
		availableFilters?.data?.includes(CURRENT_AVAILABLE_FILTERS.ADEQUATE_FOR_CLIENT) ||
		availableFilters?.data?.includes(CURRENT_AVAILABLE_FILTERS.ALREADY_WORKED_FOR_CLIENT)
	);
};

export const isClientAdequateActive = (
	availableFilters: AxiosResponse<AvailableFiltersApiResponse> | undefined | { data: AvailableFiltersApiResponse }
): boolean => {
	if (isNil(availableFilters?.data)) {
		return false;
	}
	return availableFilters?.data?.includes(CURRENT_AVAILABLE_FILTERS.ADEQUATE_FOR_CLIENT);
};

export const isClientWorkedForClientActive = (
	availableFilters: AxiosResponse<AvailableFiltersApiResponse> | undefined | { data: AvailableFiltersApiResponse }
): boolean => {
	if (isNil(availableFilters?.data)) {
		return false;
	}
	return availableFilters?.data?.includes(CURRENT_AVAILABLE_FILTERS.ALREADY_WORKED_FOR_CLIENT);
};

export const isAvailabilityUnifiedTempWorkerActive = (
	availableFilters: AxiosResponse<AvailableFiltersApiResponse> | undefined | { data: AvailableFiltersApiResponse }
): boolean => {
	if (isNil(availableFilters?.data)) {
		return false;
	}
	return (
		availableFilters?.data?.includes(CURRENT_AVAILABLE_FILTERS.AVAILABILITY_UNIFIED_TEMP_WORKER_V1) ||
		availableFilters?.data?.includes(CURRENT_AVAILABLE_FILTERS.AVAILABILITY_UNIFIED_TEMP_WORKER_V2)
	);
};

export const isUnavailableOrderPeriodsActive = (
	availableFilters: AxiosResponse<AvailableFiltersApiResponse> | undefined | { data: AvailableFiltersApiResponse }
): boolean => {
	if (isNil(availableFilters?.data)) {
		return false;
	}
	return (
		availableFilters?.data?.includes(CURRENT_AVAILABLE_FILTERS.UNAVAILABILITY_ORDER_PERIODS_V1) ||
		availableFilters?.data?.includes(CURRENT_AVAILABLE_FILTERS.UNAVAILABILITY_ORDER_PERIODS_V2)
	);
};

export const isUnavailabilityContractPeriodsActive = (
	availableFilters: AxiosResponse<AvailableFiltersApiResponse> | undefined | { data: string[] }
): boolean => {
	if (isNil(availableFilters?.data)) {
		return false;
	}
	return availableFilters?.data?.includes(CURRENT_AVAILABLE_FILTERS.UNAVAILABILITY_CONTRACT_PERIODS_V1);
};

export const isSingleFilterActive = (
	filter: string,
	availableFilters: AxiosResponse<AvailableFiltersApiResponse> | undefined | { data: AvailableFiltersApiResponse }
): boolean => {
	if (isNil(availableFilters?.data)) {
		return false;
	}
	return availableFilters?.data?.includes(filter);
};

type UseBusinessInformation = LocationCalculationType & {
	showAvailabilityPeriodFilter: boolean;
	showClientHistoryFilter: boolean;
	showClientAdequateFilter: boolean;
	showClientWorkedForClientFilter: boolean;
	showAvailabilityUnifiedTempWorkerFilter: boolean;
	showUnavailableOrderPeriodsFilter: boolean;
	showUnavailabilityContractPeriodsFilter: boolean;
	shouldShowSingleFilter: (filter: string) => boolean;
};

// TODO : orderData must be typed
const useBusinessInformationFilter = (
	orderData: { data: OrderInterface },
	availableFilters: AxiosResponse<AvailableFiltersApiResponse> | undefined | { data: AvailableFiltersApiResponse }
): UseBusinessInformation => {
	const { latitude, longitude, gpsCalculationRequestedAt } = orderData?.data?.workAddress || {};

	const showAvailabilityPeriodFilter = useMemo(
		() => isAvailabilityPeriodActive(availableFilters),
		[availableFilters]
	);
	const showClientHistoryFilter = useMemo(() => isClientHistoryActive(availableFilters), [availableFilters]);
	const showClientAdequateFilter = useMemo(() => isClientAdequateActive(availableFilters), [availableFilters]);
	const showClientWorkedForClientFilter = useMemo(
		() => isClientWorkedForClientActive(availableFilters),
		[availableFilters]
	);
	const showAvailabilityUnifiedTempWorkerFilter = useMemo(
		() => isAvailabilityUnifiedTempWorkerActive(availableFilters),
		[availableFilters]
	);
	const showUnavailableOrderPeriodsFilter = useMemo(
		() => isUnavailableOrderPeriodsActive(availableFilters),
		[availableFilters]
	);

	const showUnavailabilityContractPeriodsFilter = useMemo(
		() => isUnavailabilityContractPeriodsActive(availableFilters),
		[availableFilters]
	);

	const shouldShowSingleFilter = useCallback(
		(filter: string) => isSingleFilterActive(filter, availableFilters),
		[availableFilters]
	);

	return {
		isLocationBeingCalculated: isLocationBeingCalculated(latitude, longitude, gpsCalculationRequestedAt),
		locationCouldNotBeCalculated: locationCouldNotBeCalculated(latitude, longitude, gpsCalculationRequestedAt),
		showAvailabilityPeriodFilter,
		showClientHistoryFilter,
		showClientAdequateFilter,
		showClientWorkedForClientFilter,
		showAvailabilityUnifiedTempWorkerFilter,
		showUnavailableOrderPeriodsFilter,
		showUnavailabilityContractPeriodsFilter,
		shouldShowSingleFilter,
	};
};

export default useBusinessInformationFilter;
