// @ts-nocheck
import { ORDER_STATUS } from "../../constants";
const commandsFormDefaultValues = {
	statuses: [ORDER_STATUS.IN_PROGRESS],
	agenciesIds: [],
	showGenericAgency: false,
	minCreationDate: null,
	maxCreationDate: null,
	search: null,
};
export default commandsFormDefaultValues;
