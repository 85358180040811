// @ts-nocheck
// Externals
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-no-useless-fragment */
import { PaginationV2, Picto, SelectPopover, Text, Title, Tooltip } from "@zolteam/react-ras-library";
import { saveAs } from "file-saver";
import { t } from "i18next";
import { toast } from "react-toastify";
import { useTitle } from "react-use";
// Hooks
import { useState } from "react";
import { useQueries } from "react-query";
// Constants
import {
	DEFAULT_EXPIRED_DOCUMENTS_FILTERS,
	DEFAULT_EXPIRED_DOCUMENTS_OPTIONS,
	MAX_AGENCY_FOR_EXPORT,
	PAGINATION_OFFSET_OPTIONS,
} from "../../constants";
// Organisms
import ListMaxWidth from "../../components/organisms/ListMaxWidth";
import TableListing from "../../components/organisms/TableListing";
// Molecules
import ColumnListing from "../../components/molecules/ColumnListing";
import PaginationSearch from "../../components/molecules/PaginationSearch";
import TableInfo from "../../components/molecules/TableInfo";
// Icons
import Spinner from "../../components/icons/Spinner";
// Templates
import AgenciesFilter from "../../components/templates/AgenciesFilter";
import TableFilters from "../../components/templates/TableFilters";
import WithSideMenu from "../../components/templates/WithSideMenu";
import WithTopBarre from "../../components/templates/WithTopBarre";
// Services
import expiredDocuments from "../../services/expiredDocuments";
import user from "../../services/user";
// Utils
import cn from "../../utils/cn";
import { extractFilename } from "../../utils/extractFilename";
import { formatDate } from "../../utils/formatDate";
// Style
import TooltipMessage from "src/components/atoms/TooltipMessage";
import style from "./ExpiredDocuments.module.css";

const ExpiredDocuments = () => {
	useTitle(t("expiredDocuments.title"));

	const [expiredDocumentsFilters, setExpiredDocumentsFilters] = useState(DEFAULT_EXPIRED_DOCUMENTS_FILTERS);
	const [options, setOptions] = useState(DEFAULT_EXPIRED_DOCUMENTS_OPTIONS);

	// API Calls
	const [
		postExpiredDocumentsSearchQuery,
		getAgenciesQuery,
		exportExpiredDocumentsRequest,
		exportExpiredDocumentsHistoryRequest,
	] = useQueries([
		{
			queryKey: ["postExpiredDocumentsSearch", expiredDocumentsFilters, options],
			queryFn: () =>
				expiredDocuments.postExpiredDocumentsSearch({
					...options,
					...expiredDocumentsFilters,
					agencies: expiredDocumentsFilters.agencies?.map((agency) => agency.tempoFolderName),
				}),
		},
		{
			queryKey: ["getAgencies"],
			queryFn: () => user.getUserAgencies(),
		},
		{
			queryKey: ["exportExpiredDocuments"],
			queryFn: () =>
				expiredDocuments.exportExpiredDocuments({
					...expiredDocumentsFilters,
					agencies: expiredDocumentsFilters.agencies?.map((agency) => agency.tempoFolderName),
				}),
			onSuccess: (res) => {
				if (res?.data) {
					const pdfData = new Blob([res.data]);
					const filename = extractFilename(res);

					saveAs(pdfData, filename);

					toast.success(t("expiredDocuments.exportSuccess"));
				}
			},
			onError: () => {
				toast.error(t("expiredDocuments.exportError"));
			},
			enabled: false,
		},
		{
			queryKey: ["exportExpiredDocumentsHistory"],
			queryFn: () => expiredDocuments.exportExpiredDocumentsHistory(),
			onSuccess: (res) => {
				if (res?.data) {
					const pdfData = new Blob([res.data]);
					const filename = extractFilename(res);

					saveAs(pdfData, filename);

					toast.success(t("expiredDocuments.exportSuccess"));
				}
			},
			onError: () => {
				toast.error(t("expiredDocuments.exportError"));
			},
			enabled: false,
		},
	]);

	// Destructuring useQuery
	const {
		data: documents,
		isLoading: isLoadingDocuments,
		isError: isErrorExpiredDocumentsSearch,
	} = postExpiredDocumentsSearchQuery;

	const { data: agencies } = getAgenciesQuery;

	const { isFetching: isLoadingExportExpiredDocuments, refetch: refectExportExpiredDocuments } =
		exportExpiredDocumentsRequest;

	const { isFetching: isLoadingExportExpiredDocumentsHistory, refetch: refectExportExpiredDocumentsHistory } =
		exportExpiredDocumentsHistoryRequest;

	// Handlers
	const handlePaginationChange = (params) => {
		setOptions((prev) => ({ ...prev, ...params }));
	};

	const handleLimitChange = (params) => {
		setOptions((prev) => ({
			...prev,
			limit: params.limit,
			page: 1,
		}));
	};

	const handleFilterAgencies = (params) => {
		setExpiredDocumentsFilters({
			...expiredDocumentsFilters,
			agencies: params,
		});
		setOptions((prev) => ({ ...prev, page: 1 }));
	};

	const getPageMaxNumber = () => Math.round((documents?.data?.total ?? 0) / options.limit);

	const getStartPagination = options.limit * options.page - options.limit + 1;

	const getEndPagination =
		options.limit * options.page <= documents?.data?.total ? options.limit * options.page : documents?.data?.total;

	const isMaximumAgenciesForExport =
		(expiredDocumentsFilters?.agencies?.length === 0 && agencies?.data?.length > MAX_AGENCY_FOR_EXPORT) ||
		expiredDocumentsFilters?.agencies?.length > MAX_AGENCY_FOR_EXPORT;

	return (
		<WithSideMenu>
			<WithTopBarre
				quickEntryContent={<div>test</div>}
				title={
					<Title tag="h1" size="heading01" lineHeight="s">
						{t("expiredDocuments.title")}
					</Title>
				}
				containerClassname="container"
			>
				<TableFilters
					leftContent={
						<div className={style.filterLeftContent + " gap-3"}>
							<AgenciesFilter
								activeSelectAllOption
								options={
									agencies?.data?.map(({ id, name, tempoFolderName }) => ({
										id,
										name,
										tempoFolderName,
									})) || []
								}
								selectedOptions={expiredDocumentsFilters?.agencies.map((agency) => agency.id)}
								handleFilter={(agenciesSelected) => handleFilterAgencies(agenciesSelected)}
							/>
							<button
								type="button"
								className={cn([
									style.filterField,
									(isLoadingExportExpiredDocuments || isMaximumAgenciesForExport) &&
										style.disableButton,
								])}
								onClick={() => refectExportExpiredDocuments()}
								disabled={isLoadingExportExpiredDocuments || isMaximumAgenciesForExport}
							>
								<Picto className={style.iconPink} icon="upload" />
								<Text tag="span" className={style.pictoSpan}>
									{t("expiredDocuments.export")}
								</Text>
								{isLoadingExportExpiredDocuments && <Spinner className={style.loadingIcon} />}
							</button>
							{isMaximumAgenciesForExport && (
								<>
									<TooltipMessage placement="right" className="ml-2">
										{" "}
										{t("expiredDocuments.exportMaxLimit")}
									</TooltipMessage>
								</>
							)}
						</div>
					}
					rightContent={
						<div className={style.historicalBtnParent}>
							<button
								type="button"
								className={cn([
									style.filterField,
									"ml-l",
									isLoadingExportExpiredDocumentsHistory && style.disableButton,
								])}
								onClick={() => refectExportExpiredDocumentsHistory()}
								disabled={isLoadingExportExpiredDocumentsHistory}
							>
								<Picto className={style.iconPink} icon="timefast" />
								<Text tag="span" className={style.pictoSpan}>
									{t("expiredDocuments.historic")}
								</Text>
								{isLoadingExportExpiredDocumentsHistory && <Spinner className={style.loadingIcon} />}
							</button>
						</div>
					}
				/>
				<TableListing
					loading={isLoadingDocuments}
					error={isErrorExpiredDocumentsSearch}
					data={documents?.data?.items}
					hideOnDesktop={false}
					handleSearch={handlePaginationChange}
					initialColumnSort={{
						field: "expirationDate",
						orderBy: "DESC",
					}}
					canBeChecked={false}
					footer={
						<>
							{documents?.data?.total ? (
								<>
									<PaginationV2
										previousLabel={<Picto icon="chevronLeft" />}
										nextLabel={<Picto icon="chevronRight" />}
										breakLine={
											<PaginationSearch
												onInputChange={handlePaginationChange}
												max={getPageMaxNumber()}
											/>
										}
										currentPage={options.page}
										totalCount={documents.data.total}
										pageSize={options.limit}
										siblingCount={2}
										onPageChange={(activePage) =>
											handlePaginationChange({
												page: activePage,
											})
										}
									/>
									<Text tag="span" size="paragraph02" color="grey">
										{t("global.pagination.resultsPagination", {
											resultStart: getStartPagination,
											resultEnd: getEndPagination,
											resultTotal: documents.data.total,
										})}
									</Text>
									<SelectPopover
										name="limit"
										options={PAGINATION_OFFSET_OPTIONS}
										onChange={(value) => handleLimitChange({ limit: value })}
										value={
											options.limit
												? PAGINATION_OFFSET_OPTIONS.find(
														(option) => option.value === options.limit
													)
												: null
										}
									/>
								</>
							) : null}
						</>
					}
				>
					<ColumnListing
						id="twStatus"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("expiredDocuments.headers.twStatus")}
							</Text>
						}
						field={(elem) => ({ twStatus: elem.twStatus })}
						component={({ data: { twStatus } }) => (
							<Text tag="span" size="paragraph02">
								{twStatus ? t(`twStatus.${twStatus}`) : "N/A"}
							</Text>
						)}
						tdClassName="min-column-width"
						isSortable
					/>
					<ColumnListing
						id="tempWorkerName"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("expiredDocuments.headers.tempWorkerName")}
							</Text>
						}
						field={(elem) => ({
							tempWorkerName: elem.tempWorkerName,
						})}
						component={({ data: { tempWorkerName } }) => (
							<Text tag="span" size="paragraph02">
								{tempWorkerName}
							</Text>
						)}
						tdClassName="min-column-width"
						isSortable
					/>
					<ColumnListing
						id="isVip"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("expiredDocuments.headers.vip")}
							</Text>
						}
						field={(elem) => ({ isVip: elem.isVip })}
						component={({ data: { isVip } }) => (
							<Picto
								icon={isVip ? "check" : "close"}
								className={isVip ? style.isValidIcon : style.isInvalidIcon}
							/>
						)}
						tdClassName="min-column-width"
						cellClassName="justify-center"
					/>
					<ColumnListing
						id="hasContract"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("expiredDocuments.headers.contract")}
							</Text>
						}
						field={(elem) => ({ hasContract: elem.hasContract })}
						component={({ data: { hasContract } }) => {
							let tooltipContent = <>{t("expiredDocuments.contractTooltip.noContract")}</>;
							if (hasContract === true) {
								tooltipContent = <>{t("expiredDocuments.contractTooltip.hasContract")}</>;
							} else if (hasContract === false) {
								// hasContract peut aussi être null
								tooltipContent = <>{t("expiredDocuments.contractTooltip.hasEndedContract")}</>;
							}
							return (
								<Tooltip content={tooltipContent}>
									<div>
										<Picto
											icon={hasContract ? "check" : "close"}
											className={cn([
												hasContract ? style.isValidIcon : style.isInvalidIcon,
												"items-center d-flex",
											])}
										/>
									</div>
								</Tooltip>
							);
						}}
						tdClassName="min-column-width"
						cellClassName="justify-center"
					/>
					<ColumnListing
						id="isOutOfBusiness"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("expiredDocuments.headers.outOfBusiness")}
							</Text>
						}
						field={(elem) => ({
							isOutOfBusiness: elem.isOutOfBusiness,
						})}
						component={({ data: { isOutOfBusiness } }) =>
							isOutOfBusiness !== null ? (
								<Picto
									icon={isOutOfBusiness ? "check" : "close"}
									className={isOutOfBusiness ? style.isValidIcon : style.isInvalidIcon}
								/>
							) : (
								""
							)
						}
						tdClassName="min-column-width"
						cellClassName="justify-center"
					/>
					<ColumnListing
						id="userAgencies"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("expiredDocuments.headers.agencies")}
							</Text>
						}
						field={(elem) => ({
							mainAgency: elem.mainAgency,
							listOthersAgencies: elem.listOthersAgencies,
							idDoc: elem.id,
						})}
						component={({ data: { listOthersAgencies = [], idDoc, mainAgency } }) => {
							const agenciesToDisplay = listOthersAgencies?.map((agency, index) => ({
								id: index,
								name: agency,
							}));
							agenciesToDisplay.unshift({ name: mainAgency, id: 0 });
							return (
								<ListMaxWidth
									array={agenciesToDisplay}
									keyListElem="id"
									component={({ data }) => {
										return (
											<Text tag="span" size="paragraph02">
												{data.name}
											</Text>
										);
									}}
									margeError={5}
									oneItem
								/>
							);
						}}
						tdClassName="min-column-width"
						isSortable
					/>

					<ColumnListing
						id="qualification"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("expiredDocuments.headers.qualification")}
							</Text>
						}
						field={(elem) => ({
							qualification: elem.qualification,
						})}
						component={({ data: { qualification } }) => (
							<Text tag="span" size="paragraph02">
								{qualification ?? ""}
							</Text>
						)}
						tdClassName="min-column-width"
						isSortable
					/>
					<ColumnListing
						id="documentName"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("expiredDocuments.headers.documentName")}
							</Text>
						}
						field={(elem) => ({ documentName: elem.documentName })}
						component={({ data: { documentName } }) => (
							<Text tag="span" size="paragraph02" className={style.documentName}>
								{documentName}
							</Text>
						)}
						tdClassName="min-column-width"
						isSortable
					/>
					<ColumnListing
						id="effectiveDate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("expiredDocuments.headers.effectiveDate")}
							</Text>
						}
						field={(elem) => ({
							effectiveDate: elem.effectiveDate,
						})}
						component={({ data: { effectiveDate } }) => (
							<TableInfo info={formatDate(effectiveDate, "dd MMM yyyy")} />
						)}
						tdClassName="min-column-width"
						isSortable
					/>
					<ColumnListing
						id="expirationDate"
						name={
							<Text tag="span" size="paragraph02" color="grey">
								{t("expiredDocuments.headers.expirationDate")}
							</Text>
						}
						field={(elem) => ({
							expirationDate: elem.expirationDate,
						})}
						component={({ data: { expirationDate } }) => (
							<TableInfo info={formatDate(expirationDate, "dd MMM yyyy")} />
						)}
						tdClassName="min-column-width"
						isSortable
					/>
				</TableListing>
			</WithTopBarre>
		</WithSideMenu>
	);
};

export default ExpiredDocuments;
