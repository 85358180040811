// @ts-nocheck
import { WeekDaysSelected, NightDaysSelected } from "../../../../types/global";
import { COMMAND_TYPE_INTERIM, COMMAND_TYPE_NEW_ORDER, MANAGEMENT_TYPE, WORK_PERIOD_TYPES } from "src/constants";
import { DEFAULT_DAY_WORKING_TIME } from "src/views/Command/Configuration/forms/CommandTimeFormBlock/constants";

export const commandTypeFormDefaultValues = {
	type: COMMAND_TYPE_INTERIM,
	nature: COMMAND_TYPE_NEW_ORDER,
};
export const commandAgencyFormDefaultValues = {
	agencyOption: null,
	agencyId: null,
};
export const commandClientFormDefaultValues = {
	clientOption: null,
	clientId: null,
	isManageClient: false, // Not in use and will be renamed in the future
};
export const commandInterlocutorFormDefaultValues = {
	contact: {
		id: null,
		title: null,
		name: null,
		function: null,
		phone: null,
		phone2: null,
		email: null,
		isCustom: false,
	},
};
export const commandContractTypeFormDefaultValues = {
	contractTypeId: null, // Not in use for now
};
export const commandAppealCaseFormDefaultValues = {
	appealCaseOption: null,
	appealCase: {
		id: null,
		tempoId: null,
		model: { id: null, template: null },
	},
};
export const commandQualificationFormDefaultValues = {
	qualificationOption: null,
	qualification: {
		id: "",
		source: "",
		workerRequestedCount: 1,
		level: null,
	},
};
export const commandWorkAddressFormDefaultValues = {
	workAddress: {
		id: null,
		libelle: null,
		address: null,
		meetingAddress: null,
		addressComplement: null,
		meetingAddressComplement: null,
		postalCode: null,
		meetingPostalCode: null,
		city: null,
		meetingCity: null,
		country: null,
		meetingCountry: null,
		isClientMainAddress: false,
		geographicSector: null,
		comment: null,
		latitude: null,
		longitude: null,
	},
	workAddressCountryOption: null,
	workAddressMeetingCountryOption: null,
};
export const commandPeriodFormDefaultValues = {
	period: { startDate: new Date().toISOString(), endDate: null },
	workPeriodType: WORK_PERIOD_TYPES.DAY,
	workingTime: {
		slots: [{ startHour: null, endHour: null }],
		particularWorkTime: null,
		periodNotWorking: null,
		firstDayStartHour: null,
		...DEFAULT_DAY_WORKING_TIME,
	},
} as {
	period: { startDate: string | null; endDate: string | null };
	workPeriodType: WORK_PERIOD_TYPES.DAY | WORK_PERIOD_TYPES.NIGHT;
	workingTime: {
		slots: Array<{ startHour: string | null; endHour: string | null }>;
		workingDays?: WeekDaysSelected;
		workingNights?: NightDaysSelected;
		particularWorkTime: string | null;
		periodNotWorking: string | null;
		firstDayStartHour: string | null;
	};
};
export const commandBillingFormDefaultValues = {
	managementType: MANAGEMENT_TYPE.DELEGATION,
	package: null,
	packageAmount: null,
};
export const commandMissionFormDefaultValues = {
	missionDescription: null,
};
const defaultValues = {
	...commandTypeFormDefaultValues,
	...commandAgencyFormDefaultValues,
	...commandClientFormDefaultValues,
	...commandInterlocutorFormDefaultValues,
	...commandContractTypeFormDefaultValues,
	...commandAppealCaseFormDefaultValues,
	...commandQualificationFormDefaultValues,
	...commandWorkAddressFormDefaultValues,
	...commandPeriodFormDefaultValues,
	...commandBillingFormDefaultValues,
};
export default defaultValues;
