// @ts-nocheck
import { Button, Title } from "@zolteam/react-ras-library";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "../../../../../utils/cn";

// style
import style from "./ModalContentAddProfilesModal.module.css";

// components
import Slider from "../../../../../components/atoms/Slider";

// const
import { MAX_PROFILES_COUNT } from "../../../../../constants";

const ModalContentAddAllProfilesModal = ({ onConfirmClick, onCancelClick, selectableProfilesNumber }) => {
	function roundToNextTen(number) {
		const remainder = number % 10;
		const roundedValue = remainder !== 0 ? number - remainder + 10 : number;
		return roundedValue;
	}
	// Hooks
	const { t } = useTranslation();
	const [selectedProfiles, setSelectedProfiles] = useState(
		selectableProfilesNumber > MAX_PROFILES_COUNT ? MAX_PROFILES_COUNT : roundToNextTen(selectableProfilesNumber)
	);

	const renderSliderLabel = (value, maxLabel) => {
		if (value > maxLabel) {
			return `${maxLabel} profils`;
		}
		return `${value} profils`;
	};

	return (
		<div className={style.content}>
			<Title tag="h1" size="heading01" lineHeight="l" className={cn([style.title])}>
				{t("matching.addAllProfilesModal.title")}
			</Title>
			<div className={style.textContent}>
				{selectableProfilesNumber > MAX_PROFILES_COUNT ? (
					<p>{t("matching.addAllProfilesModal.infoMessageAddNumber")}</p>
				) : (
					<p>{t("matching.addAllProfilesModal.infoMessageAddall")}</p>
				)}
			</div>
			<div className={style.slider}>
				<Slider
					min={10}
					max={
						selectableProfilesNumber <= MAX_PROFILES_COUNT
							? roundToNextTen(selectableProfilesNumber)
							: MAX_PROFILES_COUNT
					}
					onChange={(profiles) => setSelectedProfiles(profiles)}
					maxLabel={
						selectableProfilesNumber <= MAX_PROFILES_COUNT
							? selectableProfilesNumber.toString()
							: MAX_PROFILES_COUNT.toString()
					}
					minLabel={10}
					type="profile"
					value={selectedProfiles}
					renderSliderLabel={renderSliderLabel}
					className="tooltipProfiles"
				/>
			</div>
			<div className={style.textContent}>
				{roundToNextTen(selectableProfilesNumber) !== selectedProfiles ? (
					<p className={style.message}>
						{t("matching.addAllProfilesModal.detailsMessageNumber", {
							profiles:
								selectedProfiles > selectableProfilesNumber
									? selectableProfilesNumber
									: selectedProfiles,
						})}
					</p>
				) : (
					<p className={style.message}>
						{t("matching.addAllProfilesModal.detailsMessageAll", {
							profiles:
								selectedProfiles > selectableProfilesNumber ? MAX_PROFILES_COUNT : selectedProfiles,
						})}
					</p>
				)}
				<p className={style.message}>{t("matching.addAllProfilesModal.confirmMessage")}</p>
			</div>
			<hr className={style.separator} />
			<div className={cn([style.buttonsBlock])}>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={onCancelClick}
					value={selectedProfiles}
					outline
				>
					{t("matching.addAllProfilesModal.cancelButtonMessage")}
				</Button>
				<Button
					className={style.validationButtonRight}
					type="button"
					color="primary"
					onClick={() =>
						onConfirmClick(
							selectedProfiles > selectableProfilesNumber ? selectableProfilesNumber : selectedProfiles
						)
					}
				>
					{t("matching.addAllProfilesModal.validationButtonMessage")}
				</Button>
			</div>
		</div>
	);
};

ModalContentAddAllProfilesModal.propTypes = {
	onConfirmClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
	selectableProfilesNumber: PropTypes.number,
};

ModalContentAddAllProfilesModal.defaultProps = {
	selectableProfilesNumber: null,
};

export default ModalContentAddAllProfilesModal;
